import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchLaundryMonthlyReport = createAsyncThunk(
  "laundryMonthlyReport/fetchLaundryMonthlyReport",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/laundry/generate-monthly-report", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "applictaion/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const laundryMonthlyReportSlice = createSlice({
  name: "laundryMonthlyReport",
  initialState: {
    laundryMonthlyReports: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLaundryMonthlyReport.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLaundryMonthlyReport.fulfilled, (state, action) => {
        state.status = "success";
        state.laundryMonthlyReports = action.payload.data;
      })
      .addCase(fetchLaundryMonthlyReport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default laundryMonthlyReportSlice.reducer;
