import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import {
  formatCreatedAt,
  formatNumberWithCommas,
  formatTime,
} from "../../utils/commonUtils";
import { fetchManualTransfer } from "../../redux/slices/manualTransferSlice";
import { fetchUsers } from "../../redux/slices/userSlice";
import ImageFullScreen from "../../components/ImageFullScreen";
import ApproveCartShoppingManualTransfer from "../../components/modals/bankPayment/ApproveCartShoppingManualTransfer";

const CartShopping = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchManualTransfer());
    dispatch(fetchUsers());
  }, [dispatch]);

  const cartShoppings = useSelector(
    (state) => state.manualTransfer?.manualTransfers
  );

  const cartShoppingFiltered = cartShoppings?.filter(
    (funding) => funding.details.type === "shopping"
  );

  const usersList = useSelector((state) => state.user?.users);

  // const reversedCartShopping = cartShoppingFiltered.slice().reverse();

  // Filter categories based on search term
  const filteredCartShopping = cartShoppingFiltered.filter(
    (list) =>
      search.toLowerCase() === "" ||
      list?.name.toLowerCase().includes(search) ||
      list.order_id.toString().toLowerCase().includes(search.toLowerCase())
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentCartShoppings = filteredCartShopping.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredCartShopping.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.manualTransfer?.status);
  const error = useSelector((state) => state.manualTransfer?.error);

  const getUserNameFromId = (userId) => {
    const user = usersList?.find((user) => user.id === parseInt(userId, 10));
    if (!user) {
      return { name: "Unknown", email: "N/A", phone: "N/A" };
    }

    return {
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      phone: user.phone,
    };
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Cart Shopping
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Bank Payment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Cart Shopping
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4"></div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search orders..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>
      <div className="bg-white rounded-lg w-full h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm whitespace-nowrap text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 text-center py-4">No.</th>
                <th className="px-4 text-center py-4">Name</th>
                <th className="px-4 py-4 text-center">Receipt</th>
                <th className="px-4 py-4 text-center">Amount</th>
                {/* <th className="px-4 py-4 text-center">Approved By</th> */}
                <th className="px-4 py-4 text-center">Order Id</th>
                <th className="px-4 py-4 text-center">Status</th>
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Time</th>
                <th className="px-4 py-4 text-center">Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentCartShoppings.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentCartShoppings?.map((order, index) => (
                  <tr key={order.id} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <div className="flex flex-col gap-2">
                        <p className="  text-base font-semibold">
                          {order.name}
                        </p>
                        <p className="  font-normal">
                          {getUserNameFromId(order.user_id).email}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="flex justify-center items-center">
                        <ImageFullScreen src={order.receipt} alt="recepit" />
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p className="  font-semibold">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(order.amount)}
                      </p>
                    </td>
                    {/* <td className="px-4 py-4 text-center">
                      <p className="  font-semibold">
                        {order.approved_by ? order.approver_by : "N/A"}
                      </p>
                    </td> */}

                    <td className="px-4 py-4 whitespace-nowrap">
                      <Link
                        to={`/dashboard/bank-payment/cart-shopping-order-detail/${order.details.order_id}`}
                      >
                        <p className=" text-base text-center font-semibold text-blue-600">
                          {order.details.order_id
                            ? order.details.order_id
                            : "N/A"}
                        </p>
                      </Link>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p className="  font-semibold">{order.status}</p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatTime(order.created_at)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(order.updated_at)}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center gap-4">
                        <div className="">
                          <ApproveCartShoppingManualTransfer
                            id={order.id}
                            name={order.name}
                            user_id={order.user_id}
                            amount={order.amount}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {filteredCartShopping.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartShopping;
