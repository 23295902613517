import React, { useEffect, useState } from "react";
import { FaCity, FaFileCode } from "react-icons/fa";

import { HiChevronRight } from "react-icons/hi";
import { IoMdCall, IoMdMail, IoMdPerson } from "react-icons/io";
import {
  IoHomeOutline,
  IoLocationOutline,
  IoWalletOutline,
} from "react-icons/io5";

import { Link, useParams } from "react-router-dom";
import {
  fetchUserDetail,
  selectUserDetail,
} from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { MdDateRange } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { TbNumber } from "react-icons/tb";

const IsusuApprovalUserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [showDailyROI, setShowDailyROI] = useState(false);

  useEffect(() => {
    dispatch(fetchUserDetail(id));
  }, [dispatch, id]);

  const userDetail = useSelector(selectUserDetail);

  const status = useSelector((state) => state.user?.userDetailStatus);
  const error = useSelector((state) => state.user?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameFromId = (countryId) => {
    const country = countries?.find(
      (country) => country.id === parseInt(countryId, 10)
    );
    return country ? country.name : "No country";
  };

  const getStateNameFromId = (stateId) => {
    const state = states?.find((state) => state.id === parseInt(stateId, 10));
    return state ? state.name : "No state";
  };

  const getCityNameFromId = (provinceId) => {
    const province = provinces?.find(
      (province) => province.id === parseInt(provinceId, 10)
    );
    return province ? province.name : "No city";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Isusu Approval User Details
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/investment/isusu-approval-request">
              <p className="text-[14px] text-[#1F7BF4]  font-medium">
                Isusu Approval Request
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Isusu Approval User Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <div className="m-auto">
          <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
        </div>
      ) : (
        <div>
          {userDetail ? (
            <div class="flex mt-8 gap-6">
              <div class="flex flex-col gap-6 w-6/12">
                <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-row justify-start gap-4">
                    <div className="flex flex-col w-full gap-4 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-2xl text-[#1A1C21] font-medium tracking-[0.09px]">
                        Wallet
                      </h1>
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2 justify-start">
                          <div className="bg-green-100 w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-green-200 w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoWalletOutline className=" text-2xl font-extrabold text-green-600" />
                            </div>
                          </div>
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                            Cash in wallet
                          </p>
                        </div>
                        <p className="text-3xl mt-4 text-start text-[#257443] font-bold">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            userDetail.data.wallet_balance
                              ? userDetail.data.wallet_balance
                              : "0"
                          )}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => setShowDailyROI(true)}
                          className=" border-2 p-2 bg-gray-700 text-gray-50 rounded-lg"
                        >
                          Daily ROI
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-8 h-auto px-2 py-2 bg-white rounded-lg shadow">
                      <div className=" flex justify-between p-2 items-center rounded-lg">
                        {userDetail.data.user.image ? (
                          <img
                            src={userDetail.data.user.image}
                            alt={userDetail.data.user.firstname}
                            className=" w-full max-h-[200px] object-contain rounded-lg ring-2 ring-slate-100"
                          />
                        ) : (
                          <div className="flex items-center justify-center h-full">
                            <p className="text-center">No Image</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      Bonuses
                    </h1>
                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <GiMoneyStack size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Referral Bonus
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            userDetail.data.user.bonus
                              ? userDetail.data.user.bonus
                              : "0"
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <GiMoneyStack size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Investment Bonus
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            userDetail.data.user.investment
                              ? userDetail.data.user.investment
                              : "0"
                          )}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <GiMoneyStack size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Laundry Bonus
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            userDetail.data.user.laundry
                              ? userDetail.data.user.laundry
                              : "0"
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <GiMoneyStack size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Shopping Bonus
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            userDetail.data.user.shopping
                              ? userDetail.data.user.shopping
                              : "0"
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <GiMoneyStack size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          Isusu coupon
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            userDetail.data.user.isusu_coupon
                              ? userDetail.data.user.isusu_coupon
                              : "0"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-6 w-6/12">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      User Information
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {`${userDetail.data.user.firstname} ${userDetail.data.user.lastname}`}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdMail size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Email
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {userDetail.data.user.email}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCall size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Phone
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {userDetail.data.user.phone}
                        </p>
                      </div>

                      <div className="flex justify-between items-center gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoHomeOutline size={20} color="#667085" />
                            </div>
                          </div>

                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Address
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] text-right break-words w-48 font-medium tracking-[0.07px]">
                          {userDetail.data.user.address
                            ? userDetail.data.user.address
                            : "No Info"}
                        </p>
                      </div>

                      <div className="flex justify-between items-center gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaFileCode size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Ref Code
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {userDetail.data.user.ref_code}
                        </p>
                      </div>
                      <div className="flex justify-between items-center gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <TbNumber size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            No. of Referrals
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {userDetail?.data?.referrals
                            ? userDetail?.data?.referrals.length
                            : "No Info"}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaCity size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                            City
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {userDetail.data.user.province
                            ? getCityNameFromId(userDetail.data.user.province)
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoLocationOutline size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                            State
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {userDetail.data.user.state
                            ? getStateNameFromId(userDetail.data.user.state)
                            : "No Info"}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoLocationOutline size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                            Country
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {userDetail.data.user.country
                            ? getCountryNameFromId(userDetail.data.user.country)
                            : "No info"}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdDateRange size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                              Created
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(userDetail.data.user.created_at)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdDateRange size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                              Updated
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(userDetail.data.user.updated_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "null"
          )}
        </div>
      )}

      {showDailyROI && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[100vh] overflow-y-auto">
            <div className=" flex justify-between items-center mb-4">
              <div className=" flex flex-col">
                <h2 className="text-2xl font-semibold text-gray-500 mt-4 mb-4">
                  Daily ROI History & Total
                </h2>
                <p className=" text-base font-medium text-gray-500">
                  Total:
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    ₦ 0
                  </span>
                </p>
              </div>
              <button
                onClick={() => setShowDailyROI(false)}
                className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-bold text-sm hover:text-base "
              >
                Close
              </button>
            </div>
            <table className="w-full text-sm text-center text-gray-500">
              <thead className="text-sm text-gray-700 bg-gray-100">
                <tr>
                  <th className="px-4 py-4">No.</th>
                  <th className="px-4 py-4">Date</th>
                  <th className="px-4 py-4">Amount</th>
                  <th className="px-4 py-4">Created</th>
                  <th className="px-4 py-4">Updated</th>
                </tr>
              </thead>
              {/* <tbody>
                {shopperWithdrawals.map((withdrawal, index) => (
                  <tr key={withdrawal.id}>
                    <td className="px-4 py-4">{index + 1}</td>
                    <td className="px-4 py-4">{withdrawal.name}</td>
                    <td className="px-4 py-4">
                      <span className="ml-1">&#8358;</span>{" "}
                      {formatNumberWithCommas(withdrawal.amount)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(withdrawal.created_at)}
                    </td>
                    <td className="px-4 py-4">
                      {formatCreatedAt(withdrawal.updated_at)}
                    </td>
                  </tr>
                ))}
              </tbody> */}
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default IsusuApprovalUserDetail;
