import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchLaundryCancelled = createAsyncThunk(
  "laundryCancelled/fetchLaundryCancelled",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/canceled", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const laundryCancelledSlice = createSlice({
  name: "laundryCancelled",
  initialState: {
    laundryCancelleds: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLaundryCancelled.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLaundryCancelled.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundryCancelleds = action.payload.data;
      })
      .addCase(fetchLaundryCancelled.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch laundryCancelleds";
      });
  },
});

export default laundryCancelledSlice.reducer;
