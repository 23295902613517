import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchProvince,
  updateProvince,
} from "../../../../redux/slices/provinceSlice";
import { ImSpinner2 } from "react-icons/im";

export default function ModalUpdateProvince({
  id,
  name,
  state_id,
  country_id,
}) {
  const [showModal, setShowModal] = useState(false);
  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    setUpdatedData({ state_id, country_id, name });
  }, [state_id, country_id, name]);

  const dispatch = useDispatch();
  const status = useSelector((state) => state.province?.status);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (name === "country_id" || name === "state_id") {
      updatedValue = parseInt(value, 10) || 0;
    }

    setUpdatedData({
      ...updatedData,
      [name]: updatedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(updateProvince({ id, updatedData }));

      toast.success(res.payload.message);
      dispatch(fetchProvince());
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className="w-4 h-4 text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-6 ">
                    <h1 className="text-lg text-gray-800 text-center font-medium mb-4">
                      Update A City
                    </h1>
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="category_name"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name of City
                      </label>
                      <div className="flex items-center">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={updatedData.name}
                          onChange={handleInputChange}
                          placeholder="Type city here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                      <label
                        htmlFor="state_id"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name of State
                      </label>
                      <div className="flex items-center">
                        <select
                          name="state_id"
                          id="state_id"
                          value={updatedData.state_id}
                          onChange={handleInputChange}
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option className="text-[#333843]">
                            Select Country
                          </option>
                          {states?.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <label
                        htmlFor="country_id"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name of Country
                      </label>
                      <div className="flex items-center">
                        <select
                          name="country_id"
                          id="country_id"
                          value={updatedData.country_id}
                          onChange={handleInputChange}
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option className="text-[#333843]">
                            Select Country
                          </option>
                          {countries?.map((country) => (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <ImSpinner2 className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Updating ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaEdit className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Update City
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
