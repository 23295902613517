import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { addLaundryPickupCityFee } from "../../redux/slices/laundryFeeSettingSlice";
import { formatNumberWithCommas2 } from "../../utils/commonUtils";

const LaundryPickupCitySetting = () => {
  const initialFormData = {
    pickup_fee: "",
    min_fee: "",
    max_fee: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const dispatch = useDispatch();

  const status = useSelector(
    (state) => state.laundryFeeSetting?.laundryPickupCityFeeStatus
  );

  const laundryPickupCityFee = useSelector(
    (state) => state.feeSetting?.feeSettings?.laundry_pickup_fee_city
  );

  useEffect(() => {
    if (laundryPickupCityFee) {
      setFormData({
        pickup_fee: laundryPickupCityFee.pickup_fee || "",
        min_fee: laundryPickupCityFee.min_fee || "",
        max_fee: laundryPickupCityFee.max_fee || "",
      });
    }
  }, [laundryPickupCityFee]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let adjustedValue = value.replace(/,/g, "");

    if (name === "pickup_fee") {
      const floatValue = parseFloat(value);
      adjustedValue = Math.min(Math.max(floatValue, 0), 100).toString();
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: adjustedValue,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.pickup_fee || !formData.min_fee || !formData.max_fee) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    try {
      await dispatch(addLaundryPickupCityFee(formData));
      toast.success("Laundry fee successfully set");
    } catch (error) {}
  };
  return (
    <div>
      <div className="flex gap-20 justify-between items-center">
        <label
          htmlFor="pickup_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Pickup (%)
        </label>
        <div className=" pr-24">
          <input
            name="pickup_fee"
            id="name"
            type="number"
            value={formData.pickup_fee}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex gap-8 mt-2 justify-between items-center">
        <label
          htmlFor="min_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Minimum Fee
        </label>
        <div className=" pr-24">
          <input
            name="min_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.min_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>

      <div className="flex gap-8 mt-2 justify-between items-center ">
        <label
          htmlFor="max_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Maximum Fee
        </label>
        <div className=" pr-24">
          <input
            name="max_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.max_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-[#333843] text-right p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>

      <div className="flex justify-center">
        <button
          className="flex gap-1 mt-4  rounded-lg items-center justify-center bg-blue-600 text-white py-2 px-6"
          disabled={status === "loading"}
          onClick={handleSubmit}
        >
          {status === "loading" ? (
            <>
              <FaSpinner className="animate-spin" />
              <p className="text-sm font-semibold whitespace-nowrap">
                Submitting ...
              </p>
            </>
          ) : (
            <>
              <FiPlus className=" font-bold text-base" />
              <p className="text-sm font-semibold whitespace-nowrap">Submit</p>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default LaundryPickupCitySetting;
