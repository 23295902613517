import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiService } from "../../services/apiService";

export const fetchStore = createAsyncThunk(
  "store/fetchStore",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/stores", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchStoreDetail = createAsyncThunk(
  "storeDetail/fetchStoreDetail",
  async ({ id, page }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(`/store/${id}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addStore = createAsyncThunk(
  "store/addStore",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post("/create/store", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteStore = createAsyncThunk(
  "store/deleteStore",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      await apiService.delete(`/store/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStore = createAsyncThunk(
  "store/updateStore",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(`/store/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeStore = createAsyncThunk(
  "store/closeStore",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(`/store/close/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState: {
    stores: [],
    status: "idle",
    error: null,
    storeDetail: null,
    storeDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stores = action.payload.data;
      })
      .addCase(fetchStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchStoreDetail.pending, (state) => {
        state.storeDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchStoreDetail.fulfilled, (state, action) => {
        state.storeDetailStatus = "succeeded";
        state.storeDetail = action.payload;
      })
      .addCase(fetchStoreDetail.rejected, (state, action) => {
        state.storeDetailStatus = "failed";
        state.error =
          action.error.message || "Failed to fetch Store Detail detail";
      })
      .addCase(addStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stores.unshift(action.payload);
      })
      .addCase(addStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteStore.fulfilled, (state, action) => {
        const deletedStoreId = action.payload;
        state.status = "succeeded";
        state.stores = state.stores.filter(
          (store) => store.id !== deletedStoreId
        );
      })
      .addCase(deleteStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete store";
      })
      .addCase(updateStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedStore = action.payload;
        state.stores = state.stores.map((store) =>
          store.id === updatedStore.id ? updatedStore : store
        );
        state.success = true;
      })
      .addCase(updateStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(closeStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(closeStore.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(closeStore.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
  },
});

export const { resetSuccess } = storeSlice.actions;

export const selectStoreDetail = (state) => state.store?.storeDetail;

export default storeSlice.reducer;
