import React, { useState } from "react";
import { toast } from "react-toastify";
import { FaRegTrashAlt, FaSpinner, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

import { BsExclamationLg } from "react-icons/bs";

import {
  deleteProduct,
  fetchProducts,
} from "../../../redux/slices/productSlice";

export default function DeleteProduct({ pId, name }) {
  const [showModal, setShowModal] = useState(false);

  const status = useSelector((state) => state.product?.status);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteProduct(pId));
      await dispatch(fetchProducts());
      toast.success(res.payload.message);
    } catch (error) {
      console.log(error);
    }
    setShowModal(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaRegTrashAlt className="w-4 h-4 text-[#667085] focus:text-[#00AAFF]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              {/*content*/}
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="flex flex-col items-center px-8 py-4">
                  <div className=" text-center mb-4">
                    <h1 className="text-2xl text-gray-800 mb-4 text-center font-medium">
                      Delete Product
                    </h1>
                    <p>Do you want to Delete this Product ?</p>
                  </div>
                  <div className="flex justify-center items-center m-2">
                    <BsExclamationLg className="text-gray-500 text-3xl font-bold" />
                  </div>
                  <div className="flex flex-col m-auto gap-4 mt-6">
                    <div className="flex justify-center gap-2 items-center">
                      <h1>Name: </h1>
                      <p className="text-lg text-center text-gray-800">
                        {name}
                      </p>
                    </div>

                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleDelete}
                        className="flex gap-1 rounded-lg items-center bg-[#FC0000] text-white py-2 px-4"
                        disabled={status === "loading"}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold">
                              Deleting ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold">
                              Delete Product
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
