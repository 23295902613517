import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPlus, FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";

import { addLaundryItem } from "../../../redux/slices/laundryItem";
import { fetchLaundryStoreDetail } from "../../../redux/slices/laundryStoreSlice";
import { formatNumberWithCommas2 } from "../../../utils/commonUtils";

export default function ModalAddLaundryItem({ lsId: id }) {
  const [showModal, setShowModal] = React.useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.laundryItem?.status);

  const initialFormData = {
    name: "",
    laundry_id: "",
    wash: "",
    iron: "",
    dryclean: "",
    starch: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const parsedValue = value.replace(/,/g, "");

    const updatedlsIdId = Number.isNaN(Number(id)) ? 0 : Number(id);

    setFormData({
      ...formData,
      [name]: parsedValue,
      laundry_id: updatedlsIdId,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);

    try {
      const res = await dispatch(addLaundryItem(formData));

      if (res.payload.status === "success") {
        toast.success(res.payload.message);
        setFormData({ ...initialFormData });
        dispatch(fetchLaundryStoreDetail(id));

        setShowModal(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        <PiPlusBold className=" text-lg font-extrabold" />
        Add Laundry Item
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-5 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Add Laundry Item
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-4">
                  <div className="flex flex-col gap-4 ">
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="category_name"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name
                      </label>
                      <div className="flex items-center">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          placeholder="Type laundry item here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="dryclean"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Dryclean Price
                      </label>
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="dryclean"
                          value={formatNumberWithCommas2(formData.dryclean)}
                          onChange={handleInputChange}
                          placeholder="Enter dryclean price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="state"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Wash Price
                      </label>
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="wash"
                          value={formatNumberWithCommas2(formData.wash)}
                          onChange={handleInputChange}
                          placeholder="Enter watch price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="state"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Iron Price
                      </label>
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="iron"
                          value={formatNumberWithCommas2(formData.iron)}
                          onChange={handleInputChange}
                          placeholder="Enter iron price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="state"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Starch Price
                      </label>
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="starch"
                          value={formatNumberWithCommas2(formData.starch)}
                          onChange={handleInputChange}
                          placeholder="Enter starch price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                 

                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Adding ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaPlus className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Add Laundry Item
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
