import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegTrashAlt, FaSpinner, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteStore, fetchStore } from "../../../redux/slices/storeSlice";


export default function ModalDeleteStore({
  id,
  image,
  name,
  landmark,
  location,
  type,
  country,
  state,
  cities,
}) {
  const [showModal, setShowModal] = useState(false);

  const status = useSelector((state) => state.store?.status);
  const dispatch = useDispatch();

  const handleDeleteStore = async () => {
    try {
      const res = await dispatch(deleteStore(id));

      toast.success(res.payload.data.message);
      dispatch(fetchStore());
    } catch (error) {
      console.error(error)
    }

    setShowModal(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaRegTrashAlt className="w-4 h-4 text-[#667085] focus:text-[#00AAFF]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              {/*content*/}
              <div className="border-0 rounded-lg p-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-6 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="text-center">
                  <h1 className="text-2xl text-gray-800 font-medium mb-4">
                    Delete Store
                  </h1>
                </div>
                <div className="flex flex-row gap-6">
                  <div className="flex flex-col items-center px-8 py-4 gap-4">
                    <img
                      src={image}
                      alt={name}
                      className="w-[224px] h-[269px] rounded-lg ring-[11px] ring-[#D9D9D9]"
                    />
                    <div className=" flex justify-between items-center">
                      <h1 className="text-base text-gray-400"> Name:</h1>
                      <p className=" ml-2 text-lg text-gray-700 font-semibold">
                        {name}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 mt-6">
                    <div className=" flex justify-between items-center">
                      <h1 className="text-base text-gray-400"> Landmark:</h1>
                      <p className=" ml-2 text-lg text-gray-700 font-semibold">
                        {landmark}
                      </p>
                    </div>
                    <div className=" flex justify-between items-center">
                      <h1 className="text-base text-gray-400"> Location:</h1>
                      <p className=" ml-2 text-lg text-gray-700 font-semibold">
                        {location}
                      </p>
                    </div>
                    <div className=" flex justify-between items-center">
                      <h1 className="text-base text-gray-400"> Status:</h1>
                      <p className=" ml-2 text-lg text-gray-700 font-semibold">
                        {type}
                      </p>
                    </div>
                    <div className=" flex justify-between items-center">
                      <h1 className="text-base text-gray-400"> Country:</h1>
                      <p className=" ml-2 text-lg text-gray-700 font-semibold">
                        {country}
                      </p>
                    </div>
                    <div className=" flex justify-between items-center">
                      <h1 className="text-base text-gray-400"> State:</h1>
                      <p className=" ml-2 text-lg text-gray-700 font-semibold">
                        {state}
                      </p>
                    </div>
                    <div className=" flex justify-between items-center">
                      <h1 className="text-base text-gray-400"> City:</h1>
                      <p className=" ml-2 text-lg text-gray-700 font-semibold">
                        {cities}
                      </p>
                    </div>
                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleDeleteStore(id)}
                        className="flex gap-1 rounded-lg items-center bg-[#FC0000] text-white py-2 px-4"
                        disabled={status === "loading"}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold">
                              Delete Store
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold">
                              Delete Store
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
