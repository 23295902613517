import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchProvince = createAsyncThunk(
  "province/fetchProvince",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get('/admin/city', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addProvince = createAsyncThunk(
  "province/addProvince",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/add/city", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProvince = createAsyncThunk(
  "province/deleteProvince",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(`/delete/city/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProvince = createAsyncThunk(
  "province/updateProvince",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.put(`/update/city/${id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const provinceSlice = createSlice({
  name: "province",
  initialState: {
    provinces: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProvince.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProvince.fulfilled, (state, action) => {
        state.status = "success";
        state.provinces = action.payload.data;
      })
      .addCase(fetchProvince.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addProvince.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addProvince.fulfilled, (state, action) => {
        state.status = "success";
        state.provinces.push(action.payload);
      })
      .addCase(addProvince.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteProvince.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteProvince.fulfilled, (state, action) => {
        state.status = "succeeded";
        const deletedProvinceId = action.payload;
        state.provinces = state.provinces.filter(
          (province) => province._id !== deletedProvinceId
        );
      })
      .addCase(deleteProvince.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete state";
      })
      .addCase(updateProvince.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateProvince.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedProvince = action.payload;
        state.provinces = state.provinces?.map((province) =>
          province.id === updatedProvince.id ? updatedProvince : province
        );
      })
      .addCase(updateProvince.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to update market";
      });
  },
});

export default provinceSlice.reducer;
