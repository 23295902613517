import React, { useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import { formatCreatedAt } from "../../utils/commonUtils";
import DeleteCategory from "../../components/modals/product/DeleteCategory";
import AddCategory from "../../components/modals/product/AddCategory";

const Categories = () => {
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items to display per page

  const categories = JSON.parse(localStorage.getItem("categories"));

  const reversedCategories = categories.slice().reverse();
  const status = useSelector((state) => state.category?.status);
  const error = useSelector((state) => state.category?.error);

  // Filter categories based on search term and filter type
  const filteredCategories = reversedCategories.filter(
    (category) =>
      (search.trim() === "" ||
        category.name.toLowerCase().includes(search.toLowerCase()) ||
        category.owner_type.toLowerCase().includes(search.toLowerCase())) &&
      (filterType === "" || category.owner_type.toLowerCase() === filterType)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentCategories = filteredCategories.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            All Categories
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Categories</p>
          </div>
        </div>
        <div className="flex items-end">
          <AddCategory />
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search categories..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
        <div>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 p-2.5"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="">All Types</option>
            <option value="market">Market</option>
            <option value="store">Store</option>
            <option value="fastfood">Fastfood</option>
            <option value="pharmacy">Pharmacy</option>
          </select>
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full text-sm whitespace-nowrap text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-4">
                  <p className="px-4 py-3 text-center">Name</p>
                </th>
                <th className="px-4 py-3 text-center">Type</th>
                <th className="px-4 py-3 text-center">Created</th>
                <th className="px-4 py-3 text-center">Last Updated</th>

                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentCategories.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentCategories?.map((category, index) => (
                  <tr key={category.id} className="bg-white border-b">
                    <td>
                      <div>
                        <p className=" text-base text-center font-semibold flex justify-center items-center">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-center gap-[8px]">
                      <p className="text-[14px] font-medium">{category.name}</p>
                    </td>

                    <td className="px-4 py-4 text-center">
                      <p className="text-[14px]">{category.owner_type}</p>
                    </td>

                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(category.created_at)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(category.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center">
                        <div>
                          <DeleteCategory
                            id={category.id}
                            name={category.name}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {filteredCategories.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
