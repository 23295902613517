import React, { useState, useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProject } from "../../redux/slices/projectSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { fetchInvestments } from "../../redux/slices/investmentSlice";
import { fetchUsers } from "../../redux/slices/userSlice";
import ModalSingleProject from "../../components/modals/investment/projects/ModalSingleProject";
import { ImSpinner2 } from "react-icons/im";

const ProjectList = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProject());
    dispatch(fetchUsers());
    dispatch(fetchInvestments());
  }, [dispatch]);

  const projects = useSelector((state) => state.projects?.projects);
  const investments = useSelector((state) => state.investment?.investments);

  // Filter categories based on search term
  const filteredProjects = projects.filter((order) =>
    search.toLowerCase() === ""
      ? order
      : order.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentProjects = filteredProjects.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const users = useSelector((state) => state.user?.users);
  const status = useSelector((state) => state.projects?.status);
  const error = useSelector((state) => state.projects?.error);

  const getInvestmentNameById = (investmentId) => {
    const id = Number(investmentId);

    const investment = investments?.find((investment) => investment.id === id);

    return investment
      ? { name: investment.name, amount: investment.amount }
      : { name: "Unknown", amount: 0 };
  };

  const getUserDataFromUserId = (userId) => {
    const id = Number(userId);
    const user = users?.find((user) => user.id === id);

    return user
      ? {
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
        }
      : { email: "Unknown", firstname: "Unknown", lastname: "Unknown" };
  };

 

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Projects
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Project List
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

     
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full whitespace-nowrap text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-center">Project Name</th>

                <th className="px-4 py-3 text-center">Customer Detail</th>
                <th className="px-4 py-3 text-center">Investment Detail</th>
                <th className="px-4 py-3 text-center">Amount Invested</th>

                <th className="px-4 py-3 text-center">Created Date</th>
                <th className="px-4 py-3 text-center">Date of Project</th>

                <th className="px-4 py-3 text-center">Completed</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
            {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) :status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentProjects.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentProjects?.map((order, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-center">
                      <p>{order.name}</p>
                    </td>
                    <td className="px-4 py-3 text-center">
                      <div className="flex flex-col gap-1">
                        <p className="px-4 py-1 text-[14px]  font-semibold whitespace-nowrap ">
                          {getUserDataFromUserId(order.user_id).firstname}{" "}
                          {getUserDataFromUserId(order.user_id).lastname}
                        </p>

                        <p className="px-4 py-1 text-sm font-medium">
                          {getUserDataFromUserId(order.user_id).email}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-center">
                      <p className="text-sm font-medium text-gray-800 whitespace-nowrap">
                        {getInvestmentNameById(order.investment_id).name}
                      </p>
                      <p className="text-sm mt-2  font-medium text-gray-800 whitespace-nowrap">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(
                          getInvestmentNameById(order.investment_id).amount
                        )}
                      </p>
                    </td>
                    <td className="px-4 py-2 text-center">
                      <p className="text-[14px] text-[#667085] font-medium">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(order.amount)}
                      </p>
                    </td>

                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-4 py-2 text-center">
                      <p className="text-[14px] text-[#667085] font-medium whitespace-nowrap">
                        {formatCreatedAt(order.date_of_project)}
                      </p>
                    </td>

                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {order.completed ? order.completed : "Not yet"}
                    </td>

                    <td className="px-4 py-4">
                      <div className="flex gap-6 justify-center items-center">
                        <ModalSingleProject
                          id={order.id}
                          userFirstname={
                            getUserDataFromUserId(order.user_id).firstname
                          }
                          userLastname={
                            getUserDataFromUserId(order.user_id).lastname
                          }
                          userEmail={getUserDataFromUserId(order.user_id).email}
                          name={order.name}
                          date_of_project={order.date_of_project}
                          cost={order.cost}
                          amount={order.amount}
                          days_left={order.days_left}
                          investmentName={
                            getInvestmentNameById(order.investment_id).name
                          }
                          investmentAmount={
                            getInvestmentNameById(order.investment_id).amount
                          }
                          completed={order.completed}
                          created_at={formatCreatedAt(order.created_at)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {projects.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
