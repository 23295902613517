import React, { useEffect, useState } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ImSpinner2 } from "react-icons/im";
import {
  fetchProductSubs,
  updateProductSub,
} from "../../../redux/slices/productSub";

export default function ModalUpdateProductSub({
  id,
  name,
  cost,
  shopping_cost,
  shopping_fee,
  delivery,
}) {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.productSub?.status);

  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    setUpdateData({ name, cost, shopping_cost, shopping_fee, delivery });
  }, [name, cost, shopping_cost, shopping_fee, delivery]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (
      name === "cost" ||
      name === "shopping_cost" ||
      name === "shopping_fee" ||
      name === "delivery"
    ) {
      updatedValue = parseInt(value, 10) || 0;
    }

    setUpdateData({
      ...updateData,
      [name]: updatedValue,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();


    try {
      const res = await dispatch(updateProductSub({ id, updateData }));
      toast.success(res.payload.message);
      dispatch(fetchProductSubs());
    } catch (error) {}

    setShowModal(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className="text-gray-500 text-base " />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Update Shopping Subscription
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-row gap-6 ">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="name"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name
                        </label>
                        <div className="flex items-center">
                          <input
                            name="name"
                            id="name"
                            type="text"
                            value={updateData && updateData.name}
                            onChange={handleInputChange}
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="cost"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Cost
                        </label>
                        <div className="flex items-center">
                          <input
                            name="cost"
                            id="cost"
                            type="number"
                            value={updateData && updateData.cost}
                            onChange={handleInputChange}
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="shopping_cost"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Shopping Cost
                        </label>
                        <div className="flex items-center">
                          <input
                            name="shopping_cost"
                            id="shopping_cost"
                            type="number"
                            value={updateData && updateData.shopping_cost}
                            onChange={handleInputChange}
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="shopping_fee"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                        No. of Shopping
                        </label>
                        <div className="flex items-center">
                          <input
                            name="shopping_fee"
                            id="shopping_fee"
                            type="number"
                            value={updateData && updateData.shopping_fee}
                            onChange={handleInputChange}
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="delivery"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                         No. of Deliveries
                        </label>
                        <div className="flex items-center">
                          <input
                            name="delivery"
                            id="delivery"
                            type="number"
                            value={updateData && updateData.delivery}
                            onChange={handleInputChange}
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex mt-6 gap-4 justify-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                        >
                          Cancel
                        </button>
                        <button
                          className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                          disabled={status === "loading"}
                          onClick={handleUpdate}
                        >
                          {status === "loading" ? (
                            <>
                              <ImSpinner2 className="animate-spin" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Updating ...
                              </p>
                            </>
                          ) : (
                            <>
                              <FaTimes className="w-4 h-4" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                              Update Shopping Subscription
                              </p>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
