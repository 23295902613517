import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiChevronRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

import UploadImage from "../../components/UploadImage";
import { useDispatch, useSelector } from "react-redux";
import {
  addInvestment,
  fetchInvestments,
} from "../../redux/slices/investmentSlice";
import { ImSpinner2 } from "react-icons/im";
import { FaPlus } from "react-icons/fa";
import { formatNumberWithCommas2 } from "../../utils/commonUtils";

const AddIvestment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFormData = {
    name: "",
    type: "",
    minimum: "",
    min_month: "",
    roi: "",
    image: null,
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const status = useSelector((state) => state.investment?.status);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value.replace(/,/g, "");

    if (name === "roi" || name === "amount") {
      updatedValue = parseFloat(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: updatedValue,
    }));
  };

  const handleImageSelected = (selectedImage) => {
    setFormData({
      ...formData,
      image: selectedImage,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(addInvestment(formData));
   
      toast.success(res.payload.message);
      setFormData({ ...initialFormData });
      navigate("/dashboard/investment/investments");

      dispatch(fetchInvestments());
    } catch (error) {}
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Investment
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/investment/investments">
              <p className="text-[14px] text-[#1F7BF4]  font-medium">
                Investments
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-gray-500 font-medium">
              Add Investment
            </p>
          </div>
        </div>

        <div className="flex items-center">
          <Link to="/dashboard/investment/investments">
            <button
              type="button"
              className="flex gap-2 text-[#84818A] border-2 items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-semibold"
            >
              Cancel
            </button>
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row  mt-6 gap-6">
          <div className="w-4/12 h-auto">
            <div className="w-full h-auto flex flex-col px-6 py-6 bg-white rounded-lg shadow gap-[14px]">
              <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                Thumbnail
              </h1>

              <div>
                <UploadImage onUpload={handleImageSelected} />
              </div>
            </div>
          </div>
          <div className="w-8/12 h-auto">
            <div className="w-full h-auto flex flex-col px-6 py-6 bg-white rounded-lg shadow gap-[14px]">
              <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                General Information
              </h1>
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="category_name"
                  className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                >
                  Name
                </label>
                <div className="flex items-center">
                  <input
                    type="text"
                    name="name"
                    placeholder="Type name here. . ."
                    className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[1px]">
                    <label
                      htmlFor="type"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Type
                    </label>
                    <select
                      name="type"
                      id="type"
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      value={formData.type}
                      onChange={handleInputChange}
                    >
                      <option value="" className="text-[#333843]" disabled>
                        Choose Type
                      </option>
                      <option value="Trending">Trending</option>

                      <option value="New">New</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[1px]">
                    <label
                      htmlFor="min_month"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Minimum No. of Month
                    </label>
                    <input
                      type="number"
                      name="min_month"
                      id="min_month"
                      placeholder="Enter minimum of month here..."
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      value={formData.min_month}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[1px]">
                    <label
                      htmlFor="location"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Minimum Investment
                    </label>
                    <input
                      type="text"
                      name="minimum"
                      id="minimum"
                      placeholder="Enter Minimum here ..."
                      className="pl-[12px] text-[#060708] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      value={formatNumberWithCommas2(formData.minimum)}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[1px]">
                    <label
                      htmlFor="roi"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Return of Investment (%)
                    </label>
                    <input
                      type="number"
                      name="roi"
                      id="roi"
                      placeholder="Enter Return of Investment  ..."
                      className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      value={formData.roi}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <button
                className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                disabled={status === "loading"}
                onClick={handleSubmit}
              >
                {status === "loading" ? (
                  <>
                    <ImSpinner2 className="animate-spin" />
                    <p className="text-sm font-semibold whitespace-nowrap">
                      Adding ...
                    </p>
                  </>
                ) : (
                  <>
                    <FaPlus className="w-4 h-4" />
                    <p className="text-sm font-semibold whitespace-nowrap">
                      Add Investment
                    </p>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddIvestment;
