import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";

import { Link } from "react-router-dom";

import { formatCreatedAt } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../redux/slices/userSlice";

import { ImSpinner2 } from "react-icons/im";
import ModalPromotion from "../../components/modals/user/ModalPromotion";

const UserPromotion = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const usersList = useSelector((state) => state.user?.users);

  // console.log(usersList);

  const reverseUsersList = usersList.slice().reverse();

  const status = useSelector((state) => state.user?.status);
  const error = useSelector((state) => state.user?.error);
  
  // Filter categories based on search term
  const filteredUsers = reverseUsersList.filter((user) =>
    search.toUpperCase() === ""
      ? true
      : user.firstname.toUpperCase().includes(search.toUpperCase()) ||
        user.lastname.toUpperCase().includes(search.toUpperCase()) ||
        user.email.toUpperCase().includes(search.toUpperCase())
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentUserList = filteredUsers.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, userId) => {
    const isChecked = e.target.checked;

    setSelectedUserIds((prevSelectedIds) => {
      if (isChecked) {
        // If checkbox is checked, add the userId to the selectedUserIds array
        return [...prevSelectedIds, userId];
      } else {
        // If checkbox is unchecked, remove the userId from the selectedUserIds array
        return prevSelectedIds.filter((id) => id !== userId);
      }
    });
  };

  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setSelectAll(isChecked);

    if (isChecked) {
      const allUserIds = filteredUsers.map((user) => user.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const clearSelectedUserIds = () => {
    setSelectedUserIds([]);
    setSelectAll(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            User Promotion
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Users</p>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">
              User Promotion
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search user..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
        <div>
          <ModalPromotion
            clearSelectedUserIds={clearSelectedUserIds}
            selectedUserIds={selectedUserIds}
          />
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-4 text-left">Name</th>
                <th className="px-2 py-3 text-center ">Image</th>
                <th className="px-2 py-3 text-center whitespace-nowrap">
                  Ref Code
                </th>
                <th className="px-2 py-3 text-center">Created</th>
                <th className="px-2 py-3 text-center">Last Updated</th>
                <th className="px-2 py-3 text-center">
                  <div className=" flex justify-center items-center gap-2">
                    <h1>Action</h1>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleHeaderCheckboxChange}
                      className="w-4 h-4"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentUserList.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentUserList?.map((user, index) => (
                  <tr key={user.id}>
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex flex-col text-left gap-2">
                        <Link to={`/dashboard/user/promotions/${user.id}`}>
                          <p className=" text-blue-600 text-base font-semibold">
                            {user.firstname} {user.lastname}
                          </p>
                        </Link>

                        <p className=" text-gray-500">{user.email}</p>
                        <p className=" font-bold">{user.phone}</p>
                      </div>
                    </td>
                    <td className="px-4 py-4 ">
                      <div className=" flex justify-center items-center whitespace-nowrap">
                        {user.image ? (
                          <img
                            src={user.image}
                            alt={user.firstname}
                            className=" rounded-md object-left w-20 h-20"
                            checked={selectedUserIds.includes(user.id)}
                          />
                        ) : (
                          "No Image"
                        )}
                      </div>
                    </td>

                    <td className="px-4 py-4 text-center">{user.ref_code}</td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.created_at)}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center">
                        <div>
                          <Link to={`/dashboard/user/promotions/${user.id}`}>
                            <IoEyeOutline className="text-base text-[#667085]" />
                          </Link>
                        </div>
                        <div className="flex justify-center items-center">
                          <input
                            type="checkbox"
                            value={user.id}
                            onChange={(e) => handleCheckboxChange(e, user.id)}
                            className="w-4 h-4"
                            checked={selectedUserIds.includes(user.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {usersList.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPromotion;
