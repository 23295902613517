import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchLaundryExpress = createAsyncThunk(
  "laundryExpress/fetchLaundryExpress",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/express", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const laundryExpressSlice = createSlice({
  name: "laundryExpress",
  initialState: {
    laundryExpresses: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLaundryExpress.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLaundryExpress.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundryExpresses = action.payload.data;
      })
      .addCase(fetchLaundryExpress.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default laundryExpressSlice.reducer;
