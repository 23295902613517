import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchItemDelivered = createAsyncThunk(
  "itemDelivered/fetchItemDelivered",
  async (_, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem("token");
      const response = await apiService.get('/laundry/items-delivered', {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const itemDeliveredSlice = createSlice({
  name: "itemDelivered",
  initialState: {
    itemDelivereds: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItemDelivered.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchItemDelivered.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.itemDelivereds = action.payload.data;
      })
      .addCase(fetchItemDelivered.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default itemDeliveredSlice.reducer;
