import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchAdmins = createAsyncThunk(
  "admin/fetchAdmins",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admins", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAdmin = createAsyncThunk(
  "admin/addAdmin",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/register", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/updateAdmin",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put("/admin/update", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPasswordAdmin = createAsyncThunk(
  "admin/resetPasswordAdmin",

  async (formDataObj, { rejectWithValue }) => {
    const formData = formDataObj;
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/reset-password", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAdmin = createAsyncThunk(
  "admin/verifyAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/admin/${id}/verify`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const suspendAdmin = createAsyncThunk(
  "admin/suspendAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/admin/${id}/suspend`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchAdminDetail = createAsyncThunk(
  "admin/fetchAdminDetail ",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get(`/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    admins: [],
    status: "idle",
    error: null,
    adminDetail: null,
    adminDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.status = "success";
        state.admins = action.payload.data;
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addAdmin.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addAdmin.fulfilled, (state, action) => {
        state.status = "success";
        state.admins.unshift(action.payload);
      })
      .addCase(addAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.status = "success";
        state.admins = state.admins?.map((admin) =>
          admin.id === action.payload.id ? action.payload : admin
        );
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })
      .addCase(resetPasswordAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPasswordAdmin.fulfilled, (state, action) => {
        state.status = "success";
        state.admins = state.admins?.map((admin) =>
          admin.id === action.payload.id ? action.payload : admin
        );
      })
      .addCase(resetPasswordAdmin.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })
      .addCase(suspendAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(suspendAdmin.fulfilled, (state, action) => {
        state.status = "success";
        state.admins = state.admins?.map((admin) =>
          admin.id === action.payload.id ? action.payload : admin
        );
      })
      .addCase(suspendAdmin.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })
      .addCase(verifyAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyAdmin.fulfilled, (state, action) => {
        state.status = "success";
        state.admins = state.admins?.map((admin) =>
          admin.id === action.payload.id ? action.payload : admin
        );
      })
      .addCase(verifyAdmin.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })
      .addCase(fetchAdminDetail.pending, (state) => {
        state.adminDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchAdminDetail.fulfilled, (state, action) => {
        state.adminDetailStatus = "succeeded";
        state.adminDetail = action.payload;
      })
      .addCase(fetchAdminDetail.rejected, (state, action) => {
        state.adminDetailStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectAdminDetail = (state) => state.admin?.adminDetail;

export default adminSlice.reducer;
