// authThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginAPI } from "../../services/apiService";

export const loginAdminAsync = createAsyncThunk(
  "auth/loginAdminAsync",
  async (formData, thunkAPI) => {
    try {
      const response = await loginAPI(formData);
      return response;
      
    } catch (error) {

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
