import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addProductSub,
  fetchProductSubs,
} from "../../../redux/slices/productSub";
import { ImSpinner2 } from "react-icons/im";

export default function ModalAddProductSub() {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.productSub?.status);

  const initialFormData = {
    name: "",
    cost: "",
    shopping_cost: "",
    shopping_fee: "",
    delivery: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (
      name === "cost" ||
      name === "shopping_cost" ||
      name === "shopping_fee" ||
      name === "delivery"
    ) {
      updatedValue = parseInt(value, 10) || "";
    }

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await dispatch(addProductSub(formData));
      setFormData({ ...initialFormData });

      toast.success(res.payload.message);
      dispatch(fetchProductSubs());
    } catch (error) {
      console.error(error);
    }

    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        <PiPlusBold className=" text-lg font-extrabold" />
        Add Shopping Subscription
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                  Add Shopping Subscription
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-row gap-6 ">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="name"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name
                        </label>
                        <div className="flex items-center">
                          <input
                            name="name"
                            id="name"
                            type="text"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Type name here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="cost"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Cost
                        </label>
                        <div className="flex items-center">
                          <input
                            name="cost"
                            id="cost"
                            type="number"
                            value={formData.cost}
                            onChange={handleInputChange}
                            placeholder="Type cost here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="shopping_cost"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Shopping Cost
                        </label>
                        <div className="flex items-center">
                          <input
                            name="shopping_cost"
                            id="shopping_cost"
                            type="number"
                            value={formData.shopping_cost}
                            onChange={handleInputChange}
                            placeholder="Type shopping cost here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="shopping_fee"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                         No. of Shopping 
                        </label>
                        <div className="flex items-center">
                          <input
                            name="shopping_fee"
                            id="shopping_fee"
                            type="number"
                            value={formData.shopping_fee}
                            onChange={handleInputChange}
                            placeholder="Type shopping fee here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="delivery"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                        No. of Deliveries
                        </label>
                        <div className="flex items-center">
                          <input
                            name="delivery"
                            id="delivery"
                            type="number"
                            value={formData.delivery}
                            onChange={handleInputChange}
                            placeholder="Type delivery here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>
                      <div className="flex mt-6 gap-4 justify-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                        >
                          Cancel
                        </button>
                        <button
                          className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                          disabled={status === "loading"}
                          onClick={handleSubmit}
                        >
                          {status === "loading" ? (
                            <>
                              <ImSpinner2 className="animate-spin" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Adding ...
                              </p>
                            </>
                          ) : (
                            <>
                              <FaTimes className="w-4 h-4" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                              Add Shopping Subs.
                              </p>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
