import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchInvestments = createAsyncThunk(
  "investment/fetchInvestments",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/investments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addInvestment = createAsyncThunk(
  "investment/addInvestment",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/store-investment", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteInvestment = createAsyncThunk(
  "investment/deleteInvestment",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(`/admin/delete-investment/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateInvestment = createAsyncThunk(
  "investment/updateInvestment",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.put(
        `/update-investment/${id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const investmentSlice = createSlice({
  name: "investment",
  initialState: {
    investments: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvestments.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInvestments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.investments = action.payload.data;
      })
      .addCase(fetchInvestments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addInvestment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addInvestment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.investments.unshift(action.payload);
      })
      .addCase(addInvestment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to add new investment";
      })
      .addCase(deleteInvestment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteInvestment.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.investments = state.investments.filter(
          (investment) => investment._id !== action.payload._id
        );
      })
      .addCase(deleteInvestment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete investment";
      })

      .addCase(updateInvestment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateInvestment.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedInvestment = action.payload;

        state.investments = state.investments.map((investment) =>
          investment.id === updatedInvestment.id
            ? updatedInvestment
            : investment
        );
      })
      .addCase(updateInvestment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to update investment";
      });
  },
});

export default investmentSlice.reducer;
