import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addInterCountryFee } from "../../redux/slices/feeSettingSlice";
import { FaSpinner } from "react-icons/fa";
import { formatNumberWithCommas2 } from "../../utils/commonUtils";

const InterCountrySetting = () => {
  const initialFormData = {
    delivery_fee: "",
    min_fee: "",
    max_fee: "",
    capfields: "",
    driver: "",
    transit: "",
    driver2: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const dispatch = useDispatch();
  const status = useSelector(
    (state) => state.feeSetting?.interCountryFeeStatus
  );
  const delivery_country = useSelector(
    (state) => state.feeSetting?.feeSettings?.delivery_country
  );

  useEffect(() => {
    if (delivery_country) {
      setFormData({
        delivery_fee: delivery_country.delivery_fee || "",
        min_fee: delivery_country.min_fee || "",
        max_fee: delivery_country.max_fee || "",
        capfields: delivery_country.capfields || "",
        driver: delivery_country.driver || "",
        transit: delivery_country.transit || "",
        driver2: delivery_country.driver2 || "",
      });
    }
  }, [delivery_country]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Remove commas from the value if it's a string
    const processedValue = typeof value === "string" ? value.replace(/,/g, "") : value;
  
    let adjustedValue = processedValue;
  
    if (name === "delivery_fee") {
      // Parse the input value to ensure it's a valid number
      let intValue = parseInt(processedValue, 10);
  
      // Ensure the adjusted value is within the allowed range (1 to 100)
      intValue = isNaN(intValue) ? 0 : Math.min(Math.max(intValue, 1), 100);
  
      adjustedValue = intValue.toString();
    } else if (["capfields", "driver", "transit", "driver2"].includes(name)) {
      // Parse the processed value to ensure it's a valid number
      const intValue = parseInt(processedValue, 10);
  
      // Calculate the sum of capfields, driver, transit, and driver2
      const sumTotal = Object.keys(formData)
        .filter((key) => ["capfields", "driver", "transit", "driver2"].includes(key) && key !== name)
        .reduce((acc, key) => acc + parseInt(formData[key] || 0, 10), 0);
  
      // Ensure the adjusted value is within the allowed range (1 to 100)
      adjustedValue = isNaN(intValue) ? "" : Math.min(Math.max(intValue, 1), 100 - sumTotal).toString();
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: adjustedValue,
    }));
  };
  
  
  
  

  const handleSubmit = async () => {
    if (
      !formData.delivery_fee ||
      !formData.min_fee ||
      !formData.max_fee ||
      !formData.capfields ||
      !formData.driver ||
      !formData.transit ||
      !formData.driver2
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    try {
      await dispatch(addInterCountryFee(formData));
      toast.success("Shopping fee successfully set");
    } catch (error) {}

    // setFormData({ ...initialFormData });
  };

  return (
    <div>
      <div className="flex flex-row justify-between items-center gap-4">
        <label
          htmlFor="delivery_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Delivery Fee (%)
        </label>
        <div className="flex justify-end pr-24">
          <input
            name="delivery_fee"
            id="name"
            type="number"
            value={formData.delivery_fee}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex flex-row mt-2 justify-between items-center gap-4">
        <label
          htmlFor="min_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Minimum Fee
        </label>
        <div className="flex justify-end pr-24">
          <input
            name="min_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.min_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>

      <div className="flex flex-row mt-2 justify-between items-center gap-4">
        <label
          htmlFor="max_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Maximum Fee
        </label>
        <div className="flex justify-end pr-24">
          <input
            name="max_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.max_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex flex-row mt-2 justify-between items-center gap-4">
        <label
          htmlFor="capfileds"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Capfields (%)
        </label>
        <div className="flex justify-end pr-24">
          <input
            name="capfields"
            id="name"
            type="number"
            value={formData.capfields}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex mt-2 flex-row justify-between items-center gap-4">
        <label
          htmlFor="driver"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Driver1 (%)
        </label>
        <div className="flex justify-end pr-24">
          <input
            name="driver"
            id="name"
            type="number"
            value={formData.driver}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex mt-2 flex-row justify-between items-center gap-4">
        <label
          htmlFor="transit"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Transit (%)
        </label>
        <div className="flex justify-end pr-24">
          <input
            name="transit"
            id="name"
            type="number"
            value={formData.transit}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex mt-2 flex-row justify-between items-center gap-4">
        <label
          htmlFor="driver2"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Driver2 (%)
        </label>
        <div className="flex justify-end pr-24">
          <input
            name="driver2"
            id="name"
            type="number"
            value={formData.driver2}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex justify-center">
        <button
          className="flex gap-1 mt-4  rounded-lg items-center justify-center bg-blue-600 text-white py-2 px-4"
          disabled={status === "loading"}
          onClick={handleSubmit}
        >
          {status === "loading" ? (
            <>
              <FaSpinner className="animate-spin" />
              <p className="text-sm font-semibold whitespace-nowrap">
                Submitting ...
              </p>
            </>
          ) : (
            <>
              <FiPlus className=" font-bold text-base" />
              <p className="text-sm font-semibold whitespace-nowrap">Submit</p>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default InterCountrySetting;
