import React from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import LaundryPickupCitySetting from "../../components/feesetting/LaundryPickupCitySetting";
import LaundryPickupStateSetting from "../../components/feesetting/LaundryPickupStateSetting";
import LaundryDeliveryCitySetting from "../../components/feesetting/LaundryDeliveryCitySetting";
import LaundryDeliveryStateSetting from "../../components/feesetting/LaundryDeliveryStateSetting";

const LaundryFeeSetting = () => {
  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Laundry Pickup & Delivery Calculation
          </h1>

          <div className="flex mb-4 justify-start items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Fee Setting
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Laundry Fee Setting
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4"></div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="w-full text-base whitespace-nowrap text-gray-700 bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-center border-2"></th>
                  <th className="px-4 py-3 text-center border-2">In City</th>
                  <th className="px-4 py-3 text-center border-2">In State</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-3 text-center font-bold border-2">
                    Pickup Fee
                  </td>
                  <td className="px-4 py-3 text-center whitespace-nowrap border-2">
                    <LaundryPickupCitySetting />
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-center border-2">
                    <LaundryPickupStateSetting />
                  </td>
                </tr>
                <tr className="mt-2">
                  <td className="px-4 py-3 whitespace-nowrap font-bold text-center border-2">
                    Delivery Fee
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-center border-2">
                    <LaundryDeliveryCitySetting />
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-center border-2">
                    <LaundryDeliveryStateSetting />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaundryFeeSetting;
