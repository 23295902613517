import React, { useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";

import { Link, useParams } from "react-router-dom";
import { IoIosCard, IoMdCall, IoMdMail, IoMdPerson } from "react-icons/io";

import { IoHomeOutline, IoLocationOutline } from "react-icons/io5";
import { BiCalendar, BiSolidStore } from "react-icons/bi";

import { useDispatch, useSelector } from "react-redux";
import { fetchSingleOrder } from "../../redux/slices/orderSlice";

import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
import { FaCheck, FaCity, FaTimes } from "react-icons/fa";

const OrderReportedDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSingleOrder(id));
  }, [dispatch, id]);

  const singleOrder = useSelector((state) => state.order?.singleOrder);
  const status = useSelector((state) => state.order?.orderDetailStatus);

  // console.log(singleOrder);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Reported Order Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/orders/reported-orders">
              <p className="text-[14px] text-[#1F7BF4] font-medium">Reported</p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Order Details
            </p>
          </div>
        </div>
      </div>

      {status === "loading" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
            </div>
          </td>
        </tr>
      ) : (
        singleOrder && (
          <>
            <div className="flex gap-6 mt-4">
              <div className="flex flex-col gap-6 w-5/12">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex justify-between">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      Order <span>{singleOrder.order_id}</span>
                    </h1>
                    <p className="bg-teal-100 text-teal-500 px-[12px] text-[13px] py-[4px] flex justify-center rounded-lg items-center font-semibold">
                      {singleOrder.status}
                    </p>
                  </div>

                  <div className="flex flex-col gap-[14px]">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7]  w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <BiCalendar
                              className=""
                              size={20}
                              color="#667085"
                            />
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          Created
                        </p>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                        {formatCreatedAt(singleOrder.created_at)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className=" flex items-center gap-2">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoLocationOutline size={20} color="#667085" />
                          </div>
                        </div>
                        <div className="flex flex-row gap-1">
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Drop Off
                          </p>
                        </div>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium">
                        {singleOrder.dropoff}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className=" flex items-center gap-2">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoLocationOutline size={20} color="#667085" />
                          </div>
                        </div>
                        <div className="flex flex-row gap-1">
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Total Amount
                          </p>
                        </div>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(
                          singleOrder.total_amount
                            ? singleOrder.total_amount
                            : "0"
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7]  w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoIosCard className="" size={20} color="#667085" />
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          Capfields
                        </p>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(singleOrder.capfields)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className=" flex items-center gap-2">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoLocationOutline size={20} color="#667085" />
                          </div>
                        </div>
                        <div className="flex flex-row gap-1">
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Shopper fee
                          </p>
                        </div>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(singleOrder.shopper_fee)}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className=" flex items-center gap-2">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoLocationOutline size={20} color="#667085" />
                          </div>
                        </div>
                        <div className="flex flex-row gap-1">
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Pay on Delivery
                          </p>
                        </div>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium">
                        {singleOrder.pay_on_delivery === null ? (
                          <FaTimes className="text-lg text-red-600 font-semibold" />
                        ) : (
                          <FaCheck className="text-lg text-green-600 font-semibold"/>
                        )}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className=" flex items-center gap-2">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoLocationOutline size={20} color="#667085" />
                          </div>
                        </div>
                        <div className="flex flex-row gap-1">
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Driver1
                          </p>
                        </div>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(
                          singleOrder.driver1 ? singleOrder.driver1 : "0"
                        )}
                      </p>
                    </div>
                    {singleOrder.driver2 ? (
                      <div className="flex items-center justify-between">
                        <div className=" flex items-center gap-2">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoLocationOutline size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex flex-row gap-1">
                            <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                              Driver2
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(singleOrder.driver2)}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <Link
                    to={`/dashboard/user/user-detail/${singleOrder?.user_id}`}
                  >
                <div className="flex flex-col gap-[24px] w-full h-auto cursor-pointer px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex justify-between">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      Customer
                    </h1>
                    <div>
                      {singleOrder?.customer?.image ? (
                        <img
                          src={singleOrder?.customer?.image}
                          alt={singleOrder?.customer?.name}
                          className="w-24 h-24 rounded-2xl"
                        />
                      ) : (
                        "No Image"
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-[14px]">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoMdPerson size={20} color="#667085" />
                          </div>
                        </div>
                        <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                          Name
                        </p>
                      </div>
                      <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                        {singleOrder?.customer?.name}
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoMdMail size={20} color="#667085" />
                          </div>
                        </div>
                        <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                          Email
                        </p>
                      </div>
                      <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                        {singleOrder?.customer?.email}
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoMdCall size={20} color="#667085" />
                          </div>
                        </div>
                        <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                          Phone
                        </p>
                      </div>
                      <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                        {singleOrder?.customer?.phone}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoHomeOutline size={20} color="#667085" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                            Address
                          </p>
                        </div>
                      </div>
                      <p className="text-[14px] text-gray-800 font-semibold text-right">
                        {singleOrder?.customer?.address}
                      </p>
                    </div>

                    {singleOrder.details === null ? (
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <BiSolidStore size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                              Store Data
                            </p>
                          </div>
                        </div>

                        <p className="text-[14px] text-gray-800 font-semibold text-right">
                          No Data Available
                        </p>
                      </div>
                    ) : (
                      <div className=" flex flex-col gap-2">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <BiSolidStore size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex flex-col gap-1">
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Name of Store
                              </p>
                            </div>
                          </div>

                          <p className="text-[14px] text-gray-800 font-semibold text-right">
                            {singleOrder?.details?.name}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex flex-col gap-1">
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Store Country
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-gray-800 font-semibold text-right">
                            {singleOrder?.details?.country?.name}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex flex-col gap-1">
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Store State
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-gray-800 font-semibold text-right">
                            {singleOrder?.details?.state?.name}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaCity size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex flex-col gap-1">
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Store City
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-gray-800 font-semibold text-right">
                            {singleOrder?.details?.city?.name
                              ? singleOrder?.details?.city?.name
                              : "No info"}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                </Link>
              </div>

              <div className="flex flex-col gap-6 w-7/12">
                <div className="flex gap-6">
                  <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col gap-[14px]">
                      <div className=" flex items-center gap-2">
                        <div className="flex flex-row gap-1">
                          <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                            Note
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <textarea
                          name=""
                          id=""
                          value={
                            singleOrder.note
                              ? singleOrder.note
                              : " No note found"
                          }
                          cols="12"
                          rows="4"
                          readOnly
                          className="text-[14px] w-full border-2 p-2 rounded-lg text-[#1A1C21] font-medium"
                        ></textarea>
                      </div>
                      {singleOrder.shipping_details ? (
                        <div className="flex mt-4 items-center justify-between">
                          <div className=" flex items-center gap-2">
                            <div className="flex flex-row gap-1">
                              <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                                Shipping Detail
                              </p>
                            </div>
                          </div>

                          <p className="text-[14px] text-[#1A1C21] font-medium">
                            {singleOrder.shipping_details}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col gap-[14px]">
                      <div className=" flex items-center gap-2">
                        <div className="flex flex-row gap-1">
                          <p className="text-[14px] text-[#4D5464] font-semibold tracking-[0.07px]">
                            Review
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <textarea
                          name=""
                          id=""
                          value={
                            singleOrder.review
                              ? singleOrder.review
                              : " No review"
                          }
                          cols="12"
                          rows="3"
                          readOnly
                          className="text-[14px] text-gray-500 w-full border-2 p-2 border-gray-100 rounded-lg  font-medium"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" flex flex-row gap-4">
                  {singleOrder?.shopper?.name ? (
                    <Link
                      to={`/dashboard/shoppers/shopper-detail/${singleOrder?.shopper_id}`}
                    >
                      <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                        <div className="flex justify-between">
                          <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                            Shopper
                          </h1>
                          <div>
                            {singleOrder?.shopper?.image ? (
                              <img
                                src={singleOrder?.shopper?.image}
                                alt={singleOrder?.shopper?.name}
                                className="w-24 h-24 rounded-2xl"
                              />
                            ) : (
                              "No Image"
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-[14px]">
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <IoMdPerson size={20} color="#667085" />
                                </div>
                              </div>
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Name
                              </p>
                            </div>
                            <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                              {singleOrder?.shopper?.name}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <IoMdMail size={20} color="#667085" />
                                </div>
                              </div>
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Email
                              </p>
                            </div>
                            <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                              {singleOrder?.shopper?.email}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <IoMdCall size={20} color="#667085" />
                                </div>
                              </div>
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Phone
                              </p>
                            </div>
                            <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                              {singleOrder?.shopper?.phone}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}

                  {singleOrder?.delivery?.name ? (
                    <Link
                      to={`/dashboard/drivers/driver-detail/${singleOrder?.delivery_id}`}
                    >
                      <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                        <div className=" flex justify-between ">
                          <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                            Delivery
                          </h1>
                          <div>
                            {singleOrder?.delivery?.image ? (
                              <img
                                src={singleOrder?.delivery?.image}
                                alt={singleOrder?.delivery?.name}
                                className="w-24 h-24 rounded-2xl"
                              />
                            ) : (
                              "No Image"
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-[14px]">
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <IoMdPerson size={20} color="#667085" />
                                </div>
                              </div>
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Name
                              </p>
                            </div>
                            <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                              {singleOrder?.delivery?.name}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <IoMdMail size={20} color="#667085" />
                                </div>
                              </div>
                              <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                                Email
                              </p>
                            </div>
                            <p className="text-[14px] text-gray-800 font-semibold tracking-[0.07px]">
                              {singleOrder?.delivery?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className=" w-full mt-4">
              <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                <div className="flex gap-2">
                  <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                    Order Details
                  </h1>
                  <p className="bg-[#E7F4EE] text-[#0D894F] px-[12px] text-[13px] py-[4px] flex justify-center rounded-lg items-center font-semibold">
                    {singleOrder?.order_details.length} Order
                  </p>
                </div>
                <div className="relative overflow-x-auto border-[1px] p-6 rounded-lg border-[#E8E8E8]">
                  <table className="w-full text-center text-gray-500">
                    <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
                      <tr>
                        <th className="px-6 py-3">No.</th>
                        <th className="px-6 py-3">Name</th>
                        <th className="px-6 py-3">Image</th>
                        <th className="px-6 py-3">Shopper Image</th>
                        <th className="px-6 py-3">Driver Image</th>
                        <th className="px-6 py-3">Quantity</th>
                        <th className="px-6 py-3">Price</th>
                        <th className="px-6 py-3">Shopper Comment</th>
                        <th className="px-6 py-3">User Comment</th>
                      </tr>
                    </thead>

                    <tbody>
                      {singleOrder?.order_details?.map((order, index) => (
                        <tr
                          key={order.id}
                          className="bg-white border-b border-[#F0F1F3]"
                        >
                          <td className="px-6 py-4 text-[14px]">
                            <div className="flex justify-center items-center">
                              <p className=" text-base text-center font-semibold ">
                                {index + 1}
                              </p>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-[14px]">
                            {order.name}
                          </td>
                          <td className="px-4 py-4 text-center">
                            <div className="flex justify-center items-center">
                              {order.image ? (
                                <img
                                  src={order.image}
                                  alt={order.name}
                                  className="w-20 h-20 object-cover rounded-lg"
                                />
                              ) : (
                                "No Image"
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-4">
                            <div className="flex justify-center items-center">
                              {order.image2 ? (
                                <img
                                  src={order.image2}
                                  alt={order.name}
                                  className="w-20 h-20 object-cover rounded-lg"
                                />
                              ) : (
                                "No Image"
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-4">
                            <div className="flex justify-center items-center">
                              {order.driver_image ? (
                                <img
                                  src={order.driver_image}
                                  alt={order.name}
                                  className="w-20 h-20 object-cover rounded-lg"
                                />
                              ) : (
                                "No Image"
                              )}
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <p>{order.quantity}</p>
                          </td>
                          <td className="px-6 py-4">
                            <span className="mr-1">&#8358;</span>
                            {formatNumberWithCommas(order.price)}
                          </td>
                          <td className="px-6 py-4 text-[14px]  ">
                            {order.shopper_comment
                              ? order.shopper_comment
                              : "No Comment"}
                          </td>
                          <td className="px-6 py-3">
                            {order.user_comment
                              ? order.user_comment
                              : "No comment"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default OrderReportedDetail;
