import { createSlice } from "@reduxjs/toolkit";
import { loginAdminAsync } from "../thunks/authThunks";

const initialState = {
  status: "idle",
  error: null,
  authToken: null,
  userRole: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload; 
      state.user = action.payload.user; 

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdminAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loginAdminAsync.fulfilled, (state) => {
        state.status = "succeeded";
        state.isLoading = false;
      })
      .addCase(loginAdminAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Login failed";
      })
      // .addCase(verifyOTPAsync.pending, (state) => {
      //   state.status = "loading";
      //   state.error = null;
      // })
      // .addCase(verifyOTPAsync.fulfilled, (state) => {
      //   state.status = "succeeded";
      // })
      // .addCase(verifyOTPAsync.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload || "OTP verification failed";
      // });
  },
});

export const selectAuthToken = (state) => state.auth.authToken;
export const selectUser = (state) => state.auth.user;



export const { setAuthToken } = authSlice.actions;

export default authSlice.reducer;
