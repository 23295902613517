import React, { useEffect, useState } from "react";
import { FiSend } from "react-icons/fi";
import moment from "moment";
import Pusher from "pusher-js";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderOngoings } from "../../redux/slices/orderOngoingSlice";
import { fetchSingleOrder } from "../../redux/slices/orderSlice";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import { convertValue, formatNumberWithCommas } from "../../utils/commonUtils";
import MonitorOrderDetailUpdate from "../../components/monitororder/MonitorOrderDetailUpdate";
import MonitorOrderApproval from "../../components/monitororder/MonitorOrderApproval";
import MonitorOrderDecline from "../../components/monitororder/MonitorOrderDecline";
import { addMonitorOrder } from "../../redux/slices/monitorOrderSlice";
import { IoRadioButtonOnSharp } from "react-icons/io5";

const MonitorOrders = () => {
  const [showCustomerList, setShowCustomerList] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [userMessages, setUserMessages] = useState(() => {});
  const [channel, setChannel] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [activeCustomerId, setActiveCustomerId] = useState(null);
  const [activeCustomerName, setActiveCustomerName] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrderOngoings());
  }, [dispatch]);

  const orderOngoings = useSelector(
    (state) => state.orderOngoing?.orderOngoings
  );

  const customers = orderOngoings.filter((ongoing) => ongoing?.monitor === "1");
  const capfields = orderOngoings.filter((ongoing) => ongoing?.monitor !== "1");

  useEffect(() => {
    const pusher = new Pusher("f7d33bdec5406bf8afa5", {
      cluster: "eu",
      authEndpoint: "https://appi.capfieldsevolve.com/broadcasting/auth",
      authTransport: "ajax",
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      },
    });

    if (selectedOrderId) {
      const userChannel = pusher.subscribe(`private-${selectedOrderId}`);
      setChannel(userChannel);

      const handleNewMessage = (newMessage) => {
        setUserMessages((prevMessages) => {
          const newMessages = { ...prevMessages };
          if (newMessages[newMessage.senderId]) {
            const messageCount = Object.keys(
              newMessages[newMessage.senderId]
            ).length;
            newMessages[newMessage.senderId][messageCount + 1] = newMessage;
          } else {
            newMessages[newMessage.senderId] = { 1: newMessage };
          }
          return newMessages;
        });
      };

      userChannel.bind("client-message", handleNewMessage);

      return () => {
        pusher.unsubscribe(`private-${selectedOrderId}`);
      };
    }
  }, [selectedOrderId]);

  const handleSendMessage = async () => {
    if (messageInput.trim() !== "" && selectedOrderId && channel) {
      const formData = new FormData();
      formData.append("message", messageInput.trim());
      formData.append("chat_id", selectedOrderId);
      formData.append("type", "text");
      formData.append("user_id", activeCustomerId);

      const sender = {
        name: activeCustomerName,
      };

      formData.append("sender", JSON.stringify(sender));
      setSendingMessage(true);
      try {
        const response = await fetch(
          "https://appi.capfieldsevolve.com/api/v1/monitor/send-text-message",
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          const newMessage = {
            senderId: selectedOrderId,
            senderName: selectedOrderId,
            content: messageInput.trim(),
            timestamp: moment().toISOString(),
            image: selectedOrderId,
          };

          setUserMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            if (newMessages[selectedOrderId]) {
              const messageCount = Object.keys(
                newMessages[selectedOrderId]
              ).length;
              newMessages[selectedOrderId][messageCount + 1] = newMessage;
            } else {
              newMessages[selectedOrderId] = { 1: newMessage };
            }
            return newMessages;
          });
          setMessageInput("");
        } else {
          console.error("Error sending message:", data.message);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setSendingMessage(false);
        setMessageInput("");
      }
    }
  };

  const fetchChatMessages = async (orderId) => {
    setLoadingMessages(true);
    try {
      const response = await fetch(
        `https://appi.capfieldsevolve.com/api/v1/monitor/get-chat-by-id/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      // console.log("Fetched data:", data);

      if (data.messages && data.messages.data) {
        setUserMessages((prevMessages) => ({
          ...prevMessages,
          [orderId]: data.messages.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleCustomerClick = (customerId, orderId, customerName) => {
    setActiveCustomerId(customerId);
    setSelectedOrderId(orderId);
    fetchChatMessages(orderId);
    setActiveCustomerName(customerName); // Set the active customer's name
  };

  const handleMessageChange = (e) => {
    setMessageInput(e.target.value);
  };

  const toggleCustomerList = () => {
    setShowCustomerList(!showCustomerList);
    setSelectedOrderId(null);
  };

  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const messageDate = moment(timestamp).startOf("day");

    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("MMMM D, YYYY");
    }
  };

  const renderChatMessages = () => {
    const messages = userMessages[selectedOrderId] || [];
    // console.log("Rendering messages for order:", selectedOrderId, messages);

    if (loadingMessages) {
      return (
        <div className=" flex justify-center p-4 items-center">
          <p className="text-base mr-4 p-2">Loading messages...</p>
          <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
        </div>
      );
    }

    if (!selectedOrderId || messages.length === 0) {
      return (
        <div className=" flex justify-center p-4 items-center">
          <p className="text-base font-semibold">No messages to display.</p>
        </div>
      );
    }

    return messages.map((message, index) => (
      <div
        key={index}
        className={`flex flex-col mb-4 ${
          message.user_id === activeCustomerId ? "items-end" : "items-start"
        }`}
      >
        <div className="flex items-start">
          <img
            src="/path/to/default-avatar.jpg"
            alt={message.sender}
            className="w-8 h-8 rounded-full mr-2"
          />
          <div
            className={`p-3 rounded-lg ${
              message.user_id === activeCustomerId
                ? "bg-blue-100 text-right"
                : "bg-gray-200 text-left"
            }`}
          >
            <p className=" text-[14px]">{message.message}</p>
          </div>
        </div>
        <p
          className={`text-xs mt-2 text-gray-400 ${
            message.user_id === activeCustomerId ? "text-left" : "text-right"
          }`}
        >
          {formatDate(message.created_at)} at{" "}
          {moment(message.created_at).format("h:mm A")}
        </p>
      </div>
    ));
  };

  useEffect(() => {
    if (selectedOrderId) {
      fetchChatMessages(selectedOrderId);
    }
  }, [selectedOrderId]);

  useEffect(() => {
    // Access orderId from selectedUser state
    const orderId = selectedOrderId;

    dispatch(fetchSingleOrder(orderId));
  }, [dispatch, selectedOrderId]);

  const singleOrder = useSelector((state) => state.order?.singleOrder);
  const status = useSelector((state) => state.order?.orderDetailStatus);

  const handleUpdate = async (index, updatedData) => {
    // Create a new array with updated order details
    const updatedOrderDetails = [...singleOrder.order_details];

    updatedOrderDetails[index] = {
      ...updatedOrderDetails[index],
      user_comment: updatedData.user_comment,
      image2: updatedData.image,
    };

    const order_id = selectedOrderId;
    const order_details = updatedOrderDetails;

    try {
      await dispatch(addMonitorOrder({ order_details, order_id }));
      const orderId = selectedOrderId;
      await dispatch(fetchSingleOrder(orderId));
    } catch (error) {}
  };

  const handleApprove = async (index, updatedData) => {
    // Create a new array with updated order details
    const updatedOrderDetails = [...singleOrder.order_details];

    updatedOrderDetails[index] = {
      ...updatedOrderDetails[index],
      status: updatedData.status,
    };

    const order_id = selectedOrderId;
    const order_details = updatedOrderDetails;

    try {
      await dispatch(addMonitorOrder({ order_details, order_id }));
      const orderId = selectedOrderId;
      await dispatch(fetchSingleOrder(orderId));
    } catch (error) {}
  };

  const handleDecline = async (index, updatedData) => {
    // Create a new array with updated order details
    const updatedOrderDetails = [...singleOrder.order_details];

    updatedOrderDetails[index] = {
      ...updatedOrderDetails[index],
      status: updatedData.status,
    };

    const order_id = selectedOrderId;
    const order_details = updatedOrderDetails;

    try {
      await dispatch(addMonitorOrder({ order_details, order_id }));
      const orderId = selectedOrderId;
      await dispatch(fetchSingleOrder(orderId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="p-4">
        <p className="text-2xl mb-6 font-bold text-gray-600">Monitor Orders</p>

        <div className="flex justify-between">
          <div
            onClick={toggleCustomerList}
            className={`relative flex pt-1 w-48 h-14 rounded-3xl border-gray-300 border-2 transition-all duration-500 cursor-pointer ${
              showCustomerList ? "bg-green-500" : "bg-teal-100"
            }`}
          >
            {/* Foreground toggle */}
            <span
              className={`relative z-10 h-12 w-24 bg-white rounded-3xl transition-all duration-500 flex items-center justify-center ${
                showCustomerList ? "ml-24" : ""
              } ${showCustomerList ? "text-black" : "text-black"}`}
            >
              {showCustomerList ? "Customers" : "Capfields"}
            </span>
          </div>
          {selectedOrderId ? (
            <div
              className="flex gap-2 justify-center items-center cursor-pointer"
              onClick={() => setShowChat(!showChat)}
            >
              <IoRadioButtonOnSharp className="text-red-600" />
              <p>{showChat ? "Switch to Order Table" : "Switch to Chat"}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="flex h-screen gap-2">
        {/* Chat List */}
        <div className="w-3/12 bg-gray-50 rounded-lg p-2 overflow-y-auto">
          <div className="flex flex-col space-y-1 mt-4">
            {showCustomerList
              ? customers?.map((customer) => (
                  <div
                    key={customer.id}
                    className={`flex items-center py-2 px-4 cursor-pointer ${
                      activeCustomerId === customer.id
                        ? "bg-blue-100 rounded-lg"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() =>
                      handleCustomerClick(
                        customer.id,
                        customer.order_id,
                        customer.customer.name
                      )
                    }
                  >
                    <div>
                      <img
                        src={
                          customer.customer.image ||
                          `https://ui-avatars.com/api/?name=${customer.customer.name}`
                        }
                        alt={customer.customer.name}
                        className="w-10 h-10  text-sm rounded-full mr-4"
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex justify-between gap-12 items-center">
                        <div className="flex flex-col ml-4">
                          <p className="font-semibold text-[14px] whitespace-nowrap">
                            {customer.customer.name}
                          </p>
                          {/* <p className=" text-gray-500 text-xs font-normal line-clamp-1">
                            Hi there, please can I get to...
                          </p> */}
                        </div>
                        <div className="flex flex-col">
                          <p className="text-[12px] font-semibold  text-gray-500">
                            #{customer.order_id}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : capfields.map((user) => (
                  <div
                    key={user.id}
                    className={`flex items-center py-2 px-4 cursor-pointer ${
                      activeCustomerId === user.id
                        ? "bg-blue-100 rounded-lg"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() =>
                      handleCustomerClick(
                        user.id,
                        user.order_id,
                        user.customer.name
                      )
                    }
                  >
                    <div>
                      <img
                        src={
                          user.customer.image ||
                          `https://ui-avatars.com/api/?name=${user.customer.name}`
                        }
                        alt={user.customer.name}
                        className="w-10 h-10  text-sm rounded-full mr-4"
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex justify-between gap-12 items-center">
                        <div className="flex flex-col ml-4">
                          <p className="font-semibold text-[14px] whitespace-nowrap">
                            {user.customer.name}
                          </p>
                          {/* <p className=" text-gray-500 text-xs font-normal line-clamp-1">
                            Hi there, please can I get to...
                          </p> */}
                        </div>
                        <div className="flex flex-col">
                          <p className="text-[12px] font-semibold  text-gray-500">
                            #{user.order_id}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>

        {/* Chat Area */}
        <div className="w-9/12 flex flex-col justify-between">
          {showChat ? (
            <div className="flex flex-col w-full h-screen gap-4 px-6 py-6 bg-white rounded-lg shadow">
              <div className="overflow-y-auto h-full p-4 flex flex-col-reverse border border-gray-300 rounded-lg">
                {renderChatMessages()}
              </div>
              <div className="flex-none">
                <div className="flex items-end">
                  <input
                    type="text"
                    className="flex-1 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
                    placeholder="Type a message"
                    value={messageInput}
                    onChange={handleMessageChange}
                  />
                  <button
                    type="submit"
                    onClick={handleSendMessage}
                    className="ml-4 bg-blue-500 text-white rounded-lg px-4 py-2 flex items-center"
                    disabled={sendingMessage}
                  >
                    {sendingMessage ? (
                      "Sending..."
                    ) : (
                      <>
                        <FiSend className="mr-2" /> Send
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className=" w-full ">
              <div className="flex flex-col w-full gap-[18px]  px-6 py-6 bg-white rounded-lg shadow">
                <div className="flex gap-2">
                  <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                    Order Details
                  </h1>
                  <p className="bg-[#E7F4EE] text-[#0D894F] px-[12px] text-[13px] py-[4px] flex justify-center rounded-lg items-center font-semibold">
                    {singleOrder?.order_details.length} Order
                  </p>
                </div>
                <div className="relative overflow-x-auto border-[1px] p-6 rounded-lg border-[#E8E8E8]">
                  <table className="w-full text-center text-gray-500">
                    <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
                      <tr>
                        <th className="px-6 py-3">No.</th>
                        <th className="px-6 py-3">Name</th>
                        <th className="px-6 py-3">Quantity</th>
                        <th className="px-6 py-3">Price</th>
                        <th className="px-6 py-3">Image</th>
                        <th className="px-6 py-3">Shopper Image</th>
                        <th className="px-6 py-3"></th>
                        <th className="px-6 py-3">Shopper Comment</th>
                        <th className="px-6 py-3">Admin Comment</th>
                        <th className="px-6 py-3">Approval Status</th>
                        <th className="px-6 py-3">Payment</th>
                        <th className="px-6 py-3">Approve/Decline</th>
                      </tr>
                    </thead>

                    <tbody>
                      {status === "loading" ? (
                        <tr>
                          <td colSpan="12" className="text-center py-4">
                            <div className="flex justify-center items-center">
                              <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                            </div>
                          </td>
                        </tr>
                      ) : (
                        singleOrder?.order_details?.map((order, index) => (
                          <tr
                            key={order.id}
                            className="bg-white border-b border-[#F0F1F3]"
                          >
                            <td className="px-6 py-4 text-[14px]">
                              <div className="flex justify-center items-center">
                                <p className=" text-base text-center font-semibold ">
                                  {index + 1}
                                </p>
                              </div>
                            </td>
                            <td className="px-6 py-4 text-[14px]">
                              {order.name}
                            </td>

                            <td className="px-6 py-4">
                              <p>{order.quantity}</p>
                            </td>
                            <td className="px-6 py-4">
                              <span className="mr-1">&#8358;</span>
                              {formatNumberWithCommas(order.price)}
                            </td>
                            <td className="px-4 py-4 text-center">
                              <div className="flex justify-center whitespace-nowrap items-center">
                                {order.image.length > 0 ? (
                                  <img
                                    src={
                                      order.image && order.image.length > 0
                                        ? order.image[0]
                                        : "default-image-url.jpg"
                                    }
                                    alt={order.name}
                                    className="w-20 h-20 object-cover rounded-lg"
                                  />
                                ) : (
                                  "No Image"
                                )}
                              </div>
                            </td>
                            <td className="px-4 py-4">
                              <div className="flex justify-center items-center">
                                {order.image2 ? (
                                  <img
                                    src={order.image2}
                                    alt={order.name}
                                    className="w-20 h-20 object-cover rounded-lg"
                                  />
                                ) : (
                                  "No Image"
                                )}
                              </div>
                            </td>
                            <td>
                              <MonitorOrderDetailUpdate
                                index={index}
                                handleUpdate={handleUpdate}
                              />
                            </td>
                            <td className="px-6 py-4 text-[14px]  ">
                              <p>
                                {order.shopper_comment
                                  ? order.shopper_comment
                                  : "No Comment"}
                              </p>
                            </td>

                            <td className="px-6 py-3">
                              {order.user_comment
                                ? order.user_comment
                                : "No comment"}
                            </td>
                            <td className="px-6 py-4 text-[14px]  ">
                              <p>{convertValue(order.status)}</p>
                            </td>
                            <td className="px-6 py-4">
                              <div className=" flex justify-center items-center">
                                <p>
                                  {order.payment ? (
                                    <FaCheck className="text-green-600" />
                                  ) : (
                                    <FaTimes className="text-red-600" />
                                  )}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className=" flex gap-2 justify-center items-center">
                                {order.status === "1" ? (
                                  <div>
                                    <button
                                      type="button"
                                      disabled
                                      className=" border-2 border-green-200 rounded-lg p-2"
                                    >
                                      <FaCheck className="text-green-200" />
                                    </button>
                                  </div>
                                ) : (
                                  <MonitorOrderApproval
                                    index={index}
                                    handleApprove={handleApprove}
                                  />
                                )}

                                {order.status === "0" ? (
                                  <div>
                                    <button
                                      disabled
                                      className=" border-2 border-red-200 rounded-lg p-2"
                                    >
                                      <FaTimes className="text-red-200" />
                                    </button>
                                  </div>
                                ) : (
                                  <MonitorOrderDecline
                                    index={index}
                                    handleDecline={handleDecline}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MonitorOrders;
