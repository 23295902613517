import React, { useEffect, useState, useMemo } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { IoEyeOutline } from "react-icons/io5";
import { fetchLaundryPendingPayment } from "../../redux/slices/laundryPendingPaymentSlice";



const PendingPaymentLaundry = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLaundryPendingPayment())
  }, [dispatch]);



  const laundryPendingPayments = useSelector((state) => state.laundryPendingPayment.laundryPendingPayments)

  


  // Filter categories based on search term
  const filteredPendingPayments = laundryPendingPayments.filter(
    (order) =>
      search.toLowerCase() === "" ||
      order.username.toLowerCase().includes(search) ||
      order.order_id.toString().toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentPendingPayments = filteredPendingPayments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredPendingPayments.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.laundryPendingPayment?.status);
  const error = useSelector((state) => state.laundryPendingPayment?.error);

  // Calculate the total sum of prices
  const totalSum = useMemo(() => {
    return laundryPendingPayments.reduce(
      (sum, order) => sum + parseFloat(order.price || 0),
      0
    );
  }, [laundryPendingPayments]);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Pending Payment Laundry
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Laundry</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Pending Payment Laundry
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search orders..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
        <div className="  p-2 text-center bg-gray-50 border border-gray-300 rounded-lg">
          <h1 className="text-gray-500 p-2 font-medium">
            Total:{" "}
            <span className="text-lg p-2 text-gray-700 font-bold">
              ₦{formatNumberWithCommas(totalSum)}
            </span>
          </h1>
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full text-sm whitespace-nowrap text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-4 py-3 text-left">Customer Name</th>
                <th className="px-4 py-3 text-center">Order Id</th>
                <th className="px-4 py-4 text-center">Amount Owing</th>

                <th className="px-4 py-3 text-center ">Order Date</th>
                <th className="px-4 py-3 text-center ">
                  Push to ready for Delivery
                </th>

                <th className="px-4 py-3 text-center">Created</th>
                <th className="px-4 py-3 text-center">Updated</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentPendingPayments.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentPendingPayments.map((order, index) => (
                  <tr
                    key={order.id}
                    className="bg-white border-b whitespace-nowrap"
                  >
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-left">
                      <div className=" flex flex-col gap-2">
                        <p className=" text-base font-semibold">
                          {order.username}
                        </p>
                        <p>{order.phone}</p>
                      </div>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-center">
                      <Link
                        to={`/dashboard/laundry/pending-payments-laundry/${order.order_id}`}
                      >
                        <p className="px-4 py-1 text-base text-blue-600 font-semibold">
                          {order.order_id}
                        </p>
                      </Link>
                    </td>

                    <td className="px-4 py-2 text-center font-semibold">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(order.price)}
                    </td>
                    <td className="px-4 py-2 text-left">
                      <div className=" flex flex-col gap-2">
                        <p className=" text-base font-semibold">
                        {formatCreatedAt(order.created_at)}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-center">
                      <div className="">
                        <p className=" text-base font-semibold">
                         -
                        </p>
                      </div>
                    </td>
                  

                    <td className="px-4 py-2 text-center">
                      {formatCreatedAt(order.created_at)}
                    </td>

                    <td className="px-4 py-2 text-center">
                      {formatCreatedAt(order.updated_at)}
                    </td>
                    
                    <td className="px-2 py-4">
                      <div className="flex justify-center">
                        <Link
                          to={`/dashboard/laundry/pending-payments-laundry/${order.order_id}`}
                        >
                          <IoEyeOutline className="w-4 h-4 text-[#667085]" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {laundryPendingPayments.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingPaymentLaundry;
