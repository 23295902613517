import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";
import { ImSpinner2 } from "react-icons/im";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firestore";

export default function ModalAddStoreLocator({ reloadStoreLocator }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [type, setType] = useState("");

  const addressInputRef = useRef(null);

  const initializeAutocomplete = () => {
    const addressInput = addressInputRef.current;
    if (addressInput) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInput
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          setAddress(place.formatted_address);
          addressInput.value = place.formatted_address;
        }
        if (place.geometry) {
          setLatitude(place.geometry.location.lat());
          setLongitude(place.geometry.location.lng());
        }
      });
    } else {
      console.error("Element with ID 'address-input' not found.");
    }
  };

  useEffect(() => {
    if (showModal) {
      const loadGoogleMapsScript = () => {
        if (!window.google) {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBtpUT4tLnlprC0988eAfhVaxTE9QvXiDM&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = initializeAutocomplete;
          document.body.appendChild(script);

          return () => {
            document.body.removeChild(script);
          };
        } else {
          initializeAutocomplete();
        }
      };
      loadGoogleMapsScript();
    }
  }, [showModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (
      !name ||
      !phone ||
      !address ||
      !country ||
      !state ||
      !city ||
      !type ||
      !longitude ||
      !latitude
    ) {
      toast.error("All fields are required.");
      return;
    }

    setLoading(true);

    try {
      // Reference to the counter document
      const counterDocRef = doc(db, "Counters", "storeLocatorId");

      // Fetch the current highest ID
      const counterDoc = await getDoc(counterDocRef);

      let newId = 1; // Default to 1 if counter does not exist
      if (counterDoc.exists()) {
        newId = counterDoc.data().currentId + 1;
      }

      let timestamp = new Date().toISOString();
      let newTimestamp = timestamp.replace("Z", "");

      const newData = {
        name,
        phone,
        address,
        country,
        state,
        city,
        type,
        longitude,
        latitude,
        id: newId.toString(),
        created_at: newTimestamp,
        updated_at: newTimestamp,
      };

      // Add new data to the collection
      await setDoc(doc(db, "Store Locator", newId.toString()), newData);

      // Update the counter document with the new highest ID
      if (counterDoc.exists()) {
        await updateDoc(counterDocRef, { currentId: newId });
      } else {
        await setDoc(counterDocRef, { currentId: newId });
      }

      toast.success("Added successfully");

      // Reset the input fields
      resetForm();
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while adding.");
    } finally {
      await reloadStoreLocator();
      setShowModal(false);
      setLoading(false);
    }
  };

  // Reset form function
  const resetForm = () => {
    setName("");
    setPhone("");
    setAddress("");
    setCountry("");
    setState("");
    setCity("");
    setType("");
    setLongitude("");
    setLatitude("");
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-2 bg-blue-600 text-white items-center py-2 px-4 text-sm hover:shadow-lg rounded-lg font-medium"
      >
        <PiPlusBold className="text-lg font-extrabold" />
        Add Store Locator
      </button>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto mx-auto my-6 max-w-4xl">
              <div className="relative flex flex-col w-full p-6 bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex items-center justify-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-6">
                    <h1 className="text-lg font-medium text-center text-gray-800 mb-4">
                      Add Store Locator
                    </h1>
                    <form onSubmit={handleSubmit}>
                      <div className="flex gap-4">
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="name"
                              className="text-sm font-medium text-gray-600"
                            >
                              Name
                            </label>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Type store name here..."
                              className="w-full p-2 text-sm text-gray-700 bg-gray-100 border-2 border-gray-300 rounded-lg"
                              required
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="phone"
                              className="text-sm font-medium text-gray-600"
                            >
                              Phone
                            </label>
                            <input
                              name="phone"
                              id="phone"
                              type="number"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              placeholder="Type phone here..."
                              className="w-full p-2 text-sm text-gray-700 bg-gray-100 border-2 border-gray-300 rounded-lg"
                              required
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="address"
                              className="text-sm font-medium text-gray-600"
                            >
                              Address
                            </label>
                            <input
                              name="address"
                              id="address-input"
                              type="text"
                              value={address}
                              autoComplete="off"
                              ref={addressInputRef}
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder="Type address here..."
                              className="w-full p-2 text-sm text-gray-700 bg-gray-100 border-2 border-gray-300 rounded-lg"
                              required
                            />
                          </div>
                          <div className="flex flex-col gap-[1px]">
                            <label
                              htmlFor="location"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Type
                            </label>
                            <select
                              name="type"
                              id="type"
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                              required
                              className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                            >
                              <option
                                value=""
                                className="text-[#333843]"
                                disabled
                              >
                                Choose type
                              </option>
                              <option value="market">Market</option>
                              <option value="store">Store</option>
                              <option value="pharmacy">Pharmacy</option>
                              <option value="fastfood">Fast Food</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="country"
                              className="text-sm font-medium text-gray-600"
                            >
                              Country
                            </label>
                            <select
                              name="country"
                              id="country"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              className="w-full p-2 text-sm text-gray-700 bg-gray-100 border-2 border-gray-300 rounded-lg"
                              required
                            >
                              <option value="">Select Country</option>
                              {countries?.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="state"
                              className="text-sm font-medium text-gray-600"
                            >
                              State
                            </label>
                            <select
                              name="state"
                              id="state"
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              className="w-full p-2 text-sm text-gray-700 bg-gray-100 border-2 border-gray-300 rounded-lg"
                              required
                            >
                              <option value="">Select State</option>
                              {states
                                ?.filter(
                                  (state) => state.country_id === country
                                )
                                .map((state) => (
                                  <option key={state.id} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="city"
                              className="text-sm font-medium text-gray-600"
                            >
                              City
                            </label>
                            <select
                              name="city"
                              id="city"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              className="w-full p-2 text-sm text-gray-700 bg-gray-100 border-2 border-gray-300 rounded-lg"
                              required
                            >
                              <option value="">Select City</option>
                              {provinces
                                ?.filter(
                                  (province) =>
                                    province.country_id === country &&
                                    province.state_id === state
                                )
                                .map((province) => (
                                  <option key={province.id} value={province.id}>
                                    {province.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="flex justify-center gap-4 mt-8">
                            <button
                              type="button"
                              onClick={() => setShowModal(false)}
                              className="py-2 px-4 text-sm font-semibold text-blue-500 bg-blue-100 rounded-lg"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="flex items-center gap-1 px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-lg"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <ImSpinner2 className="animate-spin" />
                                  <span>Adding...</span>
                                </>
                              ) : (
                                <>
                                  <FaTimes className="w-4 h-4" />
                                  <span>Add Store Locator</span>
                                </>
                              )}
                            </button>
                          </div>

                          <input
                            type="hidden"
                            name="latitude"
                            id="latitude"
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                          />
                          <input
                            type="hidden"
                            name="longitude"
                            id="longitude"
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}
