import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchLaundrySubs = createAsyncThunk(
  "laundrySub/fetchLaundrySubs",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/laundry_sub", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addLaundrySub = createAsyncThunk(
  "laundrySub/addLaundrySub",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/add_laundry_sub", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLaundrySub = createAsyncThunk(
  "laundrySub/updateLaundrySub",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(
        `update_laundry_sub/${id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLaundrySub = createAsyncThunk(
  "laundrySub/deleteLaundrySub",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.delete(`/delete_laundry_sub/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const laundrySubSlice = createSlice({
  name: "laundrySub",
  initialState: {
    laundrySubs: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLaundrySubs.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLaundrySubs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundrySubs = action.payload.data;
      })
      .addCase(fetchLaundrySubs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addLaundrySub.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addLaundrySub.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.laundrySubs.unshift(action.payload);
      })
      .addCase(addLaundrySub.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateLaundrySub.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateLaundrySub.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.laundrySubs = state.laundrySubs?.map((ls) =>
          ls.id === action.payload.id ? action.payload : ls
        );
      })
      .addCase(updateLaundrySub.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(deleteLaundrySub.pending, (state) => {
        state.status = "pending";
      })
      .addCase(deleteLaundrySub.fulfilled, (state, action) => {
        state.status = "fulfilled";
        const lsId = action.payload;
        state.laundrySubs = state.laundrySubs.filter((ls) => ls.id !== lsId);
      });
  },
});

export default laundrySubSlice.reducer;
