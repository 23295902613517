import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import productReducer from "./slices/productSlice";
import categoryReducer from "./slices/categorySlice";
import marketReducer from "./slices/marketSlice";
import orderOngoingReducer from "./slices/orderOngoingSlice";
import orderCancelledReducer from "./slices/orderCancelledSlice";
import orderCompletedReducer from "./slices/orderCompletedSlice";
import orderReportedReducer from "./slices/orderReportedSlice";
import orderReducer from "./slices/orderSlice";
import laundryReducer from "./slices/laundrySlice";
import laundryOngoingReducer from "./slices/laundryOngoingSlice";
import investmentReducer from "./slices/investmentSlice";
import investorInvestmentReducer from "./slices/investorInvestmentSlice";
import userReducer from "./slices/userSlice";
import countryReducer from "./slices/countrySlice";
import stateReducer from "./slices/stateSlice";
import provinceReducer from "./slices/provinceSlice";
import laundrySubReducer from "./slices/laundrySubSlice";
import orderPendingReducer from "./slices/orderPendingSlice";
import productSubReducer from "./slices/productSub";
import projectReducer from "./slices/projectSlice";
import isusuReducer from "./slices/isusuSlice";
import storeReducer from "./slices/storeSlice";
import pharmacyReducer from "./slices/pharmacySlice";
import fastFoodReducer from "./slices/fastFoodSlice";
import laundryPendingReducer from "./slices/laundryPendingSlice";
import laundryDisputedReducer from "./slices/laundryDisputedSlice";
import laundryCancelledReducer from "./slices/laundryCancelledSlice";
import laundryStoreReducer from "./slices/laundryStoreSlice";
import fuelReducer from "./slices/fuelSlice";
import shopperReducer from "./slices/shopperSlice";
import driverReducer from "./slices/driverSlice";
import dropoffReducer from "./slices/dropoffSlice";
import laundryItemReducer from "./slices/laundryItem";
import adminReducer from "./slices/adminSlice";
import transportReducer from "./slices/transportSlice";
import pushReducer from "./slices/pushSlice";
import ceoReducer from "./slices/ceoSlice";
import withdrawalReducer from "./slices/withdrawalSlice";
import paymentReducer from "./slices/paymentSlice";
import remitReducer from "./slices/remitSlice";
import feeSettingReducer from "./slices/feeSettingSlice";
import trackReducer from "./slices/trackSlice";
import itemReceivedReducer from "./slices/itemReceivedSlice";
import readyforDeliveryReducer from "./slices/readyforDeliverySlice";
import itemDeliveredReducer from "./slices/itemDeliveredSlice";
import laundryFeeSettingReducer from "./slices/laundryFeeSettingSlice";
import cartReducer from "./slices/cartSlice";
import monitorOrderReducer from "./slices/monitorOrderSlice";
import laundryReportedReducer from "./slices/laundryReportedSlice";
import laundryMonthlyReportReducer from "./slices/laundryMonthlyReportSlice";
import shoppingMonthlyReportReducer from "./slices/shoppingMonthlyReportSlice";
import investmentMonthlyReportReducer from "./slices/investmentMonthlyReportSlice";
import roleReducer from "./slices/rolesSlice";
import futureDeliveryReducer from "./slices/futureDeliverySlice"
import driverPushedOrderReducer from "./slices/driverPushedOrderSlice";
import balanceDetailsReducer from "./slices/balanceDetailsSlice";
import laundryPendingPaymentReducer from "./slices/laundryPendingPaymentSlice"
import tripperReducer from "./slices/trippersSlice";
import promotionReducer from "./slices/promotionsSlice";
import dailyRoiReducer from "./slices/dailyRoiSlice";
import couponHistoryReducer from "./slices/couponHistorySlice";
import laundryExpressReducer from "./slices/laundryExpressSlice";
import manualTransferReducer from "./slices/manualTransferSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    category: categoryReducer,
    market: marketReducer,
    orderOngoing: orderOngoingReducer,
    orderPending: orderPendingReducer,
    orderCancelled: orderCancelledReducer,
    orderCompleted: orderCompletedReducer,
    orderReported: orderReportedReducer,
    order: orderReducer,
    laundry: laundryReducer,
    laundryOngoing: laundryOngoingReducer,
    investment: investmentReducer,
    investorInvestment: investorInvestmentReducer,
    user: userReducer,
    country: countryReducer,
    state: stateReducer,
    province: provinceReducer,
    laundrySub: laundrySubReducer,
    productSub: productSubReducer,
    projects: projectReducer,
    isusu: isusuReducer,
    store: storeReducer,
    pharmacy: pharmacyReducer,
    fastFood: fastFoodReducer,
    laundryPending: laundryPendingReducer,
    laundryDisputed: laundryDisputedReducer,
    laundryCancelled: laundryCancelledReducer,
    laundryStore: laundryStoreReducer,
    fuel: fuelReducer,
    shopper: shopperReducer,
    driver: driverReducer,
    dropoff: dropoffReducer,
    laundryItem: laundryItemReducer,
    admin: adminReducer,
    transport: transportReducer,
    push: pushReducer,
    ceo: ceoReducer,
    withdrawal: withdrawalReducer,
    payment: paymentReducer,
    remit: remitReducer,
    feeSetting: feeSettingReducer,
    track: trackReducer,
    itemReceived: itemReceivedReducer,
    readyforDelivery: readyforDeliveryReducer,
    itemDelivered: itemDeliveredReducer,
    laundryFeeSetting: laundryFeeSettingReducer,
    cart: cartReducer,
    monitorOrder: monitorOrderReducer,
    laundryReported: laundryReportedReducer,
    laundryMonthlyReport: laundryMonthlyReportReducer,
    shoppingMonthlyReport: shoppingMonthlyReportReducer,
    investmentMonthlyReport: investmentMonthlyReportReducer,
    role: roleReducer,
    futureDelivery: futureDeliveryReducer,
    driverPushedOrder: driverPushedOrderReducer,
    balanceDetail: balanceDetailsReducer,
    laundryPendingPayment: laundryPendingPaymentReducer,
    tripper : tripperReducer,
    promotion : promotionReducer,
    dailyRoi : dailyRoiReducer,
    couponHistory: couponHistoryReducer,
    laundryExpress: laundryExpressReducer,
    manualTransfer: manualTransferReducer
  },
});

export default store;
