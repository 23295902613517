import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { paywithPaystack } from "../redux/slices/paymentSlice";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";

const PaymentOptions = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextOption, setNextOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const savedOption = localStorage.getItem("selectedPaymentOption");
    if (savedOption) {
      setSelectedOption(savedOption);
    }
  }, []);

  const toggleOption = () => {
    setNextOption(selectedOption === "manual" ? "paystack" : "manual");
    setIsModalOpen(true);
  };

  const confirmToggle = async () => {
    setIsLoading(true);
    const newOption = nextOption === "manual" ? "manual" : "paystack";
    setSelectedOption(newOption);
    localStorage.setItem("selectedPaymentOption", newOption);

    const res = await dispatch(paywithPaystack());
    if (res.payload.message === "Paystack locked") {
      setSelectedOption("manual");
      localStorage.setItem("selectedPaymentOption", "manual");
      toast.error(res.payload.message);
    } else {
      toast.success(res.payload.message);
    }

    setIsModalOpen(false);
    setIsLoading(false);
  };

  const cancelToggle = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-between items-center">
      <div
        onClick={toggleOption}
        role="switch"
        aria-checked={selectedOption === "manual"}
        className={`relative flex justify-center items-center w-44 h-12 rounded-3xl border-2 cursor-pointer transition-all duration-500 
          ${selectedOption === "manual" ? "bg-green-500" : "bg-blue-300"} 
          border-gray-300`}
      >
        <div
          className={`absolute flex justify-between items-center w-full px-2 text-sm transition-opacity duration-500 ${
            selectedOption === "manual" ? "opacity-100" : "opacity-0"
          }`}
        >
          <span className="text-transparent">Manual</span>
          <span className="text-gray-50">Paystack</span>
        </div>
        <div
          className={`absolute flex justify-between items-center w-full px-2 text-sm transition-opacity duration-500 ${
            selectedOption === "paystack" ? "opacity-100" : "opacity-0"
          }`}
        >
          <span className="text-gray-50">Manual</span>
          <span className="text-transparent">Paystack</span>
        </div>

        <span
          className={`absolute transition-all duration-500 h-8 w-20 bg-white rounded-2xl font-semibold flex items-center justify-center
            ${selectedOption === "manual" ? "left-1" : "left-20"}`}
        >
          {selectedOption === "manual" ? "Manual" : "Paystack"}
        </span>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
            <p className="mb-4">
              Do you want to switch to{" "}
              {nextOption === "manual" ? "Manual" : "Paystack"}?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
                onClick={cancelToggle}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center justify-center"
                onClick={confirmToggle}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center gap-2">
                    <ImSpinner2 className="animate-spin text-base text-gray-50" />
                    Confirming...
                  </div>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentOptions;
