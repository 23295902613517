import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

// Define the initial state
const initialState = {
  status: "idle",
  error: null,
};

export const trackOngoingOrder = createAsyncThunk(
  "track/trackOngoingOrder",
  async (orderId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/laundry/orders/${orderId}/ongoing`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data; //
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const trackReadyDelivery = createAsyncThunk(
  "track/trackReadyDelivery",
  async (orderId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/laundry/orders/${orderId}/delivery`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data; //
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const trackItemDelivered = createAsyncThunk(
  "track/trackItemDelivered",
  async (orderId, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/laundry/orders/${orderId}/delivered`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data; //
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const trackItemReceived = createAsyncThunk(
    "track/trackItemReceived",
    async (orderId, thunkAPI) => {
      try {
        const token = localStorage.getItem("token");
        const response = await apiService.put(`/laundry/orders/${orderId}/received`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
  
        return response.data; //
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

export const trackOrderDispute = createAsyncThunk(
    "track/trackOrderDispute",
    async (orderId, thunkAPI) => {
      try {
        const token = localStorage.getItem("token");
        const response = await apiService.put(`/laundry/orders/${orderId}/dispute`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
  
        return response.data; //
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

const trackSlice = createSlice({
  name: "track",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(trackOngoingOrder.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(trackOngoingOrder.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(trackOngoingOrder.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(trackReadyDelivery.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(trackReadyDelivery.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(trackReadyDelivery.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(trackItemDelivered.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(trackItemDelivered.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(trackItemDelivered.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(trackOrderDispute.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(trackOrderDispute.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(trackOrderDispute.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(trackItemReceived.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(trackItemReceived.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(trackItemReceived.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      });
      
  },
});

export default trackSlice.reducer;
