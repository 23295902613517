import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImSpinner2 } from "react-icons/im";
import {
  addCategory,
  fetchCategories,
} from "../../../redux/slices/categorySlice";

export default function AddCategory() {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();


  const status = useSelector((state) => state.category?.status);

  const initialFormData = {
    name: "",
    owner_id: "",
    owner_type: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      const res = await dispatch(addCategory(formData));
      setFormData({ ...initialFormData });
      await dispatch(fetchCategories());
      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        Add Category
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Add Category
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-4 ">
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="name"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name
                      </label>
                      <div className="flex items-center">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          placeholder="Type name here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>

                    {/* <div className="flex flex-col gap-1">
                      <label
                        htmlFor="shopping_cost"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Owner Id
                      </label>
                      <div className="flex items-center">
                        <input
                          name="owner_id"
                          id="owner_id"
                          type="number"
                          value={foodId}
                          readOnly
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div> */}

                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="owner_type"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Type of Stores
                      </label>
                      <div className="flex items-center">
                        <select
                          name="owner_type"
                          id="owner_type"
                          value={formData.owner_type}
                          onChange={handleInputChange}
                          required
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option value="" className="text-[#333843]">
                            Choose type
                          </option>
                          <option value="market">Market</option>
                          <option value="store">Store</option>
                          <option value="fastfood">Fast Food</option>
                          <option value="pharmacy">Pharmacy</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex mt-6 gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <ImSpinner2 className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Adding ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Add Category
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
