import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const addLaundryPickupCityFee = createAsyncThunk(
  "laundryFeeSetting/addLaundryPickupCityFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/laundry/pickup-city",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLaundryPickupStateFee = createAsyncThunk(
  "laundryFeeSetting/addLaundryPickupStateFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/laundry/pickup-state",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLaundryDeliveryCityFee = createAsyncThunk(
  "laundryFeeSetting/addLaundryDeliveryCityFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/laundry/delivery-city",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLaundryDeliveryStateFee = createAsyncThunk(
  "laundryFeeSetting/addLaundryDeliveryStateFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/laundry/delivery-state",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const laundryFeeSettingSlice = createSlice({
  name: "laundryFeeSetting",
  initialState: {
    laundryFeeSettings: [],
    laundryPickupCityFeeStatus: "idle",
    laundryPickupStateFeeStatus: "idle",
    laundryDeliveryCityFeeStatus: "idle",
    laundryDeliveryStateFeeStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(addLaundryPickupCityFee.pending, (state) => {
        state.laundryPickupCityFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addLaundryPickupCityFee.fulfilled, (state, action) => {
        state.laundryPickupCityFeeStatus = "succeeded";
        state.laundryFeeSettings = action.payload;
      })
      .addCase(addLaundryPickupCityFee.rejected, (state, action) => {
        state.laundryPickupCityFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addLaundryPickupStateFee.pending, (state) => {
        state.laundryPickupStateFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addLaundryPickupStateFee.fulfilled, (state, action) => {
        state.laundryPickupStateFeeStatus = "succeeded";
        state.laundryFeeSettings = action.payload;
      })
      .addCase(addLaundryPickupStateFee.rejected, (state, action) => {
        state.laundryPickupStateFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addLaundryDeliveryCityFee.pending, (state) => {
        state.laundryDeliveryCityFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addLaundryDeliveryCityFee.fulfilled, (state, action) => {
        state.laundryDeliveryCityFeeStatus = "succeeded";
        state.laundryFeeSettings = action.payload;
      })
      .addCase(addLaundryDeliveryCityFee.rejected, (state, action) => {
        state.laundryDeliveryCityFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addLaundryDeliveryStateFee.pending, (state) => {
        state.laundryDeliveryStateFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addLaundryDeliveryStateFee.fulfilled, (state, action) => {
        state.laundryDeliveryStateFeeStatus = "succeeded";
        state.laundryFeeSettings = action.payload;
      })
      .addCase(addLaundryDeliveryStateFee.rejected, (state, action) => {
        state.laundryDeliveryStateFeeStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default laundryFeeSettingSlice.reducer;
