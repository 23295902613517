import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

// Define the initial state
const initialState = {
  userStatus: "idle",
  shopperStatus: "idle",
  driverStatus: "idle",
  error: null,
};

export const pushNotifyUser = createAsyncThunk(
  "push/sendNotification",
  async (pushNote, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/push", pushNote, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data; //
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const pushNotifyDriver = createAsyncThunk(
  "push/pushNotifyDriver",
  async (pushNote, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/push-driver", pushNote, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data; //
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const pushNotifyShopper = createAsyncThunk(
  "push/pushNotifyShopper",
  async (pushNote, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/push-shopper", pushNote, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data; //
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const pushSlice = createSlice({
  name: "push",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pushNotifyUser.pending, (state) => {
        state.userStatus = "loading";
        state.error = null;
      })
      .addCase(pushNotifyUser.fulfilled, (state) => {
        state.userStatus = "idle";
        state.error = null;
      })
      .addCase(pushNotifyUser.rejected, (state, action) => {
        state.userStatus = "idle";
        state.error = action.payload;
      })
      .addCase(pushNotifyDriver.pending, (state) => {
        state.driverStatus = "loading";
        state.error = null;
      })
      .addCase(pushNotifyDriver.fulfilled, (state) => {
        state.driverStatus = "idle";
        state.error = null;
      })
      .addCase(pushNotifyDriver.rejected, (state, action) => {
        state.driverStatus = "idle";
        state.error = action.payload;
      })
      .addCase(pushNotifyShopper.pending, (state) => {
        state.shopperStatus = "loading";
        state.error = null;
      })
      .addCase(pushNotifyShopper.fulfilled, (state) => {
        state.shopperStatus = "idle";
        state.error = null;
      })
      .addCase(pushNotifyShopper.rejected, (state, action) => {
        state.shopperStatus = "idle";
        state.error = action.payload;
      });
  },
});

export default pushSlice.reducer;
