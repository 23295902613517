import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";
import { addProduct } from "../../../redux/slices/productSlice";
import { fetchMarketDetail } from "../../../redux/slices/marketSlice";
import { formatNumberWithCommas2 } from "../../../utils/commonUtils";
import UploadMultiImage from "../../UploadMultiImage";

export default function AddProductMarket({
  marketId: id,
  city,
  state,
  country,
  page,
}) {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();



  const categories = JSON.parse(localStorage.getItem("categories"));

  const categoryMarket = categories?.filter(
    (category) => category?.owner_type === "market"
  );

  const status = useSelector((state) => state.product?.status);

  const initialFormData = {
    name: "",
    description: "",
    price: "",
    type: "",
    category: "",
    quantity: "",
    owner_id: "",
    image: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    owner_type: "market",
    city: city,
    state: state,
    country: country,
    color: [],
    size: [],
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value.replace(/,/g, "");

    const updatedMarketId = Number.isNaN(Number(id)) ? 0 : Number(id);

    setFormData({
      ...formData,
      [name]: updatedValue,
      owner_id: updatedMarketId,
      quantity: 1000,
      country: country,
      state: state,
      city: city,
    });
  };

  const handleUploadImages = (files) => {
    const newFormData = { ...formData };
    files.forEach((file) => {
      if (newFormData.image === null) {
        newFormData.image = file;
      } else {
        for (let i = 2; i <= 5; i++) {
          if (newFormData[`image${i}`] === null) {
            newFormData[`image${i}`] = file;
            break;
          }
        }
      }
    });

    setFormData(newFormData);
  };

  const handleCheckboxChange = (e, field) => {
    const { value, checked } = e.target;

    setFormData((prevFormData) => {
      let updatedArray = [...prevFormData[field]];

      if (checked) {
        updatedArray.push(value);
      } else {
        updatedArray = updatedArray.filter((item) => item !== value);
      }

      return { ...prevFormData, [field]: updatedArray };
    });
  };

  const colors = [
    "red",
    "blue",
    "black",
    "green",
    "yellow",
    "purple",
    "orange",
    "pink",
    "grey",
    "gold",
  ];
  const sizes = ["XS", "S", "M", "L", "XL", "XXL"];
  // const shoe_sizes = ["XS", "S", "M", "L", "XL", "XXL"];
  const uk_sizes = ["5", "6", "7", "8", "9", "10", "11", "12"];
  const euro_sizes = ["39", "40", "41", "42", "43", "44", "45", "46", "47"];
  const cm_sizes = ["23.5", "24.6", "26.2", "27.1", "27.9", "28.8", "29.6"];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.price ||
      !formData.type ||
      !formData.category
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }

 
    const cleanedFormData = { ...formData };
    ["image", "image2", "image3", "image4", "image5"].forEach((imageKey) => {
      if (cleanedFormData[imageKey] === null) {
        delete cleanedFormData[imageKey];
      }
    });

    try {
      const formData = cleanedFormData;
      const res = await dispatch(addProduct(formData));

      setFormData({ ...initialFormData });

      await dispatch(fetchMarketDetail({id,page}));
      setShowModal(false);
      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        Add Product
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-6xl max-h-96 flex justify-center items-center">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Add Product
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-row gap-6 ">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="name"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name
                        </label>

                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          placeholder="Type name here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          required
                        />
                      </div>

                      <div className="flex flex-col gap-2  bg-white rounded-lg shadow">
                        <UploadMultiImage onUpload={handleUploadImages} />
                      </div>

                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="price"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Price
                        </label>
                        <div className="flex items-center">
                          <input
                            name="price"
                            id="price"
                            type="text"
                            value={formatNumberWithCommas2(formData.price)}
                            onChange={handleInputChange}
                            placeholder="Type price here..."
                            className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                            required
                          />
                        </div>
                      </div>

                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Type
                        </label>
                        <select
                          name="type"
                          id="type"
                          value={formData.type}
                          onChange={handleInputChange}
                          required
                          className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option value="" className="text-[#333843]" disabled>
                            Choose type
                          </option>
                          <option value="trending">Trending</option>
                          <option value="general">General</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex flex-col w-80 gap-4">
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="description"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Description
                        </label>
                        <textarea
                          name="description"
                          id="description"
                          cols="20"
                          rows="10"
                          value={formData.description}
                          onChange={handleInputChange}
                          className="bg-[#F9F9FC] border-[#E0E2E7] border-2 text-[14px] text-[#333843] font-normal tracking-[0.07px] p-3 rounded-[8px]"
                          placeholder="Type product description here..."
                          required
                        ></textarea>
                      </div>

                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="category"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Select Category
                        </label>

                        <select
                          id="category"
                          name="category"
                          value={formData.category}
                          onChange={handleInputChange}
                          className="px-3 py-3 text-[#333843] border-2 p-3 text-[14px] rounded-lg bg-[#F9F9FC] w-full border-[#E0E2E7]"
                          required
                        >
                          <option value="">Select Category</option>
                          {categoryMarket?.map((cm) => (
                            <option key={cm.id} value={cm.name}>
                              {cm.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* Colors */}
                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-base text-[#4D5464] font-medium"
                        >
                          Colors
                        </label>
                        <div className="w-80 break-words flex flex-wrap gap-2">
                          {colors.map((color) => (
                            <label key={color} className="flex items-center">
                              <input
                                type="checkbox"
                                value={color}
                                checked={formData.color.includes(color)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "color")
                                }
                                className="mr-2"
                              />
                              {color}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Size */}
                    <div className="flex flex-col gap-4">
                      {/* Sizes */}
                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Sizes
                        </label>
                        <div className="flex flex-wrap gap-2">
                          {sizes.map((size) => (
                            <label key={size} className="flex items-center">
                              <input
                                type="checkbox"
                                value={size}
                                checked={formData.size.includes(size)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "size")
                                }
                                className="mr-2"
                              />
                              {size}
                            </label>
                          ))}
                        </div>
                      </div>
                      {/* Shoe CM */}
                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Shoe CM
                        </label>
                        <div className="flex flex-wrap gap-2">
                          {cm_sizes.map((size) => (
                            <label key={size} className="flex items-center">
                              <input
                                type="checkbox"
                                value={size}
                                checked={formData.size.includes(size)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "size")
                                }
                                className="mr-2"
                              />
                              {size}
                            </label>
                          ))}
                        </div>
                      </div>
                      {/* Euro size */}
                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Euro size
                        </label>
                        <div className="flex flex-wrap gap-2">
                          {euro_sizes.map((size) => (
                            <label key={size} className="flex items-center">
                              <input
                                type="checkbox"
                                value={size}
                                checked={formData.size.includes(size)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "size")
                                }
                                className="mr-2"
                              />
                              {size}
                            </label>
                          ))}
                        </div>
                      </div>
                      {/* UK Sizes */}
                      <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Uk Sizes
                        </label>
                        <div className="flex flex-wrap gap-2">
                          {uk_sizes.map((size) => (
                            <label key={size} className="flex items-center">
                              <input
                                type="checkbox"
                                value={size}
                                checked={formData.size.includes(size)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "size")
                                }
                                className="mr-2"
                              />
                              {size}
                            </label>
                          ))}
                        </div>
                      </div>
                      {/* UK Sizes */}
                      {/* <div className="flex flex-col gap-[1px]">
                        <label
                          htmlFor="location"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Uk Sizes
                        </label>
                        <div className="flex flex-wrap gap-2">
                          {shoe_sizes.map((size) => (
                            <label key={size} className="flex items-center">
                              <input
                                type="checkbox"
                                value={size}
                                checked={formData.size.includes(size)}
                                onChange={(e) =>
                                  handleCheckboxChange(e, "size")
                                }
                                className="mr-2"
                              />
                              {size}
                            </label>
                          ))}
                        </div>
                      </div> */}
                      {/* Button */}
                      <div className="flex mt-20 gap-4 justify-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="text-sm text-blue-500  w-full font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                        >
                          Cancel
                        </button>
                        <button
                          className="flex gap-1 rounded-lg w-full items-center bg-blue-600 text-white py-2 px-4"
                          disabled={status === "loading"}
                          onClick={handleSubmit}
                        >
                          {status === "loading" ? (
                            <>
                              <ImSpinner2 className="animate-spin" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Adding ...
                              </p>
                            </>
                          ) : (
                            <>
                              <FaTimes className="w-4 h-4" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Add Product
                              </p>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
