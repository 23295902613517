import React, { useEffect, useState } from "react";
import { FaEdit, FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInvestments,
  updateInvestment,
} from "../../../redux/slices/investmentSlice";

import { toast } from "react-toastify";

export default function ModalUpdateInvestment({
  id,
  image,
  name,
  months,
  minimum,
  roi,
  type,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const status = useSelector((state) => state.investment?.status);

  const dispatch = useDispatch();

  useEffect(() => {
    setUpdatedData({ image, name, months, minimum, roi, type });
  }, [image, name, months, minimum, roi, type]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;
    if (name === "months" || name === "minimum" || name === "roi") {
      updatedValue = parseInt(value, 10) || 0;
    }

    setUpdatedData({
      ...updatedData,
      [name]: updatedValue,
    });
  };

  const handleUpdateInvestment = async () => {
    try {
      const res = await dispatch(updateInvestment({ id, updatedData }));

      toast.success(res.payload.message);
      dispatch(fetchInvestments());
    } catch (error) {}

    setShowModal(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className="w-4 h-4 text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>

                <div className=" text-center m-4">
                  <h1 className="text-2xl text-gray-800 font-medium mb-4">
                    Update Investment
                  </h1>
                </div>
                <div className=" flex flex-rol">
                  <div className="flex flex-col items-center px-8 py-4 gap-8">
                    <img
                      src={image}
                      alt={name}
                      className="w-[224px] h-[269px] rounded-lg ring-[11px] ring-[#D9D9D9]"
                    />

                    <div className="flex flex-col">
                      <label
                        htmlFor="name"
                        className="text-sm text-gray-600 mb-1"
                      >
                        Name:
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={updatedData.name}
                        onChange={handleInputChange}
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-5 ">
                      <div className="flex flex-col">
                        <label
                          htmlFor="months"
                          className="text-sm text-gray-600 mb-1"
                        >
                          Minimum No. of Months
                        </label>
                        <input
                          type="number"
                          name="months"
                          value={updatedData.months}
                          onChange={handleInputChange}
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor="minimum"
                          className="text-sm text-gray-600 mb-1"
                        >
                          Minimum
                        </label>
                        <input
                          type="number"
                          name="minimum"
                          value={updatedData.minimum}
                          onChange={handleInputChange}
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor="roi"
                          className="text-sm text-gray-600 mb-1"
                        >
                          Return of Investment
                        </label>
                        <input
                          type="number"
                          name="roi"
                          value={updatedData.roi}
                          onChange={handleInputChange}
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>

                      <div className="flex flex-col">
                        <label
                          htmlFor="type"
                          className="text-sm text-gray-600 mb-1"
                        >
                          Type
                        </label>
                        <select
                          name="type"
                          value={updatedData.type}
                          onChange={handleInputChange}
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        >
                          <option value="trending">Trending</option>
                          <option value="new">New</option>
                        </select>
                      </div>

                      <div className="flex gap-4 justify-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleUpdateInvestment}
                          className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                          disabled={status === "loading"}
                        >
                          {status === "loading" ? (
                            <>
                              <FaSpinner className="animate-spin" />
                              <p className="text-sm font-semibold">
                                Update Investment
                              </p>
                            </>
                          ) : (
                            <>
                              <FaTimes className="w-4 h-4" />
                              <p className="text-sm font-semibold">
                                Update Investment
                              </p>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
