import React, { useState, useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchIsusu, fetchIsusuDefaulter } from "../../redux/slices/isusuSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
import { fetchUsers } from "../../redux/slices/userSlice";
import ModalIsusuAdminPayment from "../../components/modals/investment/ModalIsusuAdminPayment";
import { FaTrash } from "react-icons/fa";

const IsusuDefaulter = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIsusuDefaulter());
    dispatch(fetchUsers());
    dispatch(fetchIsusu())
  }, [dispatch]);

  const isusuDefaulters = useSelector((state) => state.isusu?.isusuDefaulters);
  const usersList = useSelector((state) => state.user?.users);
  const isusus = useSelector((state) => state.isusu?.isusu);

  // console.log(isusuDefaulters);

  const reversedIsususDefaulters = isusuDefaulters?.slice().reverse();

  // Filter categories based on search term
  const filteredIsususDefaulters = reversedIsususDefaulters?.filter((order) =>
    search.toLowerCase() === ""
      ? order
      : order.username.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentIsususDefaulters = filteredIsususDefaulters?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredIsususDefaulters?.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.isusu?.status);
  const error = useSelector((state) => state.isusu?.error);

  const getUserDataFromUserId = (userId) => {
    const user = usersList?.find((user) => +user.id === +userId);

    return user
      ? {
          address: user.address || "No Address",
          phone: user.phone || "No Phone",
        }
      : { address: "No Address", phone: "No Phone" };
  };

  const getGroupDataFromGroupId = (groupId) => {
    const isusu = isusus?.find((isusu) => +isusu.id === +groupId);

    return isusu
      ? {
          name: isusu.name || "No Group",
        }
      : { name: "No Group" };
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Isusu Defaulters
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Isusu Defaulters
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full whitespace-nowrap text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-left">Customer Name</th>
                <th className="px-4 py-2 text-left w-48">Address</th>
                <th className="px-4 py-3 text-center">Isusu Group</th>
                <th className="px-4 py-3 text-center">Amount</th>
                <th className="px-4 py-3 text-center">Mode of Payment</th>
                <th className="px-4 py-3 text-center">Month Ended</th>
                <th className="px-4 py-3 text-center">Created Date</th>
                <th className="px-4 py-3 text-center">Updated Date</th>

                <th className="px-4 py-3 text-center">Pay for User</th>
                <th className="px-4 py-3 text-center">Status</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentIsususDefaulters?.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentIsususDefaulters?.map((order, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-left">
                      <div className=" flex flex-col gap-1  text-gray-600">
                        <p className=" text-base font-semibold">
                          {order.username}
                        </p>
                        {/* <p>{order.email}</p> */}
                        <p>{getUserDataFromUserId(order.user_id).phone}</p>
                      </div>
                    </td>

                    <td className="px-4 py-2 text-left">
                      <div className="">
                        <p className="text-[14px] font-medium whitespace-normal w-48 break-words ">
                          {getUserDataFromUserId(order.user_id).address}
                        </p>
                      </div>
                    </td>

                    <td className="px-4 py-3 text-center">
                      <div className="flex flex-col gap-1">
                        <p className="px-4 py-1 text-[14px] font-medium whitespace-normal w-48 break-words ">
                          {getGroupDataFromGroupId(order.group_id).name}
                        </p>
                      </div>
                    </td>

                    <td className="px-4 py-4">
                      <div className=" flex justify-center items-center">
                        <span className="mr-1">&#8358;</span>
                        {order.amount
                          ? formatNumberWithCommas(order.amount)
                          : 0}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {order.mode ? order.mode : "No Info"}
                    </td>  
                     <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.end_month)}
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.updated_at)}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex gap-[8px] items-center justify-center">
                        <ModalIsusuAdminPayment
                          username={order.username}
                          amount={order.amount? order.amount : 0}
                          group_id={order.group_id}
                          user_id={order.user_id}
                        />
                      </div>
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {order.status ? order.status: "-"}
                    </td>
                    <td className="px-4 py-4">
                    <div className="flex gap-[8px] items-center justify-center">
                        <FaTrash />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {isusuDefaulters?.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsusuDefaulter;
