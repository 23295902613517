import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchAdmins, resetPasswordAdmin } from "../redux/slices/adminSlice";

const ModalChangePassword = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const admins = useSelector((state) => state.admin?.admins);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword === confirmPassword) {
      const formData = new FormData();
      formData.append("id", selectedAdmin);
      formData.append("pass1", newPassword);
      formData.append("pass2", confirmPassword);

      setIsLoading(true); // Start loading

      // Convert FormData to a plain object for easier logging
      const formDataObj = Object.fromEntries(formData.entries());

      console.log(formDataObj);

      try {
        // Dispatch the action to reset the password with a plain object
        const res = await dispatch(resetPasswordAdmin(formDataObj));

        console.log(res);

        await dispatch(fetchAdmins());

        toast.success(res.payload.message)
        // toast.success("Password changed successfully!");

        // Clear the fields after successful submission
        setSelectedAdmin("");
        setNewPassword("");
        setConfirmPassword("");

        setIsOpen(false); // Close the modal after submission
      } catch (error) {
        toast.error("Failed to change password. Please try again.");
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      toast.error("Passwords do not match!");
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="flex gap-2 text-blue-500 border-2 items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-semibold"
      >
        Change Passwords
      </button>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Change Admin Password</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2 font-medium text-gray-600">
                  Select Admin
                </label>
                <select
                  value={selectedAdmin}
                  onChange={(e) => setSelectedAdmin(e.target.value)}
                  className="p-2 border rounded w-full"
                  required
                  disabled={isLoading} // Disable during loading
                >
                  <option value="" disabled>
                    Select an Admin
                  </option>
                  {admins.map((admin) => (
                    <option key={admin.id} value={admin.id}>
                      {admin.name} ({admin.email})
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4 relative">
                <label className="block mb-2 text-gray-600 font-medium">
                  New Password
                </label>
                <input
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="p-2 border rounded w-full pr-10"
                  required
                  disabled={isLoading} // Disable during loading
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-2 mt-8 flex items-center text-gray-600"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  disabled={isLoading} // Disable during loading
                >
                  {showNewPassword ? (
                    <IoEyeOffOutline className="text-2xl" />
                  ) : (
                    <IoEyeOutline className="text-2xl" />
                  )}
                </button>
              </div>

              <div className="mb-4 relative">
                <label className="block mb-2 font-medium text-gray-600">
                  Confirm Password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="p-2 border rounded w-full pr-10"
                  required
                  disabled={isLoading} // Disable during loading
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-2 flex mt-8 items-center text-gray-600"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  disabled={isLoading} // Disable during loading
                >
                  {showConfirmPassword ? (
                    <IoEyeOffOutline className="text-2xl" />
                  ) : (
                    <IoEyeOutline className="text-2xl" />
                  )}
                </button>
              </div>

              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                  disabled={isLoading} // Disable during loading
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isLoading} // Disable during loading
                >
                  {isLoading ? "Changing..." : "Change Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalChangePassword;
