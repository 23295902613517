import axios from "axios";

// Base URL from environment variables
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Create an axios instance
export const apiService = axios.create({
  baseURL: baseURL,
});

// Add an interceptor to inject the token into headers dynamically
apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // Fetch token dynamically
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Login API function
export const loginAPI = async (formData) => {
  try {
    const response = await axios.post(`${baseURL}/admin/login`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Save the token in localStorage after successful login
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
    }

    return response.data;
  } catch (error) {
    // Provide detailed error feedback if available
    const errorMessage = error.response?.data?.message || "Login request failed";
    throw new Error(errorMessage);
  }
};
