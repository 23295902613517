import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PiUploadLight } from "react-icons/pi";

import { ImSpinner2 } from "react-icons/im";
import { FaPlus, FaTimes } from "react-icons/fa";
import UploadImage from "./UploadImage";
import { useDispatch, useSelector } from "react-redux";
import { addCeoNote, fetchCeoNote } from "../redux/slices/ceoSlice";

export default function NoteUpload() {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const initialFormData = {
    note: "",
    image: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const status = useSelector((state) => state.ceo?.status);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUploadImage = (file) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(addCeoNote(formData));

      await dispatch(fetchCeoNote());
      toast.success(res.payload.message);
      setFormData({ ...initialFormData });
    } catch (error) {
      console.error("Error:", error);
    }
    setShowModal(false);
  };
  return (
    <>
      <button
        className="bg-[#dee8f5] p-2 px-2 font-extrabold rounded-md  flex items-center justify-center gap-2  text-[#1F7BF4] text-xs"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <PiUploadLight className=" text-lg font-bold" />
        Update Note
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-lg font-semibold">Add Note</h3>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                  >
                    <FaTimes className="text-gray-300 text-xl" />
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/*body*/}

                <div className="relative p-6 ">
                  <div className=" flex flex-row  gap-8">
                    <div>
                      <UploadImage onUpload={handleUploadImage} />
                    </div>
                    <div>
                      <textarea
                        name="note"
                        id=""
                        cols="30"
                        rows="7"
                        onChange={handleInputChange}
                        value={formData.note}
                        placeholder="Type Note here"
                        className=" border-2 rounded-lg p-2"
                      ></textarea>
                    </div>
                  </div>
                </div>
                {/*footer*/}

                <div className="flex items-center justify-end gap-2 p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <div className="flex mt-8 gap-4 justify-center">
                    <button
                      onClick={() => setShowModal(false)}
                      className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                      disabled={status === "loading"}
                      onClick={handleSubmit}
                    >
                      {status === "loading" ? (
                        <>
                          <ImSpinner2 className="animate-spin" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Adding ...
                          </p>
                        </>
                      ) : (
                        <>
                          <FaPlus className="w-4 h-4" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Add Note
                          </p>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
