import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";


export const fetchFuels = createAsyncThunk(
  "fuel/fetchFuels",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/fuel/types", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addFuel = createAsyncThunk(
  "fuel/addFuel",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/fuel/store-kind", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFuel = createAsyncThunk(
  "fuel/updateFuel",
  async ({ id, updateData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/fuel/update/${id}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFuel = createAsyncThunk(
  "fuel/deleteFuel",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const res = await apiService.delete(`/fuel/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);

const fuelSlice = createSlice({
  name: "fuel",
  initialState: {
    fuels: [],
    status: "idle",
    error: null,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFuels.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchFuels.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fuels = action.payload.data;
      })
      .addCase(fetchFuels.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addFuel.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addFuel.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fuels.unshift(action.payload);
      })
      .addCase(addFuel.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteFuel.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFuel.fulfilled, (state, action) => {
        state.status = "success";
        const fuelId = action.payload;
        state.fuels = state.fuels.filter((fuel) => fuel.id !== fuelId);
      })
      .addCase(deleteFuel.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateFuel.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFuel.fulfilled, (state, action) => {
        state.status = "success";
        state.fuels = state.fuels?.map((fuel) =>
          fuel.id === action.payload.id ? action.payload : fuel
        );
      })
      .addCase(updateFuel.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      });
  },
});

export default fuelSlice.reducer;
