import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";

import { IoEyeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchLaundryStore } from "../../redux/slices/laundryStoreSlice";
import { formatCreatedAt } from "../../utils/commonUtils";

import ModalAddLaundryStore from "../../components/modals/laundry/ModalAddLaundryStore";
import { ImSpinner2 } from "react-icons/im";
import ModalUpdateLaundryStore from "../../components/modals/laundry/ModalUpdateLaundryStore";
import ModalDeleteLaundryStore from "../../components/modals/laundry/ModalDeleteLaundryStore";

const LaundryStore = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLaundryStore());
  }, [dispatch]);

  const laundryStores = useSelector(
    (state) => state.laundryStore?.laundryStores
  );

  const reversedLaundryStores = laundryStores.slice().reverse();

  // Filter categories based on search term
  const filteredLaundryStores = reversedLaundryStores.filter((order) =>
    search.toLowerCase() === ""
      ? order
      : order.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentLaundryStores = filteredLaundryStores.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredLaundryStores.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.laundryStore?.status);
  const error = useSelector((state) => state.laundryStore?.error);
  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };



  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Laundry Stores
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Laundry</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium"> Laundry Stores</p>
          </div>
        </div>
      
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search store..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

        <div className="flex items-center gap-4">
          <ModalAddLaundryStore />
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full text-sm whitespace-nowrap text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-4 py-4 text-center">Name</th>
                <th className="px-4 py-3 text-center">Country</th>
                <th className="px-4 py-3 text-center">State</th>
                <th className="px-4 py-3 text-center">City</th>

                <th className="px-4 py-3 text-center">Created</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
            {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentLaundryStores.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentLaundryStores?.map((order, index) => (
                  <tr
                    key={order.id}
                    className="bg-white border-b whitespace-nowrap"
                  >
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-center">
                      <Link to={`/dashboard/laundry/laundry-store/${order.id}`}>
                        <p className="px-4 py-1 text-sm font-semibold text-blue-600">
                          {order.name}
                        </p>
                      </Link>
                    </td>
                    <td className="px-4 py-2 text-center">
                      {getCountryNameById(order.country)}
                    </td>

                    <td className="px-4 py-2 text-center">
                      {getStateNameById(order.state)}
                    </td>

                    <td className="px-4 py-2 text-center">
                      <p>{getCityNameById(order.city)}</p>
                    </td>
                    <td className="px-4 py-2 text-center">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-2 py-4">
                      <div className="flex gap-4  justify-center">
                        <Link
                          to={`/dashboard/laundry/laundry-store/${order.id}`}
                        >
                          <IoEyeOutline className="text-lg font-bold text-[#667085]" />
                        </Link>

                        <div>
                          <ModalUpdateLaundryStore
                            id={order.id}
                            name={order.name}
                            country={order.country}
                            state={order.state}
                            city={order.city}
                          />
                        </div>

                        <div>
                          <ModalDeleteLaundryStore
                            id={order.id}
                            name={order.name}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {laundryStores.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaundryStore;
