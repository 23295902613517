import React, { useEffect } from "react";

import { HiChevronRight } from "react-icons/hi";
import { IoMdCall, IoMdMail, IoMdPerson } from "react-icons/io";
import { VscReferences } from "react-icons/vsc";
import { IoLocationOutline } from "react-icons/io5";
import { BsBank2 } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import { convertValue, formatCreatedAt } from "../../utils/commonUtils";
import {
  FaAddressCard,
  FaCity,
  FaCreditCard,
  FaFileCode,
} from "react-icons/fa";
import { MdDateRange, MdOutlineDomainVerification } from "react-icons/md";

import {
  fetchShopperDetail,
  selectShopperDetail,
} from "../../redux/slices/shopperSlice";
import ImageFullScreen from "../../components/ImageFullScreen";
import { TbNumber } from "react-icons/tb";

const ShopperPromotionDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchShopperDetail(id));
  }, [dispatch, id]);

  const shopperDetail = useSelector(selectShopperDetail);

  const status = useSelector((state) => state.shopper?.shopperDetailStatus);
  const error = useSelector((state) => state.shopper?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameFromId = (countryId) => {
    const country = countries?.find(
      (country) => country.id === parseInt(countryId, 10)
    );
    return country ? country.name : "Unknown";
  };

  const getStateNameFromId = (stateId) => {
    const state = states?.find((state) => state.id === parseInt(stateId, 10));
    return state ? state.name : "Unknown";
  };

  const getCityNameFromId = (cityId) => {
    const city = provinces?.find((city) => city.id === parseInt(cityId, 10));
    return city ? city.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Shopper Promotion Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to={"/dashboard/shoppers/promotion"}>
              <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopper Promotion</p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-[#667085] font-medium">
              Shopper Promotion Detail
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {shopperDetail ? (
            <div class="flex mt-8 gap-6">
              <div class="flex flex-col gap-6 w-1/2">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      Shopper Information
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.name}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdMail size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Email
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.email}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCall size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Phone
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.phone}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaAddressCard size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Address
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] w-48 break-words text-right font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.address
                            ? shopperDetail?.data?.shopper?.address
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <VscReferences size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Referred By
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.referred_by
                            ? shopperDetail?.data?.shopper?.referred_by
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaFileCode size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Ref Code
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.ref_code}
                        </p>
                      </div>
                      <div className="flex justify-between items-center gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <TbNumber size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            No. of Referrals
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.referrals.length}
                        </p>
                      </div>
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaCity size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              City
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {getCityNameFromId(
                              shopperDetail?.data?.shopper?.city
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              State
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {getStateNameFromId(
                              shopperDetail?.data?.shopper?.state
                            )}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Country
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {getCountryNameFromId(
                              shopperDetail?.data?.shopper?.country
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdOutlineDomainVerification
                                size={20}
                                color="#667085"
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Verified
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {convertValue(shopperDetail?.data?.shopper?.verified)}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdOutlineDomainVerification
                                size={20}
                                color="#667085"
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Suspeneded
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {convertValue(
                            shopperDetail?.data?.shopper?.suspended
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdDateRange size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Created
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(
                            shopperDetail?.data?.shopper?.created_at
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdDateRange size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Updated
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(
                            shopperDetail?.data?.shopper?.updated_at
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-6 w-1/2">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between gap-2">
                        <p className="text-base text-gray-700  font-medium">
                          Referee
                        </p>
                        <div>
                          {shopperDetail?.data?.shopper?.referee ? (
                            JSON.parse(
                              shopperDetail?.data?.shopper?.referee
                            ).map((refereeInfo, index) => (
                              <p
                                key={index}
                                className="text-[16px] mt-2 text-right text-[#1A1C21] font-medium"
                              >
                                {refereeInfo}
                              </p>
                            ))
                          ) : (
                            <p className="text-[14px] mt-2 text-[#1A1C21] font-medium tracking-[0.07px]">
                              No Info
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-base text-[#1A1C21] font-medium tracking-[0.09px]">
                      Documents
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <BsBank2 size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Bank
                            </p>
                          </div>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.bank
                            ? shopperDetail?.data?.shopper?.bank
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaCreditCard size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Account Number
                            </p>
                          </div>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {shopperDetail?.data?.shopper?.account_number
                            ? shopperDetail?.data?.shopper?.account_number
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaAddressCard size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Address Verification
                          </p>
                        </div>
                        {shopperDetail?.data?.shopper?.address_verification ? (
                          <ImageFullScreen
                            src={
                              shopperDetail?.data?.shopper?.address_verification
                            }
                            alt={shopperDetail?.data?.shopper?.name}
                          />
                        ) : (
                          "No Image"
                        )}
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Passport
                          </p>
                        </div>
                        {shopperDetail?.data?.shopper?.passport ? (
                          <ImageFullScreen
                            src={shopperDetail?.data?.shopper?.passport}
                            alt={shopperDetail?.data?.shopper?.name}
                          />
                        ) : (
                          "No image"
                        )}
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoLocationOutline size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              NIN
                            </p>
                          </div>
                        </div>
                        {shopperDetail?.data?.shopper?.nin ? (
                          <ImageFullScreen
                            src={shopperDetail?.data?.shopper?.nin}
                            alt={shopperDetail?.data?.shopper?.name}
                          />
                        ) : (
                          "No Image"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "null"
          )}
        </div>
      )}
    </div>
  );
};

export default ShopperPromotionDetail;
