/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import loginImg from "../../assets/login-bg2.png";
import BgLogo from "../../assets/bg-logo.png";
import PasswordInput from "../../components/PasswordInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginAdminAsync } from "../../redux/thunks/authThunks";
import { ImSpinner2 } from "react-icons/im";
import { setAuthToken } from "../../redux/slices/authSlice";

function Login() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const result = await dispatch(loginAdminAsync(formData));

      const status = result.payload.status;

      if (status === "success") {
        const token = result.payload.authorisation.token;
        const authToken = result.payload.authorisation.token;
        const user = result.payload.user;

        dispatch(setAuthToken({ authToken }));
        
        localStorage.setItem("user", JSON.stringify(user));

        // Store token in localStorage
        localStorage.setItem("token", token);

        navigate("/dashboard");
        toast.success("Login successful!");
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      if (error.message === "Unauthorized") {
        toast.error("Invalid Credentials");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 w-11/12 h-screen">
      <div className="hidden relative sm:block">
        <img className="w-full h-full object-cover" src={loginImg} alt="" />
        <div className=" absolute inset-0 flex items-end justify-center">
          <div className="flex flex-col m-4 p-8">
            <div className="flex justify-start p-4">
              <img src={BgLogo} alt="" className="w-52 " />
            </div>
            <h1 className=" text-left font-bold text-5xl text-white p-4">
              For all your laundry, shopping & investments solutions
            </h1>
          </div>
        </div>
      </div>
      <div className="relative w-full flex flex-col justify-center items-center min-h-screen overflow-hidden">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
          <h1 className=" text-2xl md:text-3xl font-semibold text-start text-gray-700">
            Sign in
          </h1>
          <form onSubmit={handleSubmit} className="mt-6">
            <div className="mb-6">
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email Address"
                className="block w-full px-4 py-2 mt-2 text-blue-700 bg-white border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <PasswordInput
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {/* <button className="text-xs mt-6 text-blue-600 hover:underline">
              Forget Password?
            </button> */}
            <div className="mt-6">
              <button
                type="submit"
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-700 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              >
                {loading ? (
                  <>
                    <div className="flex items-center justify-center gap-1">
                      <p>Logging in</p>
                      <ImSpinner2 className="animate-spin" />
                    </div>
                  </>
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </form>
        </div>

        <div className=" mb-12 flex items-center justify-center">
          <p className=" text-gray-800 text-xs text-center md:text-start flex justify-center p-8">
            Protected by reCAPTCHA and subject to the Capfields Privacy Policy
            and Terms of Service.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
