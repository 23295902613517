import React, { useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import ModalTerms from "../../components/modals/legal/ModalTerms";
import { useDispatch, useSelector } from "react-redux";
import { fetchTermsandPolicy } from "../../redux/slices/ceoSlice";
import { ImSpinner2 } from "react-icons/im";

const TermandCondition = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTermsandPolicy());
  }, [dispatch]);

  const termsandPolicies = useSelector((state) => state.ceo?.termsandPolicies);
  const status = useSelector((state) => state.ceo?.status);

  const filteredConditions = termsandPolicies.conditions;

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Terms & Conditions
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Legal</p>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">
              Terms & Conditions
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-end mb-4">
        <ModalTerms />
      </div>
      {status === "loading" ? (
        // Display spinner while loading
        <div className="flex justify-center items-center py-20">
          <ImSpinner2 className="animate-spin text-blue-600 text-3xl" />
        </div>
      ) : (
        <div>
          <textarea
            name=""
            id=""
            value={
              status === "loading"
                ? "Loading terms and conditions..." // Show loading message in the textarea
                : filteredConditions || "No terms and conditions available"
            }
            cols="20"
            rows="15"
            readOnly
            className="p-4 w-full rounded-lg border-2 border-gray-200"
          />
        </div>
      )}
    </div>
  );
};

export default TermandCondition;
