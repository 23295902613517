import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addShoppingFee } from "../../redux/slices/feeSettingSlice";
import { FaSpinner } from "react-icons/fa";
import { formatNumberWithCommas2 } from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";

const ShoppingFeeSet = () => {
  const initialFormData = {
    shopping_fee: "",
    min_fee: "",
    max_fee: "",
    capfields: "",
    shopper: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const dispatch = useDispatch();
  const status = useSelector((state) => state.feeSetting?.shoppingFeeStatus);

  const shopping_fee = useSelector(
    (state) => state.feeSetting?.feeSettings?.shopping_fee
  );

  useEffect(() => {
    if (shopping_fee) {
      setFormData({
        shopping_fee: shopping_fee.shopping_fee || "",
        min_fee: shopping_fee.min_fee || "",
        max_fee: shopping_fee.max_fee || "",
        capfields: shopping_fee.capfields || "",
        shopper: shopping_fee.shopper || "",
      });
    }
  }, [shopping_fee]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let adjustedValue = value.replace(/,/g, "");

    if (name === "shopping_fee" || name === "capfields" || name === "shopper") {
      // Parse the numeric part of the input value as a float
      let floatValue = parseFloat(value);

      // Ensure the value is not greater than 100
      floatValue = Math.min(Math.max(floatValue, 1), 100);

      // Calculate the adjusted values to ensure the total is 100%
      if (name === "capfields") {
        adjustedValue = floatValue;
        const adjustedShopper = 100 - floatValue;
        setFormData((prevData) => ({
          ...prevData,
          shopper: adjustedShopper.toString(),
        }));
      } else if (name === "shopper") {
        adjustedValue = floatValue;
        const adjustedCapfields = 100 - floatValue;
        setFormData((prevData) => ({
          ...prevData,
          capfields: adjustedCapfields.toString(),
        }));
      } else {
        adjustedValue = floatValue;
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: adjustedValue,
    }));
  };

  const handleSubmit = async () => {
    if (
      !formData.shopping_fee ||
      !formData.min_fee ||
      !formData.max_fee ||
      !formData.capfields ||
      !formData.shopper
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    try {
      await dispatch(addShoppingFee(formData));
      toast.success("Shopping fee successfully set");
    } catch (error) {}
  };

  return (
    <>
      {status === "loading" ? (
        <>
          <div className="flex justify-center p-12 items-center">
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          </div>
        </>
      ) : (
        <div className="flex mt-4 items-center justify-center px-8 py-4">
          <div className=" flex flex-row gap-4">
            <div className="flex flex-col gap-3 ">
              <div className="flex flex-row justify-between items-center gap-4">
                <label
                  htmlFor="shopping_fee"
                  className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                >
                  Shopping Fee (%)
                </label>
                <div className="flex justify-end">
                  <input
                    name="shopping_fee"
                    id="name"
                    type="number"
                    value={formData.shopping_fee}
                    onChange={handleInputChange}
                    placeholder="Type here..."
                    className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between items-center gap-4">
                <label
                  htmlFor="minimum_fee"
                  className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                >
                  Minimun Fee
                </label>
                <div className="flex justify-end">
                  <input
                    name="min_fee"
                    id="name"
                    type="text"
                    value={formatNumberWithCommas2(formData.min_fee)}
                    onChange={handleInputChange}
                    placeholder="Type here..."
                    className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between items-center gap-4">
                <label
                  htmlFor="state"
                  className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                >
                  Maximun Fee
                </label>
                <div className="flex justify-end">
                  <input
                    name="max_fee"
                    id="name"
                    type="text"
                    value={formatNumberWithCommas2(formData.max_fee)}
                    onChange={handleInputChange}
                    placeholder="Type here..."
                    className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 ">
              <div className="flex flex-row justify-between items-center gap-4">
                <label
                  htmlFor="state"
                  className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                >
                  Capfileds (%)
                </label>
                <div className="flex justify-end">
                  <input
                    name="capfields"
                    id="name"
                    type="number"
                    value={formData.capfields}
                    onChange={handleInputChange}
                    placeholder="Type here..."
                    className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between items-center gap-4">
                <label
                  htmlFor="shopper"
                  className="text-[14px] text-[#4D5464] font-bold tracking-[0.07px]"
                >
                  Shopper (%)
                </label>
                <div className="flex justify-end">
                  <input
                    name="shopper"
                    id="name"
                    type="number"
                    value={formData.shopper}
                    onChange={handleInputChange}
                    placeholder="Type here..."
                    className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                  />
                </div>
              </div>
              <div>
                <button
                  className="flex gap-1 w-full  rounded-lg items-center justify-center bg-blue-600 text-white py-2 px-4"
                  disabled={status === "loading"}
                  onClick={handleSubmit}
                >
                  {status === "loading" ? (
                    <>
                      <FaSpinner className="animate-spin" />
                      <p className="text-sm font-semibold whitespace-nowrap">
                        Submitting ...
                      </p>
                    </>
                  ) : (
                    <>
                      <FiPlus className=" font-bold text-base" />
                      <p className="text-sm font-semibold whitespace-nowrap">
                        Submit
                      </p>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShoppingFeeSet;
