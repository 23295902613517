import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import axios from "axios"; // For making the Paystack API call
import {
  formatCreatedAt,
  formatNumberWithCommas,
  formatTime,
} from "../../utils/commonUtils";
import { fetchManualWithdrawls } from "../../redux/slices/manualTransferSlice";
import { fetchUsers } from "../../redux/slices/userSlice";
import ApproveManualWithdrawal from "../../components/modals/bankPayment/ApproveManualWithdrawal";

const RequestedTransfer = () => {
  const [search, setSearch] = useState("");

  const [bankList, setBankList] = useState([]); // State to store bank list
  const [currentPage, setCurrentPage] = useState(1);
  const [accountNames, setAccountNames] = useState({}); // New state to store account names
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchManualWithdrawls());
    dispatch(fetchUsers());
  }, [dispatch]);

  const manualWithdrawls = useSelector(
    (state) => state.manualTransfer?.manualWithdrawls
  );

  const usersList = useSelector((state) => state.user?.users);

  // Function to resolve the account name
  const resolveAccountName = async (account_number, bank_code, user_id) => {
    const PAYSTACK_SECRET_KEY = process.env.REACT_APP_PAYSTACK_SELECT_KEY;

    try {
      const response = await axios.get(
        `https://api.paystack.co/bank/resolve?account_number=${account_number}&bank_code=${bank_code}`,
        {
          headers: {
            Authorization: `Bearer ${PAYSTACK_SECRET_KEY}`,
          },
        }
      );

      if (response.data.status) {
        // Store the resolved account name in the state
        setAccountNames((prevNames) => ({
          ...prevNames,
          [user_id]: {
            account_name: response.data.data.account_name,
            bank_name: response.data.data.bank_name,
          },
        }));
      }
    } catch (error) {
      console.error("Error resolving account name:", error);
    }
  };

  // Fetch the bank list dynamically from Paystack
  const fetchBankList = async () => {
    const PAYSTACK_SECRET_KEY = process.env.REACT_APP_PAYSTACK_SECRET_KEY;

    try {
      const response = await axios.get("https://api.paystack.co/bank", {
        headers: {
          Authorization: `Bearer ${PAYSTACK_SECRET_KEY}`,
        },
      });

      if (response.data.status) {
        setBankList(response.data.data); // Save the bank list in state
      }
    } catch (error) {
      console.error("Error fetching bank list:", error);
    }
  };

  // Call this function inside useEffect to fetch bank list on component mount
  useEffect(() => {
    fetchBankList();
  }, []);

  // Function to retrieve the bank name using the bank code
  const getBankNameFromCode = (bankCode) => {
    const bank = bankList.find((bank) => bank.code === bankCode);
    return bank ? bank.name : "Unknown Bank";
  };

  // Resolve account names when component mounts
  useEffect(() => {
    if (manualWithdrawls && manualWithdrawls.length > 0) {
      manualWithdrawls.forEach((order) => {
        if (!accountNames[order.user_id]) {
          resolveAccountName(
            order.account_number,
            order.bank_code,
            order.user_id
          );
        }
      });
    }
  }, [manualWithdrawls, accountNames]);

  // Filter orders based on search term
  const filteredLaundry = manualWithdrawls?.filter(
    (laundry) =>
      search.toLowerCase() === "" ||
      laundry?.name.toLowerCase().includes(search) ||
      laundry.user_id.toString().toLowerCase().includes(search.toLowerCase())
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentLaundries = manualWithdrawls.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredLaundry.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.manualTransfer?.status);
  const error = useSelector((state) => state.manualTransfer?.error);

  const getUserNameFromId = (userId) => {
    const user = usersList?.find((user) => user.id === parseInt(userId, 10));
    if (!user) {
      return { name: "Unknown", email: "N/A", phone: "N/A" };
    }

    return {
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      phone: user.phone,
    };
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Requested Transfer
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Bank Payment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Requested Transfer
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4"></div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search orders..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left p-12 whitespace-nowrap text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 text-center py-4">No.</th>
                <th className="px-4 text-center py-4">Name</th>
                <th className="px-4 py-4 text-center">Amount</th>
                <th className="px-4 py-4 text-center">Transfer Fee</th>
                <th className="px-4 py-4 text-center">Bank Details</th>
                <th className="px-4 py-4 text-center">Status</th>
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Time</th>
                <th className="px-4 py-4 text-center">Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentLaundries.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentLaundries?.map((order, index) => (
                  <tr key={order.id} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <Link
                        to={`/dashboard/bank-payment/requested-transfer-user-detail/${order.user_id}`}
                      >
                        <div className="flex flex-col gap-2">
                          <p className="  text-base font-semibold">
                            {getUserNameFromId(order.user_id).name}
                          </p>
                          <p className="  font-normal">
                            {getUserNameFromId(order.user_id).phone}
                          </p>
                          <p className="  font-normal">
                            {getUserNameFromId(order.user_id).email}
                          </p>
                        </div>
                      </Link>
                    </td>

                    <td className="px-4 py-4 text-center">
                      <p className="  font-semibold">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(order.amount)}
                      </p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p className="  font-semibold">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(order.fee)}
                      </p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <div className="flex flex-col gap-2">
                        <p className="  font-semibold">
                          {accountNames[order.user_id]?.account_name ||
                            order.account_name}
                        </p>
                        <p className="  font-semibold">
                          {accountNames[order.user_id]?.bank_name ||
                            getBankNameFromCode(order.bank_code)}
                        </p>
                        <p className="  font-semibold">
                          {order.account_number}
                        </p>
                      </div>
                    </td>

                    <td className="px-4 py-4 text-center">
                      <p className="  font-semibold">{order.status}</p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatTime(order.created_at)}
                    </td>
                    <td className="px-4 py-4 text-center">
                      {formatCreatedAt(order.updated_at)}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center gap-2">
                        <div className="">
                          <ApproveManualWithdrawal
                            id={order.id}
                            bank_name={
                              accountNames[order.user_id]?.bank_name ||
                              getBankNameFromCode(order.bank_code)
                            }
                            name={getUserNameFromId(order.user_id).name}
                            user_id={order.user_id}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {filteredLaundry.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestedTransfer;
