import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const addLaundryItem = createAsyncThunk(
  "laundryItem/addLaundryItem",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/create/laundry/items",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLaundryItem = createAsyncThunk(
  "laundryItem/updateLaundryItem",
  async ({ item_id, formData }, { rejectWithValue }) => {
    try {
      const id = item_id;
      console.log(id);
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        `/update/laundry/items/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLaundryItem = createAsyncThunk(
  "laundryItem/deleteLaundryItem",
  async (item_id, { rejectWithValue }) => {
    try {
      const id = item_id;
      const token = localStorage.getItem("token");

      const response = await apiService.delete(`/delete/item/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const laundryItemSlice = createSlice({
  name: "laundryItem",
  initialState: {
    laundryItems: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addLaundryItem.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addLaundryItem.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundryItems.unshift(action.payload);
      })
      .addCase(addLaundryItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      .addCase(updateLaundryItem.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateLaundryItem.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updateLaundryItem = action.payload;
        state.laundryItems = state.laundryItems.map((lt) =>
          lt.id === updateLaundryItem.id ? updateLaundryItem : lt
        );
      })
      .addCase(updateLaundryItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      .addCase(deleteLaundryItem.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteLaundryItem.fulfilled, (state, action) => {
        state.status = "success";
        const dt = action.payload;
        state.laundryItems = state.laundryItems?.filter((li) => li.id !== dt);
      })
      .addCase(deleteLaundryItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default laundryItemSlice.reducer;
