import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchItemReceived = createAsyncThunk(
  "itemReceived/fetchItemReceived",
  async (_, { rejectWithValue }) => {
    try {
        const token = localStorage.getItem("token");
      const response = await apiService.get('/laundry/items-received', {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const itemReceviedSlice = createSlice({
  name: "itemReceived",
  initialState: {
    itemReceiveds: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItemReceived.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchItemReceived.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.itemReceiveds = action.payload.data;
      })
      .addCase(fetchItemReceived.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default itemReceviedSlice.reducer;
