import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchPriceFeedback } from "../../redux/slices/productSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";

const PriceFeedback = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPriceFeedback());
  }, [dispatch]);

  const feedbacks = useSelector((state) => state.product?.feedbacks);
  const status = useSelector((state) => state.product?.status);
  const error = useSelector((state) => state.product?.error);

  const reversedFeedbacks = feedbacks?.slice().reverse();

  const filteredFeedbacks = reversedFeedbacks?.filter(
    (product) =>
      search.trim() === "" ||
      product.name.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentFeedbacks = filteredFeedbacks?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredFeedbacks.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="text-2xl mb-2 font-medium">Price Feedback</h1>
          <div className="flex gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>
            <HiChevronRight className="text-gray-500" />
            <p className="text-[14px] text-[#667085] font-medium">
              Price Feedback
            </p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className="text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border text-gray-900 text-sm rounded-md block w-full pl-10 p-2.5"
            placeholder="Search products..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-4 py-4 text-center">Name</th>
                <th className="px-4 py-4 text-center">Phone</th>
                <th className="px-4 py-4 text-center">Note</th>
                <th className="px-4 py-4 text-center">New Price</th>
                <th className="px-4 py-4 text-center">Product Name</th>
                <th className="px-4 py-4 text-center">Product Description</th>
                <th className="px-4 py-4 text-center">Product Image</th>
                <th className="px-4 py-4 text-center">Product Price</th>
                <th className="px-4 py-4 text-center">Created</th>
                <th className="px-4 py-4 text-center">Updated</th>
                <th className="px-4 py-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentFeedbacks?.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentFeedbacks?.map((product, index) => {
                  // Parse product_Details JSON string
                  let productDetails = {};
                  try {
                    productDetails = JSON.parse(product.product_Details);
                  } catch (error) {
                    console.error("Error parsing product_Details:", error);
                  }

                  return (
                    <tr key={product.id} className="bg-white border-b">
                      <td className="px-4 py-4 text-center">
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td className="px-4 py-4 text-center">
                        <p className=" text-blue-600 text-base font-semibold">
                          {product.name}{" "}
                        </p>
                      </td>
                      <td className="px-4 py-4 text-center">{product.phone}</td>
                      <td className="px-4 py-4 text-center">
                        <textarea
                          name=""
                          value={product.note}
                          readOnly
                          id=""
                          cols="20"
                          rows="3"
                          className=" p-2 border-2 border-gray-200 rounded-lg"
                        ></textarea>
                      </td>
                      <td className="px-4 py-4 text-center">
                        &#8358;{formatNumberWithCommas(product.new_price)}
                      </td>
                      <td className="px-4 py-4 text-center">
                        {productDetails?.name}
                      </td>
                      <td className="px-4 py-4 text-center">
                        <textarea
                          name=""
                          id=""
                          value={productDetails?.description}
                          cols="20"
                          rows="3"
                            className=" p-2 border-2 border-gray-200 rounded-lg"
                        ></textarea>
                      </td>
                      <td className="px-4 py-4 text-center">
                        {productDetails?.image && productDetails?.image[0] ? (
                          <img
                            src={productDetails.image[0]}
                            alt={productDetails.name}
                            className="w-20 h-20 object-cover mt-2"
                          />
                        ) : (
                          "No image"
                        )}
                      </td>
                      <td className="px-4 py-4 text-center">
                        &#8358; {productDetails?.price}
                      </td>
                      <td className="px-4 py-4 text-center whitespace-nowrap">
                        {formatCreatedAt(product.created_at)}
                      </td>
                      <td className="px-4 py-4 text-center whitespace-nowrap">
                        {formatCreatedAt(product.updated_at)}
                      </td>
                      <td className="px-4 py-4 text-center">
                        {/* <Link
                          to={`/dashboard/shopping/trending-product-detail/${product.id}`}
                        > */}
                        <IoEyeOutline className="text-base font-bold text-[#667085]" />
                        {/* </Link> */}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-between px-4 py-4">
          <p>
            Showing {indexOfFirstItem + 1} -{" "}
            {Math.min(indexOfLastItem, filteredFeedbacks.length)} of{" "}
            {filteredFeedbacks.length}
          </p>
          <div className="flex gap-2">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
            >
              Previous
            </button>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceFeedback;
