import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { updateDropoff } from "../../../redux/slices/dropoffSlice";
import { ImSpinner2 } from "react-icons/im";
import { fetchLaundryStoreDetail } from "../../../redux/slices/laundryStoreSlice";

export default function ModalUpdateDropoff({
  dropoffId,
  laundry_id,
  phone,
  location,
}) {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  const status = useSelector((state) => state.dropoff.status);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    setUpdateData({ laundry_id, location,phone });
  }, [laundry_id, location,phone]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUpdateData({
      ...updateData,
      [name]: value,
      laundry_id: laundry_id,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    try {
      const res = await dispatch(updateDropoff({ dropoffId, updateData }));

      toast.success(res.payload.message);
      const id = laundry_id;
      dispatch(fetchLaundryStoreDetail(id));
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="py-2 px-4  font-medium"
      >
        <FaEdit className="text-gray-500 text-base " />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-5 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Update Drop Off
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-6 ">
                    {/* <div className="flex flex-col gap-1">
                      <label
                        htmlFor="laundry_id"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Laundry Id
                      </label>
                      <div className="flex items-center">
                        <input
                          name="laundry_id"
                          id="laundry_id"
                          type="number"
                          value={id}
                          readOnly
                        
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div> */}

                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="location"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Name of Location
                      </label>
                      <div className="flex items-center">
                        <input
                          name="location"
                          id="location"
                          type="text"
                          value={updateData.location}
                          onChange={handleInputChange}
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="phone"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Phone
                      </label>
                      <div className="flex items-center w-full">
                        <input
                          type="number"
                          name="phone"
                          value={updateData.phone}
                          onChange={handleInputChange}
                          placeholder="Enter phone number here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>

                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <ImSpinner2 className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Updating ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Update Drop off
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
