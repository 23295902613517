import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchStore, updateStore } from "../../../redux/slices/storeSlice";
import UploadImage from "../../UploadImage";

export default function ModalUpdateStore({
  id,
  image,
  name,
  landmark,
  location,
  type,
  note,
  country,
  state,
  province,
}) {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const status = useSelector((state) => state.store?.status);
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData({
      image,
      name,
      landmark,
      location,
      type,
      note,
      country,
      state,
      province,
    });
  }, [image, name, note, landmark, location, type, country, state, province]);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUploadImage = (file) => {
    // Set the uploaded file in the form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const handleUpdateMarket = async () => {
    console.log(formData);
    try {
      const res = await dispatch(updateStore({ id, formData }));
      toast.success(res.payload.data.message);
      dispatch(fetchStore());
    } catch (error) {}

    setShowModal(false);
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className=" text-base text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-4 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-4 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 font-medium mb-4">
                    Update Store
                  </h1>
                </div>
                <div className=" flex flex-row gap-4">
                  <div className="flex flex-col px-8 py-4 gap-6">
                    {/* <img
                      src={image}
                      alt={name}
                      className="w-[248px] h-[220px] rounded-lg ring-[8px] ring-[#D9D9D9]"
                    /> */}
                     <UploadImage onUpload={handleUploadImage} />
                    <div>
                      <label
                        htmlFor="name"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Market Name
                      </label>

                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="landmarl"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                       Short note
                      </label>

                      <input
                        type="text"
                        name="landmark"
                        value={formData.landmark}
                        onChange={handleInputChange}
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="landmarl"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Note
                      </label>
                      <textarea
                        name="note"
                        id=""
                        cols="20"
                        rows="3"
                        value={formData.note}
                        onChange={handleInputChange}
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      ></textarea>
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 mt-6">
                    <div>
                      <label
                        htmlFor="location"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Location
                      </label>

                      <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="country"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Country
                      </label>

                      <select
                        name="country"
                        id="market"
                        value={formData.country}
                        onChange={handleInputChange}
                        className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      >
                        {countries?.sort((a, b) => a.name.localeCompare(b.name)).map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="state"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        State
                      </label>

                      <select
                        name="state"
                        id="state"
                        value={formData.state}
                        onChange={handleInputChange}
                        className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      >
                         {states
                          ?.filter(
                            (state) => state.country_id === formData.country
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="city"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        City
                      </label>

                      <select
                        name="province"
                        id="province"
                        value={formData.province}
                        onChange={handleInputChange}
                        className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      >
                        {provinces
                          ?.filter(
                            (province) =>
                              province.country_id === formData.country &&
                              province.state_id === formData.state
                          )
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((province) => (
                            <option key={province.id} value={province.id}>
                              {province.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="type"
                        className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Type
                      </label>

                      <select
                        name="type"
                        id="type"
                        value={formData.type}
                        onChange={handleInputChange}
                        className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      >
                        <option value="" className="text-blue-600" disabled>
                          Choose status
                        </option>
                        <option value="trending">Trending</option>
                        <option value="general">General</option>
                      </select>
                    </div>

                    <div className="flex mt-6 gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleUpdateMarket(id)}
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold">
                              Updating ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold">
                              Update Store
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
