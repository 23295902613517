import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firestore";

export default function ModalUpdateStoreLocator({ storeId, reloadStoreLocator }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [type, setType] = useState("");

  useEffect(() => {
    const fetchStoreData = async () => {
      if (storeId) {
        const storeDocRef = doc(db, "Store Locator", storeId);
        const storeDoc = await getDoc(storeDocRef);
        if (storeDoc.exists()) {
          const storeData = storeDoc.data();
          setName(storeData.name);
          setPhone(storeData.phone);
          setAddress(storeData.address);
          setCountry(storeData.country);
          setState(storeData.state);
          setCity(storeData.city);
          setLatitude(storeData.latitude);
          setLongitude(storeData.longitude);
          setType(storeData.type);
        } else {
          toast.error("Store not found.");
        }
      }
    };

    fetchStoreData();
  }, [storeId]);

  const addressInputRef = useRef(null);

  const initializeAutocomplete = () => {
    const addressInput = addressInputRef.current;
    if (addressInput) {
      const autocomplete = new window.google.maps.places.Autocomplete(addressInput);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          setAddress(place.formatted_address);
          addressInput.value = place.formatted_address;
          setLatitude(place.geometry.location.lat());
          setLongitude(place.geometry.location.lng());
        }
      });
    } else {
      console.error("Element with ID 'address-input' not found.");
    }
  };

  useEffect(() => {
    if (showModal) {
      const loadGoogleMapsScript = () => {
        if (!window.google) {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBtpUT4tLnlprC0988eAfhVaxTE9QvXiDM&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = initializeAutocomplete;
          document.body.appendChild(script);

          return () => {
            document.body.removeChild(script);
          };
        } else {
          initializeAutocomplete();
        }
      };
      loadGoogleMapsScript();
    }
  }, [showModal]);

  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !phone ||
      !address ||
      !country ||
      !state ||
      !city ||
      !type ||
      !longitude ||
      !latitude
    ) {
      toast.error("All fields are required.");
      return;
    }

    setLoading(true);

    try {
      const timestamp = new Date().toISOString().replace("Z", "");

      const updatedData = {
        name,
        phone,
        address,
        country,
        state,
        city,
        type,
        longitude,
        latitude,
        updated_at: timestamp,
      };

      await updateDoc(doc(db, "Store Locator", storeId), updatedData);

      toast.success("Updated successfully");
    } catch (error) {
      console.error("Error updating store:", error);
      toast.error("An error occurred while updating.");
    } finally {
      await reloadStoreLocator();
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className="w-4 h-4 text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-6">
                    <h1 className="text-lg text-gray-800 text-center font-medium mb-4">
                      Update Store Locator
                    </h1>
                    <div className="flex gap-2">
                      <div className="flex flex-row gap-8">
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="name"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Name
                            </label>
                            <div className="flex items-center">
                              <input
                                name="name"
                                id="name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Type transport name here..."
                                className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="phone"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Phone
                            </label>
                            <div className="flex items-center">
                              <input
                                phone="phone"
                                id="phone"
                                type="number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Type phone here..."
                                className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="address"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Address
                            </label>
                            <div className="flex items-center">
                              <input
                                name="address"
                                id="address"
                                type="text"
                                ref={addressInputRef}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder="Type address here..."
                                className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-[1px]">
                            <label
                              htmlFor="location"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Type
                            </label>
                            <select
                              name="type"
                              id="type"
                              value={type}
                              onChange={(e) => setType(e.target.value)}
                              required
                              className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                            >
                              <option
                                value=""
                                className="text-[#333843]"
                                disabled
                              >
                                Choose type
                              </option>
                              <option value="market">Market</option>
                              <option value="store">Store</option>
                              <option value="pharmacy">Pharmacy</option>
                              <option value="fastfood">Fast Food</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="country"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              Country
                            </label>
                            <div className="flex items-center">
                              <select
                                name="country"
                                id="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                className="pl-[12px] p-2 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              >
                                <option value="">Select Country</option>
                                {countries?.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="state"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              State
                            </label>
                            <div className="flex items-center">
                              <select
                                name="state"
                                id="state"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                className="pl-[12px] p-2 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              >
                                <option className="text-[#333843]">
                                  Select State
                                </option>
                                {states
                                  ?.filter((state) => state.country_id === country)
                                  .map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor="city"
                              className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                            >
                              City
                            </label>
                            <div className="flex items-center">
                              <select
                                name="city"
                                id="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="pl-[12px] p-2 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                              >
                                <option className="text-[#333843]">
                                  Select City
                                </option>
                                {provinces
                                  ?.filter(
                                    (province) =>
                                      province.country_id === country &&
                                      province.state_id === state
                                  )
                                  .map((province) => (
                                    <option key={province.id} value={province.id}>
                                      {province.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="flex gap-4 justify-center mt-8">
                            <button
                              onClick={() => setShowModal(false)}
                              className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                            >
                              Cancel
                            </button>
                            <button
                              className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                              disabled={loading}
                              onClick={handleSubmit}
                            >
                              {loading ? (
                                <>
                                  <ImSpinner2 className="animate-spin" />
                                  <p className="text-sm font-semibold whitespace-nowrap">
                                    Updating ...
                                  </p>
                                </>
                              ) : (
                                <>
                                  <FaEdit className="w-4 h-4" />
                                  <p className="text-sm font-semibold whitespace-nowrap">
                                    Update Store Locator
                                  </p>
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
