import React, { useState } from "react";
import { BsImage } from "react-icons/bs";
import { FaSpinner, FaTimes } from "react-icons/fa";

const UploadMultiImage = ({ onUpload }) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  const handleImageUpload = (event) => {
    setLoading(true);
    const files = Array.from(event.target.files);
    setImages(files);
    onUpload(files);
    setLoading(false);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    onUpload(updatedImages);
  };

  return (
    <div className="relative flex flex-col items-center">
      <label
        htmlFor="images"
        className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
      >
        {images.length > 0 ? (
          <div className="flex flex-wrap gap-2 p-2">
            {images.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Uploaded Preview ${index + 1}`}
                  className="h-20 w-20 object-cover rounded-lg"
                />
                <button
                  type="button"
                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"
                  onClick={() => handleRemoveImage(index)}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6 text-center">
            <BsImage size={28} color="#1F7BF4" />
            <p className="mb-2 p-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and
              drop a picture here
            </p>
            {loading && <FaSpinner className="animate-spin" />}
          </div>
        )}
        <input
          id="images"
          type="file"
          multiple
          accept="image/*"
          className="hidden"
          onChange={handleImageUpload}
        />
      </label>
    </div>
  );
};

export default UploadMultiImage;
