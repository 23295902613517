import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchLaundrySubs } from "../../redux/slices/laundrySubSlice";

import { ImSpinner2 } from "react-icons/im";
import ModalAddLaundrySub from "../../components/modals/laundry/ModalAddLaundrySub";
import ModalUpdateLaundrySub from "../../components/modals/laundry/ModalUpdateLaundrySub";
import ModalDeleteLaundrySub from "../../components/modals/laundry/ModalDeleteLaundrySub";
import { formatNumberWithCommas } from "../../utils/commonUtils";

const LaundrySub = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLaundrySubs());
  }, [dispatch]);

  const laundrySubs = useSelector((state) => state.laundrySub?.laundrySubs);

  const reversedLaundrySubs = laundrySubs.slice().reverse();

  // Filter categories based on search term
  const filteredLaundrySubs = reversedLaundrySubs.filter((order) =>
    search.toLowerCase() === ""
      ? order
      : order.name.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentLaundrySubs = filteredLaundrySubs.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredLaundrySubs.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const status = useSelector((state) => state.laundrySub?.status);
  const error = useSelector((state) => state.laundrySub?.error);



  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Laundry Subscriptions
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Laundry</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Subscriptions
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4 mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search order..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
        <div className="flex items-center gap-4">
          <ModalAddLaundrySub />
        </div>
      </div>

      <div className="bg-white rounded-lg mt-4 w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-center text-gray-500">
            <thead className="w-full text-sm whitespace-nowrap text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-4 text-center">No.</th>
                <th className="px-4 py-4 text-center">Name</th>
                <th className="px-4 py-3 text-center">Cost</th>
                <th className="px-4 py-3 text-center">No. of Clothing items allowed</th>

                <th className="px-4 py-3 text-center"> No. of Pickup</th>
                <th className="px-4 py-3 text-center">No. of Deliveries</th>
                <th className="px-4 py-3 text-center">Start Date</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
            {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentLaundrySubs.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentLaundrySubs?.map((order, index) => (
                  <tr
                    key={order.id}
                    className="bg-white border-b whitespace-nowrap"
                  >
                    <td className="px-4 py-4">
                      <div className="flex justify-center items-center">
                        <p className=" text-base text-center font-semibold ">
                          {indexOfFirstItem + index + 1}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-center">
                      <p className="text-[14px] font-medium">{order.name}</p>
                    </td>

                    <td className="px-4 py-2 text-center">
                      <p>
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(order.cost)}
                      </p>
                    </td>
                    <td className="px-4 py-2 text-center">
                      <p>
                        {order.usage}
                      </p>
                    </td>
                    <td className="px-4 py-2 text-center">{order.pickup}</td>
                    <td className="px-4 py-2 text-center">{order.delivery}</td>
                    <td className="px-4 py-2 text-center">
                      {new Date(order.created_at).toDateString()}
                    </td>

                    <td className="px-2 py-4">
                      <div className="flex gap-4 justify-center items-center">
                        <div>
                          <ModalUpdateLaundrySub
                            id={order.id}
                            name={order.name}
                            cost={order.cost}
                            usage={order.usage}
                            pickup={order.pickup}
                            delivery={order.delivery}
                          />
                        </div>

                        <div>
                          <ModalDeleteLaundrySub
                            id={order.id}
                            name={order.name}
                            cost={order.cost}
                            usage={order.usage}
                            pickup={order.pickup}
                            delivery={order.delivery}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {laundrySubs.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaundrySub;
