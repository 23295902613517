import React, { useState } from "react";
import { FaPlus, FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";
import { formatNumberWithCommas } from "../../../utils/commonUtils";
import {
  fetchLaundryOrderDetail,
  updateLaundryRequest,
} from "../../../redux/slices/laundrySlice";
import { toast } from "react-toastify";

export default function ModaLaundryItemRequest({ order_id, laundryItems }) {
  const [showModal, setShowModal] = useState(false);
  const status = useSelector((state) => state.laundry?.status);
  const dispatch = useDispatch();

  // Track the expanded item in the accordion
  const [expandedItem, setExpandedItem] = useState(null);

  const initialFormData = {
    order_id,
    order_details: [],
    price: 0,
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleItemCheckboxChange = (itemId, itemName) => {
    const itemExists = formData.order_details.find(
      (detail) => detail.item === itemName
    );

    let updatedOrderDetails;

    if (itemExists) {
      // If the item exists, remove it from the order_details
      updatedOrderDetails = formData.order_details.filter(
        (detail) => detail.item !== itemName
      );
    } else {
      // Add a new entry for the item
      updatedOrderDetails = [
        ...formData.order_details,
        { item: itemName, quantity: 1, services: [] }, // Initialize with empty services array
      ];
    }

    const updatedFormData = { ...formData, order_details: updatedOrderDetails };
    setFormData(updatedFormData);

    // Expand/Collapse the clicked item
    setExpandedItem(expandedItem === itemId ? null : itemId);

    // Recalculate total price
    const totalPrice = calculateTotalPrice(updatedFormData);
    setFormData((prev) => ({ ...prev, price: totalPrice }));
  };

  const handleServiceCheckboxChange = (itemName, serviceName) => {
    const updatedOrderDetails = formData.order_details.map((detail) => {
      if (detail.item === itemName) {
        const services = detail.services.includes(serviceName)
          ? detail.services.filter((service) => service !== serviceName)
          : [...detail.services, serviceName];
        return { ...detail, services };
      }
      return detail;
    });

    const updatedFormData = { ...formData, order_details: updatedOrderDetails };
    setFormData(updatedFormData);

    // Recalculate total price
    const totalPrice = calculateTotalPrice(updatedFormData);
    setFormData((prev) => ({ ...prev, price: totalPrice }));
  };

  const handleQuantityChange = (itemName, quantity) => {
    const updatedOrderDetails = formData.order_details.map((detail) => {
      if (detail.item === itemName) {
        return { ...detail, quantity: parseInt(quantity, 10) || 1 };
      }
      return detail;
    });

    const updatedFormData = { ...formData, order_details: updatedOrderDetails };
    setFormData(updatedFormData);

    // Recalculate total price
    const totalPrice = calculateTotalPrice(updatedFormData);
    setFormData((prev) => ({ ...prev, price: totalPrice }));
  };

  const calculateTotalPrice = (data) => {
    let totalPrice = 0;

    data.order_details.forEach((detail) => {
      const item = laundryItems.find((i) => i.name === detail.item);

      if (item) {
        let itemTotalPrice = 0;

        detail.services.forEach((service) => {
          const servicePrice = parseFloat(item[service]);
          if (servicePrice && servicePrice !== 0) {
            itemTotalPrice += servicePrice * detail.quantity;
          }
        });

        itemTotalPrice += (parseFloat(item.price) || 0) * detail.quantity;
        totalPrice += itemTotalPrice;
      }
    });

    return totalPrice;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(updateLaundryRequest(formData));
      dispatch(fetchLaundryOrderDetail(order_id));
      toast.success(res.payload.message);
      setFormData({ ...initialFormData });
      setShowModal(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-2 bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-lg font-medium"
      >
        <PiPlusBold className="text-lg font-extrabold" />
        Add Laundry Item Request
      </button>

      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl max-h-[90vh] overflow-y-auto">
              <div className="border-0 rounded-lg p-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-5 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>

                <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                  Laundry Item Request
                </h1>

                {/* Form */}
                <div className="flex flex-col px-8 py-4 gap-4">
                  {/* Laundry Items Accordion */}
                  <div className="flex flex-col gap-1">
                    <h3 className="text-[14px] text-[#4D5464] font-medium">
                      Select Laundry Items:
                    </h3>
                    {/* Display Total Price */}
                    <div className="flex flex-col gap-1">
                      <label className="text-sm">Total Price:</label>
                      <p className="pl-3 text-[#333843] p-2 font-bold rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]">
                        <span className="mr-1">&#8358;</span>{" "}
                        {formatNumberWithCommas(
                          formData.price ? formData.price : 0
                        )}
                      </p>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                      {/* Sort laundryItems alphabetically by name */}
                      {Array.isArray(laundryItems) &&
                        laundryItems
                          .slice() // Create a shallow copy to avoid mutating the original array
                          .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                          .map((item) => (
                            <div
                              key={item.id}
                              className="border rounded-lg p-3"
                            >
                              <label className="flex items-center gap-2 cursor-pointer">
                                <input
                                  type="checkbox"
                                  value={item.id}
                                  onChange={() =>
                                    handleItemCheckboxChange(item.id, item.name)
                                  }
                                  checked={formData.order_details.some(
                                    (detail) => detail.item === item.name
                                  )}
                                />
                                {item.name}
                              </label>

                              {/* Accordion content for quantity and services */}
                              {expandedItem === item.id && (
                                <div className="mt-2 transition-all duration-300">
                                  <div className="flex gap-2 items-center">
                                    {/* Quantity Input */}
                                    <label className="text-sm">Quantity:</label>
                                    <input
                                      type="number"
                                      min="1"
                                      value={formData.order_details.find(
                                        (detail) => detail.item === item.name
                                      )?.quantity || 1}
                                      onChange={(e) =>
                                        handleQuantityChange(
                                          item.name,
                                          e.target.value
                                        )
                                      }
                                      className="p-1 w-12 border rounded text-sm text-center"
                                    />
                                  </div>

                                  {/* Services Selection */}
                                  <div className="flex gap-2 items-center mt-2">
                                    {["wash", "dryclean", "iron", "starch"].map(
                                      (service) => (
                                        <label
                                          key={service}
                                          className="flex items-center gap-2"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={formData.order_details
                                              .find(
                                                (detail) =>
                                                  detail.item === item.name
                                              )
                                              ?.services.includes(service)}
                                            onChange={() =>
                                              handleServiceCheckboxChange(
                                                item.name,
                                                service
                                              )
                                            }
                                            disabled={item[service] === "0"} // Disable if the service value is "0"
                                          />
                                          {service}
                                        </label>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex gap-4 justify-center">
                    <button
                      onClick={() => setShowModal(false)}
                      className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                      disabled={status === "loading"}
                      onClick={handleSubmit}
                    >
                      {status === "loading" ? (
                        <>
                          <FaSpinner className="animate-spin" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Adding ...
                          </p>
                        </>
                      ) : (
                        <>
                          <FaPlus className="w-4 h-4" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Add Laundry Item
                          </p>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
