import React, { useState } from "react";
import { BsImage } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

const CloudImageUpload = ({ onUpload }) => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const handleImageUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    setImageFile(file);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ea2mci0p"); // replace with your actual upload preset

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dhayhxgpw/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      const imageUrl = data.secure_url;
      onUpload(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <label
        htmlFor="image"
        className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
      >
        {imageFile ? (
          <img
            src={URL.createObjectURL(imageFile)}
            alt="Uploaded Preview"
            className="h-40 w-40 object-cover rounded-lg"
          />
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6 text-center">
            <BsImage size={28} color="#1F7BF4" />
            <p className="mb-2 p-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and
              drop a picture here
            </p>
            {loading && <FaSpinner className="animate-spin" />}
          </div>
        )}

        <input
          id="image"
          onChange={handleImageUpload}
          type="file"
          accept="image/*"
          className="hidden"
        />
      </label>
    </div>
  );
};

export default CloudImageUpload;
