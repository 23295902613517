import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchFeeSetting = createAsyncThunk(
  "feeSetting/fetchFeeSetting",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/fees", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addShoppingFee = createAsyncThunk(
  "feeSetting/addShoppingFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/set/shopping-fee",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addInCityFee = createAsyncThunk(
  "feeSetting/addInCityFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/delivery/in-city",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addInStateFee = createAsyncThunk(
  "feeSetting/addInStateFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/delivery/in-state",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addInterStateFee = createAsyncThunk(
  "feeSetting/addInterStateFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/delivery/inter-state",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addInterCountryFee = createAsyncThunk(
  "feeSetting/addInterCountryFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/delivery/inter-country",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addWalletRoiFee = createAsyncThunk(
  "feeSetting/addWalletRoiFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/wallet-roi", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addInvestmentFee = createAsyncThunk(
  "feeSetting/addInvestmentFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/investment-fee", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addWalletFee = createAsyncThunk(
  "feeSetting/addWalletFee",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/wallet-fee", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const feeSettingSlice = createSlice({
  name: "feeSetting",
  initialState: {
    feeSettings: [],
    shoppingFeeStatus: "idle",
    inCityFeeStatus: "idle",
    inStateFeeStatus: "idle",
    interStateFeeStatus: "idle",
    interCountryFeeStatus: "idle",
    walletRoiFeeStatus: "idle",
    investmentFeeStatus: "idle",
    walletFeeStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeeSetting.pending, (state) => {
        state.shoppingFeeStatus = "loading";
        state.error = null;
      })
      .addCase(fetchFeeSetting.fulfilled, (state, action) => {
        state.shoppingFeeStatus = "succeeded";
        state.feeSettings = action.payload.data;
      })
      .addCase(fetchFeeSetting.rejected, (state, action) => {
        state.shoppingFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addShoppingFee.pending, (state) => {
        state.shoppingFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addShoppingFee.fulfilled, (state, action) => {
        state.shoppingFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addShoppingFee.rejected, (state, action) => {
        state.shoppingFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addInCityFee.pending, (state) => {
        state.inCityFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addInCityFee.fulfilled, (state, action) => {
        state.inCityFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addInCityFee.rejected, (state, action) => {
        state.inCityFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addInStateFee.pending, (state) => {
        state.inStateFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addInStateFee.fulfilled, (state, action) => {
        state.inStateFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addInStateFee.rejected, (state, action) => {
        state.inStateFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addInterStateFee.pending, (state) => {
        state.interStateFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addInterStateFee.fulfilled, (state, action) => {
        state.interStateFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addInterStateFee.rejected, (state, action) => {
        state.interStateFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addInterCountryFee.pending, (state) => {
        state.interCountryFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addInterCountryFee.fulfilled, (state, action) => {
        state.interCountryFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addInterCountryFee.rejected, (state, action) => {
        state.interCountryFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addWalletRoiFee.pending, (state) => {
        state.walletRoiFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addWalletRoiFee.fulfilled, (state, action) => {
        state.walletRoiFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addWalletRoiFee.rejected, (state, action) => {
        state.walletRoiFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addInvestmentFee.pending, (state) => {
        state.investmentFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addInvestmentFee.fulfilled, (state, action) => {
        state.investmentFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addInvestmentFee.rejected, (state, action) => {
        state.investmentFeeStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(addWalletFee.pending, (state) => {
        state.walletFeeStatus = "loading";
        state.error = null;
      })
      .addCase(addWalletFee.fulfilled, (state, action) => {
        state.walletFeeStatus = "succeeded";
        state.feeSettings = action.payload;
      })
      .addCase(addWalletFee.rejected, (state, action) => {
        state.walletFeeStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default feeSettingSlice.reducer;
