import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./redux/store";

import AppRoutes from "./routes";

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Router>
        <AppRoutes />
      </Router>
    </Provider>
  );
};

export default App;
