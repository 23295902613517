import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import NoteUpload from "../components/NoteUpload";
import BalanceDetails from "../components/BalanceDetails";

import { getFormattedDate } from "../utils/dateUtils";
import { fetchCountry } from "../redux/slices/countrySlice";
import { fetchState } from "../redux/slices/stateSlice";
import { fetchProvince } from "../redux/slices/provinceSlice";
import { useDispatch } from "react-redux";
import { fetchCeoNote } from "../redux/slices/ceoSlice";
import LaundryMonthlyReport from "../components/dashboard/LaundryMonthlyReport";
import ShoppingMonthlyReport from "../components/dashboard/ShoppingMonthlyReport";
import InvestmentMonthlyReport from "../components/dashboard/InvestmentMonthlyReport";
import { fetchFeeSetting } from "../redux/slices/feeSettingSlice";
import { fetchCategories } from "../redux/slices/categorySlice";
import PaymentOptions from "../components/PaymentOption";

const Dashboard = () => {
  const today = getFormattedDate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCeoNote());
    dispatch(fetchCountry());
    dispatch(fetchState());
    dispatch(fetchProvince());
    dispatch(fetchFeeSetting());
    dispatch(fetchCategories());
  }, [dispatch]);

  const ceoNotes = useSelector((state) => state.ceo?.ceoNotes);

  const categories = useSelector((state) => state.category?.categories);
  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);

  localStorage.setItem("country", JSON.stringify(countries));
  localStorage.setItem("categories", JSON.stringify(categories));

  localStorage.setItem("state", JSON.stringify(states));

  localStorage.setItem("province", JSON.stringify(provinces));

  const storedUser = JSON.parse(localStorage.getItem("user"));

  const fullName = storedUser.name;
  const firstName = fullName.split(" ")[0];

  const userFunctions = storedUser?.functions || [];
  const isSuperAdmin = storedUser.role === "Super Admin";

  const hasDashboardNote =
    isSuperAdmin || userFunctions.includes("dashboard-note");
  const hasLaundryReport =
    isSuperAdmin || userFunctions.includes("laundry-report");
  const hasShoppingReport =
    isSuperAdmin || userFunctions.includes("shopping-report");
  const hasInvestmentReport =
    isSuperAdmin || userFunctions.includes("investment-report");

  return (
    <div className="p-4">
      <div className="">
        <div className="flex flex-col gap-4 font-semibold mb-4">
          <div className=" flex flex-row">
            <h1 className=" text-5xl">Welcome,</h1>
            <p className=" text-3xl mt-4 ml-2">{firstName} </p>
          </div>
        </div>
        <div className="flex justify-between">

          <p className=" ml-2 text-xl italic font-semibold">{storedUser.role}</p>
        <p className=" text-lg">{today}.</p>
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg mt-6">
        <div className="flex flex-col md:flex-row justify-start items-center">
          <div className="flex justify-start mb-4">
            {ceoNotes.note_image ? (
              <img
                src={ceoNotes?.note_image}
                alt="User"
                className="w-80 h-72 rounded-lg ring-gray-300 ring-2"
              />
            ) : (
              <div className="w-80 h-80 rounded-lg ring-gray-300 ring-2 flex justify-center items-center bg-gray-100">
                <p>Loading...</p>
              </div>
            )}
          </div>
          <div className="md:w-6/12 mt-4">
            {ceoNotes.ceo_note ? (
              <p className="ml-4">{ceoNotes.ceo_note}</p>
            ) : (
              <p className="ml-4 text-gray-400">Loading...</p>
            )}
            <p className="mt-4 ml-4 italic font-semibold text-base">- CEO</p>
          </div>
        </div>

        <div className=" flex gap-4 items-center">
          <div>{hasDashboardNote && <NoteUpload />}</div>

          <div>{isSuperAdmin && <PaymentOptions />}</div>
        </div>
      </div>
      {isSuperAdmin && <BalanceDetails />}

      {/* Laundry Business Report */}
      {hasLaundryReport && <LaundryMonthlyReport />}

      {/* Shopping Business Report */}
      {hasShoppingReport && <ShoppingMonthlyReport />}

      {/* Investment Business Report  */}
      {hasInvestmentReport && <InvestmentMonthlyReport />}
    </div>
  );
};

export default Dashboard;
