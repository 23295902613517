import React, { useEffect, useState } from "react";

import { HiChevronRight } from "react-icons/hi";
import { IoMdCube, IoMdPerson } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";
import {
  fetchContributionDetail,
  selectContributionDetail,
} from "../../redux/slices/isusuSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { fetchUsers } from "../../redux/slices/userSlice";
import { ImSpinner2 } from "react-icons/im";
import { CiCalendarDate } from "react-icons/ci";
import ModalIsusuAdminPayment from "../../components/modals/investment/ModalIsusuAdminPayment";
import { IoWalletOutline } from "react-icons/io5";

const IsusuDetails = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const contributionDetail = useSelector(selectContributionDetail);
  const usersList = useSelector((state) => state.user?.users);
  const status = useSelector((state) => state.isusu?.status);
  const error = useSelector((state) => state.isusu?.error);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    dispatch(fetchContributionDetail(id));
    dispatch(fetchUsers());
  }, [dispatch, id]);

  useEffect(() => {
    if (contributionDetail) {
      const startDate = new Date(contributionDetail.data.group.created_at);
      const endDate = new Date(contributionDetail.data.group.end_date);
      setMonths(getMonthsBetweenDates(startDate, endDate));
    }
  }, [contributionDetail]);

  const getMonthsBetweenDates = (startDate, endDate) => {
    let date = new Date(startDate);
    const months = [];
    while (date <= endDate) {
      months.push(
        new Date(date).toLocaleString("default", {
          month: "long",
          year: "numeric",
        })
      );
      date.setMonth(date.getMonth() + 1);
    }
    return months;
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setShowModal(true);
  };

  const getUserDataFromUserId = (userId) => {
    const user = usersList?.find((user) => +user.id === +userId);

    return user
      ? {
          firstname: user.firstname || "No Firstname",
          lastname: user.lastname || "No Lastname",
          email: user.email || "No Email",
          address: user.address || "No Address",
          phone: user.phone || "No Phone",
        }
      : {
          firstname: "Unknown",
          lastname: "Unknown",
          email: "No Email",
          address: "No Address",
          phone: "No Phone",
        };
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Isusu
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/investment/isusu">
              <p className="text-[14px] text-[#1F7BF4] font-medium">Isusu</p>
            </Link>
            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-[#667085] font-medium">
              Isusu Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {contributionDetail ? (
            <div className="flex mt-8 gap-6">
              <div className="flex flex-col gap-6 w-4/12">
                <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                      Wallet Balance
                    </h1>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoWalletOutline className=" text-2xl font-extrabold text-green-600" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-3xl text-green-600 font-extrabold">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(
                        contributionDetail?.data?.wallet_balance
                      )}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                      Group Information
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] whitespace-nowrap font-medium tracking-[0.07px]">
                            Owner
                          </p>
                        </div>

                        <p className=" whitespace-nowrap">
                          {contributionDetail.data.group.owner_name}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>

                        <p className=" whitespace-nowrap">
                          {contributionDetail.data.group.name}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Description
                          </p>
                        </div>

                        <p className=" text-right w-96 break-words">
                          {contributionDetail.data.group.description}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Goal
                          </p>
                        </div>

                        <p className=" whitespace-nowrap">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            contributionDetail.data.group.goal
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Duration
                          </p>
                        </div>

                        <p className=" whitespace-nowrap">
                          {contributionDetail.data.group.duration}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCube size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Monthly
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            contributionDetail.data.group.monthly
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCube size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Members
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {contributionDetail.data.group.members}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <CiCalendarDate size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Start Date
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(
                            contributionDetail.data.group.created_at
                          )}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <CiCalendarDate size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            End Date
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {contributionDetail.data.group.end_date}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 w-8/12">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                      Members
                    </h1>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-center text-gray-500">
                        <thead className="text-sm text-gray-700 bg-gray-50">
                          <tr>
                            <th className="px-4 py-3">Customer</th>

                            <th className="px-4 py-3">Group</th>
                            <th className="px-4 py-3 whitespace-nowrap">
                              Month
                            </th>

                            <th className="px-8 py-3 whitespace-nowrap">
                              Created Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-center">
                          {contributionDetail.data.members.map((member) => (
                            <tr key={member.id} className="bg-white border-b">
                              <td className="px-4 py-3 text-center">
                                <div className="flex flex-col gap-1">
                                  <p className="px-4 py-1 text-[14px]  font-semibold whitespace-nowrap ">
                                    {member.username}
                                  </p>
                                </div>
                              </td>

                              <td className="px-4 py-2 text-center">
                                <p className="text-sm font-medium text-gray-800 whitespace-nowrap">
                                  {member.group_id}
                                </p>
                              </td>
                              <td className="px-4 py-2 text-center">
                                <p className="text-[14px] text-[#667085] font-medium">
                                  {member.month
                                    ? formatCreatedAt(member.month)
                                    : "No month"}
                                </p>
                              </td>
                              <td className="px-4 py-2 text-center">
                                <p className="text-[14px] text-[#667085] font-medium">
                                  {formatCreatedAt(member.created_at)}
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-row justify-between items-center w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-lg text-[#1A1C21] font-semibold tracking-[0.09px]">
                      Payouts
                    </h1>

                    <div className="flex flex-col">
                      <label
                        htmlFor="monthsDropdown"
                        className="text-[14px] text-left mb-2 text-gray-500 font-medium"
                      >
                        Select Month:
                      </label>
                      <select
                        id="monthsDropdown"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        className="p-2 border rounded"
                      >
                        <option value="" disabled>
                          Select a month
                        </option>
                        {months.map((month, index) => (
                          <option key={index} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                      Transcations
                    </h1>

                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-center text-gray-500">
                        <thead className="text-sm text-gray-700 bg-gray-50">
                          <tr>
                            <th className="px-4 py-3">Customer</th>

                            <th className="px-4 py-3 whitespace-nowrap">
                              Transaction Type
                            </th>

                            <th className="px-4 py-3">Amount</th>
                            <th className="px-8 py-3 whitespace-nowrap">
                              Created Date
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-center">
                          {contributionDetail.data.transactions.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center text-gray-500 py-4"
                              >
                                No Data
                              </td>
                            </tr>
                          ) : (
                            contributionDetail.data.transactions.map(
                              (transaction) => (
                                <tr
                                  key={transaction.id}
                                  className="bg-white border-b"
                                >
                                  <td className="px-4 py-3 text-center">
                                    <div className="flex flex-col gap-1">
                                      <p className="px-4 py-1 text-[14px]  font-semibold whitespace-nowrap ">
                                        {transaction.username}
                                      </p>

                                      <p className="px-4 py-1 text-sm font-medium">
                                        {
                                          getUserDataFromUserId(
                                            transaction.user_id
                                          ).email
                                        }
                                      </p>
                                    </div>
                                  </td>

                                  <td className="px-4 py-2 text-center">
                                    <p className="text-sm font-medium text-gray-800 whitespace-nowrap">
                                      {transaction.type
                                        ? transaction.type
                                        : "No Data"}
                                    </p>
                                  </td>
                                  <td className="px-4 py-2 text-center">
                                    <p className="text-[14px] text-[#667085] font-medium">
                                      <span className="mr-1">&#8358;</span>
                                      {transaction.amount}
                                    </p>
                                  </td>
                                  <td className="px-4 py-2 text-center">
                                    <p className="text-[14px] text-[#667085] font-medium">
                                      {formatCreatedAt(transaction.created_at)}
                                    </p>
                                  </td>
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                      Defaulters
                    </h1>

                    <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
                      <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left text-gray-500">
                          <thead className="w-full whitespace-nowrap text-sm text-gray-700 bg-gray-50">
                            <tr>
                              <th className="px-4 py-3 text-center">No.</th>
                              <th className="px-4 py-3 text-left">
                                Customer Name
                              </th>
                              <th className="px-4 py-2 text-left w-48">
                                Address
                              </th>

                              <th className="px-4 py-3 text-center">
                                Amount Left
                              </th>
                              <th className="px-4 py-3 text-center">
                                Month Ended
                              </th>
                              <th className="px-4 py-3 text-center">
                                Created Date
                              </th>
                              <th className="px-4 py-3 text-center">
                                Updated Date
                              </th>

                              <th className="px-4 py-3 text-center">
                                Pay for User
                              </th>
                            </tr>
                          </thead>
                          <tbody className="w-fit">
                            {status === "failed" ? (
                              <tr>
                                <td colSpan="12" className="text-center py-4">
                                  <div className="flex justify-center items-center">
                                    Error. {error}.{" "}
                                    <span className="text-base font-semibold ">
                                      {" "}
                                      Try again later
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ) : status === "loading" ? (
                              <tr>
                                <td colSpan="12" className="text-center py-4">
                                  <div className="flex justify-center items-center">
                                    <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                                  </div>
                                </td>
                              </tr>
                            ) : contributionDetail.data.defaulters?.length ===
                              0 ? (
                              <tr>
                                <td
                                  colSpan="12"
                                  className="text-center text-base text-gray-500 py-4"
                                >
                                  No Data
                                </td>
                              </tr>
                            ) : (
                              contributionDetail.data.defaulters?.map(
                                (order, index) => (
                                  <tr key={index} className="bg-white border-b">
                                    <td className="px-4 py-4">
                                      <p className=" text-base text-center font-semibold">
                                        {index + 1}
                                      </p>
                                    </td>
                                    <td className="px-4 py-4 font-medium whitespace-nowrap text-left">
                                      <div className=" flex flex-col gap-1  text-gray-600">
                                        <p className=" text-base font-semibold">
                                          {order.username}
                                        </p>

                                        <p className=" text-gray-500 text-[14px]">
                                          {
                                            getUserDataFromUserId(order.user_id)
                                              .phone
                                          }
                                        </p>
                                      </div>
                                    </td>

                                    <td className="px-4 py-2 text-left">
                                      <div className="">
                                        <p className="text-[14px] font-medium whitespace-normal w-48 break-words ">
                                          {
                                            getUserDataFromUserId(order.user_id)
                                              .address
                                          }
                                        </p>
                                      </div>
                                    </td>

                                    <td className="px-4 py-4">
                                      <div className=" flex justify-center items-center">
                                        <span className="mr-1">&#8358;</span>
                                        {order.amount_left
                                          ? formatNumberWithCommas(
                                              order.amount_left
                                            )
                                          : 0}
                                      </div>
                                    </td>
                                    <td className="px-4 py-2 text-center whitespace-nowrap">
                                      {formatCreatedAt(order.end_month)}
                                    </td>
                                    <td className="px-4 py-2 text-center whitespace-nowrap">
                                      {formatCreatedAt(order.created_at)}
                                    </td>
                                    <td className="px-4 py-2 text-center whitespace-nowrap">
                                      {formatCreatedAt(order.updated_at)}
                                    </td>
                                    <td className="px-4 py-4">
                                      <div className="flex gap-[8px] items-center justify-center">
                                        {/* <ModalIsusuAprovalRequest /> */}
                                        <ModalIsusuAdminPayment
                                          username={order.username}
                                          amount={
                                            order.amount ? order.amount : 0
                                          }
                                          group_id={order.group_id}
                                          user_id={order.user_id}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <div className=" flex flex-row  mb-4 justify-between items-center">
                      <div className=" flex flex-col p-2 gap-2">
                        <h2 className="text-xl text-left text-gray-600 font-semibold">
                          Selected Month
                        </h2>
                        <p className=" font-light">
                          You selected:{" "}
                          <span className=" text-gray-800 font-semibold">
                            {selectedMonth}
                          </span>
                        </p>
                      </div>
                      <div className="flex justify-center items-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="mt-4 p-2 bg-blue-500 text-white rounded"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                          <tr>
                            <th className="px-4 py-3">Month</th>
                            <th className="px-4 py-3">Customer</th>

                            <th className="px-4 py-3">
                              Scheduled Payment Date
                            </th>
                            <th className="px-4 py-3">
                              Receiver Account Detail
                            </th>
                            <th className="px-4 py-3 ">Amount</th>
                            <th className="px-4 py-3 ">Payment Method</th>
                            <th className="px-4 py-3 ">Status</th>

                            <th className="px-8 py-3">Created Date</th>
                            <th className="px-8 py-3">Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm text-left">
                          {contributionDetail.data.payouts.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center text-gray-500 py-4"
                              >
                                No Data
                              </td>
                            </tr>
                          ) : (
                            contributionDetail.data.payouts.map((payout) => (
                              <tr key={payout.id} className="bg-white border-b">
                                <td className="px-4 py-3">
                                  <div className="flex flex-col gap-1">
                                    <p className=" text-[14px]  font-semibold whitespace-nowrap ">
                                      {payout.username}
                                    </p>

                                    <p className=" text-sm font-medium">
                                      {
                                        getUserDataFromUserId(payout.user_id)
                                          .email
                                      }
                                    </p>
                                  </div>
                                </td>

                                <td className="px-4 py-2 text-center">
                                  <p className="text-sm font-medium text-gray-800 whitespace-nowrap">
                                    {payout.account_name}
                                  </p>
                                </td>
                                <td className="px-4 py-2 text-center">
                                  <p className="text-sm font-medium text-gray-800 whitespace-nowrap">
                                    {payout.account_number}
                                  </p>
                                </td>
                                <td className="px-4 py-2 text-center">
                                  <p className="text-sm font-medium text-gray-800 whitespace-nowrap">
                                    {payout.bank_name}
                                  </p>
                                </td>
                                <td className="px-4 py-2 text-center">
                                  <p className="text-[14px] text-[#667085] font-medium">
                                    {payout.amount}
                                  </p>
                                </td>
                                <td className="px-4 py-2 text-center">
                                  <p className="text-[14px] text-[#667085] font-medium">
                                    {formatCreatedAt(payout.created_at)}
                                  </p>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
            // <CgSpinner className="animate-spin h-8 w-8 text-blue-700" />
          )}
        </div>
      )}
    </div>
  );
};

export default IsusuDetails;
