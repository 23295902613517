import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchShoppers = createAsyncThunk(
  "shopper/fetchShoppers",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get("/admin/shoppers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateShopper = createAsyncThunk(
  "shopper/updateShopper",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/shopper/update",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyShopper = createAsyncThunk(
  "shopper/verifyShopper",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/shopper/verify",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const suspendShopper = createAsyncThunk(
  "shopper/suspendShopper",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/shopper/suspend",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchShopperDetail = createAsyncThunk(
  "shopper/fetchShopperDetail ",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get(`/admin/shopper/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteShopper = createAsyncThunk(
  "shopper/deleteShopper",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(`/admin/delete-shopper/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const shopperSlice = createSlice({
  name: "shopper",
  initialState: {
    shoppers: [],
    status: "idle",
    error: null,
    shopperDetail: null,
    shopperDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShoppers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchShoppers.fulfilled, (state, action) => {
        state.status = "success";
        state.shoppers = action.payload.data;
      })
      .addCase(fetchShoppers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateShopper.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateShopper.fulfilled, (state, action) => {
        state.status = "success";
        state.shoppers = state.shoppers?.map((sh) =>
          sh.id === action.payload.id ? action.payload : sh
        );
      })
      .addCase(updateShopper.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })
      .addCase(verifyShopper.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyShopper.fulfilled, (state, action) => {
        state.status = "success";
        state.shoppers = state.shoppers?.map((sh) =>
          sh.id === action.payload.id ? action.payload : sh
        );
      })
      .addCase(verifyShopper.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })
      .addCase(suspendShopper.pending, (state) => {
        state.status = "loading";
      })
      .addCase(suspendShopper.fulfilled, (state, action) => {
        state.status = "success";
        state.shoppers = state.shoppers?.map((sh) =>
          sh.id === action.payload.id ? action.payload : sh
        );
      })
      .addCase(suspendShopper.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.error.message;
      })
      .addCase(fetchShopperDetail.pending, (state) => {
        state.shopperDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchShopperDetail.fulfilled, (state, action) => {
        state.shopperDetailStatus = "succeeded";
        state.shopperDetail = action.payload;
      })
      .addCase(fetchShopperDetail.rejected, (state, action) => {
        state.shopperDetailStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteShopper.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteShopper.fulfilled, (state, action) => {
        state.status = "success";
        state.shoppers = state.shoppers?.filter(shopper => shopper.id !== action.payload);
      })
      .addCase(deleteShopper.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const selectShopperDetail = (state) => state.shopper?.shopperDetail;

export default shopperSlice.reducer;
