import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchLaundry = createAsyncThunk(
  "laundry/fetchLaundry",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get("/laundry/all-orders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLaundry = createAsyncThunk(
  "laundry/addLaundry",
  async (formData, { getState }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post("/laundry", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      throw new Error("Failed to add laundry");
    }
  }
);

export const deleteLaundry = createAsyncThunk(
  "laundry/deleteLaundry",
  async (laundryId, { getState }) => {
    try {
      const token = localStorage.getItem("token");

      await apiService.delete(`/laundry/${laundryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return laundryId;
    } catch (error) {
      throw new Error("Failed to delete laundry");
    }
  }
);

export const updateLaundry = createAsyncThunk(
  "laundry/updateLaundry",
  async ({ id, updatedLaundryData }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.patch(
        `/laundry/${id}`,
        updatedLaundryData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLaundryOrderDetail = createAsyncThunk(
  "laundry/fetchLaundryOrderDetail ",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get(`/laundry/order/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateLaundryRequest = createAsyncThunk(
  "laundry/updateLaundryRequest ",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post("/laundry-request", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const laundrySlice = createSlice({
  name: "laundry",
  initialState: {
    laundries: [],
    status: "idle",
    error: null,
    laundryOrderDetail: null,
    laundryOrderDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLaundry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLaundry.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundries = action.payload.data;
      })
      .addCase(fetchLaundry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch laundry";
      })
      .addCase(addLaundry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addLaundry.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundries?.push(action.payload);
      })
      .addCase(addLaundry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to add laundry";
      })
      .addCase(deleteLaundry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteLaundry.fulfilled, (state, action) => {
        const deletedLaundryId = action.payload;

        state.status = "succeeded";
        state.laundries =
          state.laundries?.filter(
            (laundry) => laundry._id !== deletedLaundryId
          ) ?? [];
      })
      .addCase(deleteLaundry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete laundry";
      })
      .addCase(updateLaundry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateLaundry.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedLaundry = action.payload;

        state.laundries = state.laundries.map((laundry) =>
          laundry._id === updatedLaundry._id ? updatedLaundry : laundry
        );
      })

      .addCase(updateLaundry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to update laundry";
      })
      .addCase(fetchLaundryOrderDetail.pending, (state) => {
        state.laundryOrderDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchLaundryOrderDetail.fulfilled, (state, action) => {
        state.laundryOrderDetailStatus = "succeeded";
        state.laundryOrderDetail = action.payload;
      })
      .addCase(fetchLaundryOrderDetail.rejected, (state, action) => {
        state.laundryOrderDetailStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(updateLaundryRequest.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateLaundryRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(updateLaundryRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectLaundryOrderDetail = (state) =>
  state.laundry?.laundryOrderDetail;

export default laundrySlice.reducer;
