import React from "react";
import { FaTimes } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";

export default function ModalSingleProject({
  id,
  userFirstname,
  userLastname,
  userEmail,
  name,
  date_of_project,
  cost,
  amount,
  days_left,
  investmentName,
  investmentAmount,
  completed,
  created_at,
}) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <IoEyeOutline className="w-4 h-4 text-[#667085]" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center p-6 font-semibold">
                      <h1 className="text-2xl text-gray-800  mb-4">
                        {name} Project
                      </h1>
                    </div>

                <div className="flex items-center px-8 py-4 gap-16">
                  <div className="flex flex-col gap-6 mt-6">
                   
                    <div className=" flex  flex-row gap-8">
                      <div>
                        <div className=" flex flex-row mb-4 gap-6">
                          <div className=" text-left">
                            <h1 className=" text-lg font-semibold">
                              Customer Details
                            </h1>
                          </div>
                          <div className=" flex flex-col gap-2 justify-between items-center">
                            <p className="flex flex-row justify-end">
                              {userFirstname} {userLastname}
                            </p>
                            <p>{userEmail}</p>
                          </div>
                        </div>
                        <div className=" flex flex-row mb-4 gap-6 justify-between items-center">
                          <div className="text-left">
                            <h1 className=" text-lg font-semibold">
                              Project Name
                            </h1>
                          </div>
                          <p>{name}</p>
                        </div>
                        <div className=" flex flex-row mb-4 gap-6 justify-between items-center">
                          <h1 className=" text-left text-lg font-semibold">
                            Investment Name
                          </h1>
                          <p> {investmentName}</p>
                        </div>
                        <div className=" flex flex-row gap-6 justify-between items-center">
                          <h1 className=" text-left text-lg font-semibold">
                            Amount Invested
                          </h1>
                          <p> <span className="mr-1">&#8358;</span> {amount}</p>
                        </div>
                      </div>
                      <div>
                        <div className=" flex flex-row mb-4 gap-6 justify-between items-center">
                          <h1 className=" text-center text-lg font-semibold">
                            Investment Amount
                          </h1>
                          <p> <span className="mr-1">&#8358;</span> {investmentAmount}</p>
                        </div>
                        <div className=" flex flex-row mb-4 gap-6 justify-between items-center">
                          <h1 className=" text-center text-lg font-semibold">
                            Cost
                          </h1>
                          <p>  <span className="mr-1">&#8358;</span> {cost}</p>
                        </div>
                        <div className=" flex flex-row mb-4 gap-6 justify-between items-center">
                          <h1 className=" text-center text-lg font-semibold">
                            Days left
                          </h1>
                          <p> {days_left} days</p>
                        </div>
                        <div className=" flex flex-row mb-2 gap-6 justify-between items-center">
                          <h1 className=" text-center text-lg font-semibold">
                            Date of Project
                          </h1>
                          <p> {date_of_project}</p>
                        </div>
                        <div className=" flex flex-row gap-6 justify-between items-center">
                          <h1 className=" text-center text-lg font-semibold">
                            Completed
                          </h1>
                          <p> {completed}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
