export const getFormattedDate = () => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date().toLocaleDateString("en-US", options);
};

export const getRandomDate = () => {
  const startDate = new Date(2022, 0, 1);
  const endDate = new Date(2022, 11, 31);
  const randomTime =
    startDate.getTime() +
    Math.random() * (endDate.getTime() - startDate.getTime());
  const randomDate = new Date(randomTime);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = randomDate.toLocaleDateString("en-US", options);

  return formattedDate;
};
