// Layout.js
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";

const Layout = () => {
  const storedUser = JSON.parse(localStorage.getItem("user"));

  const userFuctions = storedUser.functions;
  return (
    <div className=" bg-gray-100 h-screen w-screen flex flex-row overflow-hidden">
      <Sidebar userFuctions={userFuctions} />
      <div className="flex flex-col w-full overflow-hidden">
        <Header />
        <div className="flex-grow p-4 overflow-auto">{<Outlet />}</div>
      </div>
    </div>
  );
};

export default Layout;
