import React, { useEffect, useMemo, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";

import { Link } from "react-router-dom";

import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";

import { ImSpinner2 } from "react-icons/im";
import { fetchDailyRoi } from "../../redux/slices/dailyRoiSlice";
import { fetchUsers } from "../../redux/slices/userSlice";

const DailyROI = () => {
  const [search, setSearch] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDailyRoi());
    dispatch(fetchUsers());
  }, [dispatch]);

  const dailyRois = useSelector((state) => state.dailyRoi?.dailyRois);
  const usersList = useSelector((state) => state.user?.users);

  const reverseDailyRois = dailyRois?.slice()?.reverse();

  const status = useSelector((state) => state.dailyRoi?.status);
  const error = useSelector((state) => state.dailyRoi?.error);


  const getUserNameFromId = (userId) => {
    const user = usersList?.find((user) => user.id === parseInt(userId, 10));
    if (!user) {
      return { name: "Unknown", email: "N/A", phone: "N/A" };
    }

    return {
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      phone: user.phone,
    };
  };

  
  const filteredDailyRois = reverseDailyRois?.filter((dailyRoi) => {
    const user = getUserNameFromId(dailyRoi.user_id);

    
  
    const matchesSearch = search.toLowerCase() === "" 
    ? true 
    : (user.firstname && user.firstname.toLowerCase().includes(search)) ||
      (user.name && user.name.toLowerCase().includes(search));
  
    const matchesDate =
      !filterDate ||
      new Date(dailyRoi.created_at).toISOString().split("T")[0] === filterDate;
  
    return matchesSearch && matchesDate;
  });
  
  

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentDailyRois = filteredDailyRois?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredDailyRois?.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Calculate the total sum of prices
  const totalSum = useMemo(() => {
    return dailyRois.reduce(
      (sum, dailyRoi) => sum + parseFloat(dailyRoi.amount || 0),
      0
    );
  }, [dailyRois]);



  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Daily ROI
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Users</p>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Daily ROI</p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <div className=" flex gap-2 items-center">
          <button className="relative w-72">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <HiOutlineSearch className=" text-gray-500 text-2xl" />
            </div>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
              placeholder="Search ..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </button>
          <div className="">
            <input
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2"
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>
        </div>

        <div className="  p-2 text-center bg-gray-50 border border-gray-300 rounded-lg">
          <h1 className="text-gray-500 p-2 font-medium">
            Total:{" "}
            <span className="text-lg p-2 text-gray-700 font-bold">
              ₦{formatNumberWithCommas(totalSum)}
            </span>
          </h1>
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-left">Name</th>
                <th className="px-4 py-3 text-center ">Amount</th>
                <th className="px-4 py-3 text-center">Percentage</th>
                <th className="px-4 py-3 text-center">Created</th>
                <th className="px-4 py-3 text-center">Updated</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentDailyRois?.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentDailyRois?.map((dailyRoi, index) => (
                  <tr key={dailyRoi.id}>
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>

                    <td className="px-4 py-4 text-left">
                      <div className="flex flex-col gap-2">
                        <p className=" text-base font-bold">
                          {getUserNameFromId(dailyRoi.user_id).name}
                        </p>
                        <p>{getUserNameFromId(dailyRoi.user_id).email}</p>
                        <p>{getUserNameFromId(dailyRoi.user_id).phone}</p>
                      </div>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(dailyRoi.amount)}
                    </td>

                    <td className="px-4 py-4 text-center">
                      {dailyRoi.percentage}
                    </td>

                    <td className="px-4 py-4 text-center whitespace-nowrap">
                      {formatCreatedAt(dailyRoi.created_at)}
                    </td>
                    <td className="px-4 py-4 text-center whitespace-nowrap">
                      {formatCreatedAt(dailyRoi.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center">
                        <div>
                          <IoEyeOutline className="text-base text-[#667085]" />
                          {/* <Link to={`/dashboard/user/user-detail/${dailyRoi.id}`}>
                          </Link> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {filteredDailyRois?.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyROI;
