import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
import { fetchCouponHistory } from "../../redux/slices/couponHistorySlice";
import { fetchUsers } from "../../redux/slices/userSlice";

const UserCouponHistory = () => {
  const [search, setSearch] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCouponHistory());
    dispatch(fetchUsers());
  }, [dispatch]);

  const status = useSelector((state) => state.couponHistory?.status);
  const error = useSelector((state) => state.couponHistory?.error);

  const usersList = useSelector((state) => state.user?.users);

  const laundryCouponHistories = useSelector((state) =>
    state.couponHistory?.couponHistories?.filter(
      (history) => history.type === "laundry"
    )
  );

  const shoppingCouponHistories = useSelector((state) =>
    state.couponHistory?.couponHistories?.filter(
      (history) => history.type === "shopping"
    )
  );

  const isusuCouponHistories = useSelector((state) =>
    state.couponHistory?.couponHistories?.filter(
      (history) => history.type === "isusu"
    )
  );

  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [showWithdrawalsModal, setShowWithdrawalsModal] = useState(false);
  const [showRemittanceModal, setShowRemittanceModal] = useState(false);

  const getUserNameFromId = (userId) => {
    const user = usersList?.find((user) => user.id === parseInt(userId, 10));
    if (!user) {
      return { name: "Unknown", email: "N/A", phone: "N/A" };
    }

    return {
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      phone: user.phone,
    };
  };

  const filteredLaundryCouponHistories = laundryCouponHistories?.filter(
    (dailyRoi) => {
      const user = getUserNameFromId(dailyRoi.user_id);

      const matchesSearch =
        search.toLowerCase() === ""
          ? true
          : (user.firstname && user.firstname.toLowerCase().includes(search)) ||
            (user.name && user.name.toLowerCase().includes(search));

      const matchesDate =
        !filterDate ||
        new Date(dailyRoi.created_at).toISOString().split("T")[0] ===
          filterDate;

      return matchesSearch && matchesDate;
    }
  );

  const filteredShoppingCouponHistories = shoppingCouponHistories?.filter(
    (dailyRoi) => {
      const user = getUserNameFromId(dailyRoi.user_id);

      const matchesSearch =
        search.toLowerCase() === ""
          ? true
          : (user.firstname && user.firstname.toLowerCase().includes(search)) ||
            (user.name && user.name.toLowerCase().includes(search));

      const matchesDate =
        !filterDate ||
        new Date(dailyRoi.created_at).toISOString().split("T")[0] ===
          filterDate;

      return matchesSearch && matchesDate;
    }
  );

  const filteredIsusuCouponHistories = isusuCouponHistories?.filter(
    (dailyRoi) => {
      const user = getUserNameFromId(dailyRoi.user_id);

      const matchesSearch =
        search.toLowerCase() === ""
          ? true
          : (user.firstname && user.firstname.toLowerCase().includes(search)) ||
            (user.name && user.name.toLowerCase().includes(search));

      const matchesDate =
        !filterDate ||
        new Date(dailyRoi.created_at).toISOString().split("T")[0] ===
          filterDate;

      return matchesSearch && matchesDate;
    }
  );

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Coupon History
          </h1>

          <div className="flex mb-4 justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Users</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Coupon History
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-4"></div>

      <div className=" flex flex-row gap-4">
        <div className=" w-6/12">
          <div className=" flex flex-col gap-2">
            {/* Payment */}
            <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
              <div className=" flex justify-between  p-6">
                <p className=" font-bold text-base uppercase text-gray-500">
                  Laundry Coupon History
                </p>
                <div className="bg-gray-500  hover:bg-blue-500 ease-in duration-300 text-gray-50 p-2  rounded-lg">
                  <button
                    className=" text-sm hover:text-base font-medium ease-in duration-300"
                    onClick={() => setShowPaymentsModal(true)}
                  >
                    See More
                  </button>
                </div>
              </div>
              <div className="relative overflow-x-auto rounded-t-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-center">No.</th>
                      <th className="px-4 py-4 text-left">Name</th>
                      <th className="px-4 py-4 text-center">Admin</th>
                      <th className="px-2 py-3 text-center">Amount</th>
                      <th className="px-2 py-3 text-center">Created</th>
                      <th className="px-2 py-3 text-center">Updated</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm text-left">
                    {status === "loading" ? (
                      <tr>
                        <td colSpan="8" className="text-center py-4">
                          <div className="flex justify-center items-center">
                            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                          </div>
                        </td>
                      </tr>
                    ) : filteredLaundryCouponHistories?.length === 0 ? (
                      <tr>
                        <td
                          colSpan="12"
                          className="text-center text-base text-gray-500 py-4"
                        >
                          No Data
                        </td>
                      </tr>
                    ) : (
                      filteredLaundryCouponHistories?.map((user, index) => (
                        <tr key={user.id}>
                          <td className="px-4 py-4 ">
                            <div className="flex justify-center items-center">
                              <p className=" text-base text-center font-semibold ">
                                {index + 1}
                              </p>
                            </div>
                          </td>

                          <td className="px-4 py-4 text-left">
                            <div className="flex flex-col gap-2">
                              <p className=" text-base font-bold">
                                {getUserNameFromId(user.user_id).name}
                              </p>
                              <p>{getUserNameFromId(user.user_id).email}</p>
                              <p>{getUserNameFromId(user.user_id).phone}</p>
                            </div>
                          </td>
                          <td className="px-4 py-4">
                            <div className="flex flex-col text-center gap-2">
                              <p className=" text-blue-600 text-base font-semibold whitespace-nowrap">
                                {user.admin}
                              </p>
                            </div>
                          </td>
                          <td className="px-4 py-4 text-center">
                            <span className="mr-1">&#8358;</span>
                            {formatNumberWithCommas(user.amount)}
                          </td>

                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.created_at)}
                          </td>
                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.updated_at)}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Shopping Coupon History */}
            <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
              <div className="flex justify-between p-6">
                <p className=" font-bold text-base uppercase text-gray-500">
                  Shopping Coupon History
                </p>
                <div className="bg-gray-500  hover:bg-blue-500 ease-in duration-300 text-gray-50 p-2  rounded-lg">
                  <button
                    className=" text-sm hover:text-base font-medium ease-in duration-300"
                    onClick={() => setShowWithdrawalsModal(true)}
                  >
                    See More
                  </button>
                </div>
              </div>
              <div className="relative overflow-x-auto rounded-t-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                    <tr>
                      <th className="px-4 py-3 text-center">No.</th>
                      <th className="px-4 py-4 text-left">Name</th>
                      <th className="px-4 py-4 text-center">Admin</th>
                      <th className="px-2 py-3 text-center">Amount</th>
                      <th className="px-2 py-3 text-center">Created</th>
                      <th className="px-2 py-3 text-center">Updated</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm text-left">
                    {status === "loading" ? (
                      <tr>
                        <td colSpan="12" className="text-center py-4">
                          <div className="flex justify-center items-center">
                            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                          </div>
                        </td>
                      </tr>
                    ) : filteredShoppingCouponHistories?.length === 0 ? (
                      <tr>
                        <td
                          colSpan="12"
                          className="text-center text-base text-gray-500 py-4"
                        >
                          No Data
                        </td>
                      </tr>
                    ) : (
                      filteredShoppingCouponHistories?.map((user, index) => (
                        <tr key={user.id} className=" whitespace-nowrap">
                          <td className="px-4 py-4 ">
                            <div className="flex justify-center items-center">
                              <p className=" text-base text-center font-semibold ">
                                {index + 1}
                              </p>
                            </div>
                          </td>

                          <td className="px-4 py-4 text-left">
                            <div className="flex flex-col gap-2">
                              <p className=" text-base font-bold">
                                {getUserNameFromId(user.user_id).name}
                              </p>
                              <p>{getUserNameFromId(user.user_id).email}</p>
                              <p>{getUserNameFromId(user.user_id).phone}</p>
                            </div>
                          </td>

                          <td className="px-4 py-4">
                            <div className="flex flex-col text-center gap-2">
                              <p className=" text-blue-600 text-base font-semibold">
                                {user.admin}
                              </p>
                            </div>
                          </td>

                          <td className="px-4 py-4 text-center">
                            <span className="mr-1">&#8358;</span>
                            {formatNumberWithCommas(user.amount)}
                          </td>

                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.created_at)}
                          </td>
                          <td className="px-2 py-2 text-center whitespace-nowrap">
                            {formatCreatedAt(user.updated_at)}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="w-6/12">
          {/* Isusu Coupon History */}
          <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
            <div className=" flex justify-between p-6">
              <p className=" font-bold text-base uppercase text-gray-500">
                Isusu Coupon History
              </p>
              <div className="bg-gray-500  hover:bg-blue-500 ease-in duration-300 text-gray-50 p-2  rounded-lg">
                <button
                  className=" text-sm hover:text-base font-medium ease-in duration-300"
                  onClick={() => setShowRemittanceModal(true)}
                >
                  See More
                </button>
              </div>
            </div>
            <div className="relative overflow-x-auto rounded-t-lg">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                  <tr>
                    <th className="px-4 py-3 text-center">No.</th>
                    <th className="px-4 py-4 text-left">Name</th>
                    <th className="px-4 py-4 text-center">Admin</th>
                    <th className="px-2 py-3 text-center">Amount</th>
                    <th className="px-2 py-3 text-center">Created</th>
                    <th className="px-2 py-3 text-center">Updated</th>
                  </tr>
                </thead>
                <tbody className="text-sm text-left">
                  {status === "loading" ? (
                    <tr>
                      <td colSpan="8" className="text-center py-4">
                        <div className="flex justify-center items-center">
                          <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                        </div>
                      </td>
                    </tr>
                  ) : filteredIsusuCouponHistories?.length === 0 ? (
                    <tr>
                      <td
                        colSpan="12"
                        className="text-center text-base text-gray-500 py-4"
                      >
                        No Data
                      </td>
                    </tr>
                  ) : (
                    filteredIsusuCouponHistories?.map((user, index) => (
                      <tr key={user.id}>
                        <td className="px-4 py-4 ">
                          <div className="flex justify-center items-center">
                            <p className=" text-base text-center font-semibold ">
                              {index + 1}
                            </p>
                          </div>
                        </td>

                        <td className="px-4 py-4 text-left">
                          <div className="flex flex-col gap-2">
                            <p className=" text-base font-bold">
                              {getUserNameFromId(user.user_id).name}
                            </p>
                            <p>{getUserNameFromId(user.user_id).email}</p>
                            <p>{getUserNameFromId(user.user_id).phone}</p>
                          </div>
                        </td>

                        <td className="px-4 py-4">
                          <div className="flex flex-col text-center gap-2">
                            <p className=" text-blue-600 text-base font-semibold">
                              {user.admin}
                            </p>
                          </div>
                        </td>

                        <td className="px-4 py-4 text-center">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(user.amount)}
                        </td>

                        <td className="px-2 py-2 text-center whitespace-nowrap">
                          {formatCreatedAt(user.created_at)}
                        </td>
                        <td className="px-2 py-2 text-center whitespace-nowrap">
                          {formatCreatedAt(user.updated_at)}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showPaymentsModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[100vh] overflow-y-auto">
            <div className=" flex justify-between p-2">
              <h2 className="text-2xl font-semibold text-gray-500  mt-2 mb-2">
                Laundry Coupon History
              </h2>
              <button
                onClick={() => setShowPaymentsModal(false)}
                className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-bold text-sm hover:text-base "
              >
                Close
              </button>
            </div>
            <div className=" flex gap-2 items-center mb-4">
              <button className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <HiOutlineSearch className=" text-gray-500 text-2xl" />
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  placeholder="Search ..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </button>
              <div className="">
                <input
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2"
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </div>
            </div>
            <table className="w-full text-sm whitespace-nowrap text-center text-gray-500">
              <thead className="text-sm text-gray-700 bg-gray-100">
                <tr>
                  <th className="px-4 py-3 text-center">No.</th>
                  <th className="px-4 py-4 text-left">Name</th>
                  <th className="px-4 py-4 text-center">Admin</th>
                  <th className="px-2 py-3 text-center">Amount</th>
                  <th className="px-2 py-3 text-center">Created</th>
                  <th className="px-2 py-3 text-center">Updated</th>
                </tr>
              </thead>
              <tbody>
                {status === "failed" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        Error. {error}.{" "}
                        <span className="text-base font-semibold ">
                          {" "}
                          Try again later
                        </span>
                      </div>
                    </td>
                  </tr>
                ) : status === "loading" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                      </div>
                    </td>
                  </tr>
                ) : filteredLaundryCouponHistories?.length === 0 ? (
                  <tr>
                    <td colSpan="12" className="text-center text-gray-500 py-4">
                      No Data
                    </td>
                  </tr>
                ) : (
                  filteredLaundryCouponHistories?.map((user, index) => (
                    <tr key={user.id}>
                      <td className="px-4 py-4">{index + 1}</td>
                      <td className="px-4 py-4 text-left">
                        <div className="flex flex-col gap-2">
                          <p className=" text-base font-bold">
                            {getUserNameFromId(user.user_id).name}
                          </p>
                          <p>{getUserNameFromId(user.user_id).email}</p>
                          <p>{getUserNameFromId(user.user_id).phone}</p>
                        </div>
                      </td>
                      <td className="px-4 py-4">{user.admin}</td>

                      <td className="px-4 py-4">
                        <span className="ml-1">&#8358;</span>{" "}
                        {formatNumberWithCommas(user.amount)}
                      </td>
                      <td className="px-4 py-4">
                        {formatCreatedAt(user.created_at)}
                      </td>
                      <td className="px-4 py-4">
                        {formatCreatedAt(user.updated_at)}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showWithdrawalsModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[100vh] overflow-y-auto">
            <div className=" flex justify-between p-2">
              <h2 className="text-2xl font-semibold text-gray-500  mt-4 mb-4">
                Shopping Coupon History
              </h2>
              <button
                onClick={() => setShowWithdrawalsModal(false)}
                className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-bold text-sm hover:text-base "
              >
                Close
              </button>
            </div>
            <div className=" flex gap-2 items-center mb-4">
              <button className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <HiOutlineSearch className=" text-gray-500 text-2xl" />
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  placeholder="Search ..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </button>
              <div className="">
                <input
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2"
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </div>
            </div>
            <table className="w-full text-sm text-center text-gray-500">
              <thead className="text-sm text-gray-700 bg-gray-100">
                <tr>
                  <th className="px-4 py-3 text-center">No.</th>
                  <th className="px-4 py-4 text-left">Name</th>
                  <th className="px-4 py-4 text-center">Admin</th>
                  <th className="px-2 py-3 text-center">Amount</th>
                  <th className="px-2 py-3 text-center">Created</th>
                  <th className="px-2 py-3 text-center">Updated</th>
                </tr>
              </thead>
              <tbody>
                {status === "failed" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        Error. {error}.{" "}
                        <span className="text-base font-semibold ">
                          {" "}
                          Try again later
                        </span>
                      </div>
                    </td>
                  </tr>
                ) : status === "loading" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                      </div>
                    </td>
                  </tr>
                ) : filteredShoppingCouponHistories?.length === 0 ? (
                  <tr>
                    <td colSpan="12" className="text-center text-gray-500 py-4">
                      No Data
                    </td>
                  </tr>
                ) : (
                  filteredShoppingCouponHistories.map((user, index) => (
                    <tr key={user.id}>
                      <td className="px-4 py-4">{index + 1}</td>
                      <td className="px-4 py-4 text-left">
                        <div className="flex flex-col gap-2">
                          <p className=" text-base font-bold">
                            {getUserNameFromId(user.user_id).name}
                          </p>
                          <p>{getUserNameFromId(user.user_id).email}</p>
                          <p>{getUserNameFromId(user.user_id).phone}</p>
                        </div>
                      </td>
                      <td className="px-4 py-4">{user.admin}</td>
                      <td className="px-4 py-4">
                        <span className="ml-1">&#8358;</span>
                        {formatNumberWithCommas(user.amount)}
                      </td>
                      <td className="px-4 py-4">
                        {formatCreatedAt(user.created_at)}
                      </td>
                      <td className="px-4 py-4">
                        {formatCreatedAt(user.updated_at)}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {showRemittanceModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[100vh] overflow-y-auto">
            <div className=" flex justify-between p-2">
              <h2 className="text-2xl font-semibold text-gray-500 mt-4 mb-4">
                Isusu Coupon History
              </h2>
              <button
                onClick={() => setShowRemittanceModal(false)}
                className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-bold text-sm hover:text-base "
              >
                Close
              </button>
            </div>
            <div className=" flex gap-2 items-center mb-4">
              <button className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <HiOutlineSearch className=" text-gray-500 text-2xl" />
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                  placeholder="Search ..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </button>
              <div className="">
                <input
                  type="date"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2"
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </div>
            </div>
            <table className="w-full text-sm text-center text-gray-500">
              <thead className="text-sm text-gray-700 bg-gray-100">
                <tr>
                  <th className="px-4 py-3 text-center">No.</th>
                  <th className="px-4 py-4 text-left">Name</th>
                  <th className="px-4 py-4 text-center">Admin</th>
                  <th className="px-2 py-3 text-center">Amount</th>
                  <th className="px-2 py-3 text-center">Created</th>
                  <th className="px-2 py-3 text-center">Updated</th>
                </tr>
              </thead>
              <tbody>
                {status === "failed" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        Error. {error}.{" "}
                        <span className="text-base font-semibold ">
                          {" "}
                          Try again later
                        </span>
                      </div>
                    </td>
                  </tr>
                ) : status === "loading" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                      </div>
                    </td>
                  </tr>
                ) : filteredIsusuCouponHistories?.length === 0 ? (
                  <tr>
                    <td colSpan="12" className="text-center text-gray-500 py-4">
                      No Data
                    </td>
                  </tr>
                ) : (
                  filteredIsusuCouponHistories?.map((user, index) => (
                    <tr key={user.id}>
                      <td className="px-4 py-4">{index + 1}</td>
                      <td className="px-4 py-4 text-left">
                        <div className="flex flex-col gap-2">
                          <p className=" text-base font-bold">
                            {getUserNameFromId(user.user_id).name}
                          </p>
                          <p>{getUserNameFromId(user.user_id).email}</p>
                          <p>{getUserNameFromId(user.user_id).phone}</p>
                        </div>
                      </td>
                      <td className="px-4 py-4">{user.admin}</td>
                      <td className="px-4 py-4">
                        <span className="ml-1">&#8358;</span>
                        {formatNumberWithCommas(user.amount)}
                      </td>
                      <td className="px-4 py-4">
                        {formatCreatedAt(user.created_at)}
                      </td>
                      <td className="px-4 py-4">
                        {formatCreatedAt(user.updated_at)}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCouponHistory;
