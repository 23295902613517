import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { format, startOfMonth, startOfYear, eachMonthOfInterval } from "date-fns";
import { ImSpinner2 } from "react-icons/im";
import { fetchShoppingMonthlyReport } from "../../redux/slices/shoppingMonthlyReportSlice";

const ShoppingMonthlyReport = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchShoppingMonthlyReport());
  }, [dispatch]);

  const shoppingMonthlyReports = useSelector(
    (state) => state.shoppingMonthlyReport?.shoppingMonthlyReports
  );

  const statusL = useSelector((state) => state.shoppingMonthlyReport?.status);
  const error = useSelector((state) => state.shoppingMonthlyReport?.error);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-based month index

  // const [currentMonthStart, setCurrentMonthStart] = useState(
  //   startOfMonth(new Date())
  // );
  // const [selectedMonth, setSelectedMonth] = useState(
  //   format(currentMonthStart, "MMMM yyyy")
  // );

  // const filterOrdersForSelectedMonth = (orders, selectedMonth) => {
  //   const filteredOrders = orders.filter(
  //     (order) => order.month === selectedMonth
  //   );
  //   return filteredOrders;
  // };

  // const handleMonthChange = (e) => {
  //   setSelectedMonth(e.target.value);
  // };

  // useEffect(() => {
  //   setCurrentMonthStart(startOfMonth(new Date(selectedMonth)));
  // }, [selectedMonth]);

  // Filter reports from January to the current month
  const filteredShoppingMonthlyReports = shoppingMonthlyReports?.filter((order) => {
    const orderDate = new Date(order.month);
    return (
      orderDate.getFullYear() === currentYear &&
      orderDate.getMonth() + 1 <= currentMonth
    );
  });

  // Generate month options from January to the current month
  // const monthOptions = eachMonthOfInterval({
  //   start: startOfYear(new Date()),
  //   end: new Date()
  // }).map((date) => format(date, "MMMM yyyy"));

  return (
    <div className="w-full h-auto mt-8 mb-[24px]">
      <div className="bg-white rounded-lg px-6 py-6 shadow-md">
        <div className="flex items-center justify-between p-2 mb-[24px]">
          <h1 className="text-[22px] text-[#202020] font-semibold">
            Shopping Business Report
          </h1>
          {/* <div className="text-gray-400 p-2 ">
            <select
              onChange={handleMonthChange}
              value={selectedMonth}
              className="p-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500"
            >
              {monthOptions.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        <div className="w-full">
          <div className="relative overflow-x-auto border-[1px] rounded-lg border-[#E8E8E8]">
            <table className="w-full text-center">
              <thead className="text-gray-500 bg-gray-50 whitespace-nowrap">
                <tr>
                  <th className="px-4 py-4 text-center">Month</th>
                  <th className="px-4 py-3 text-center">
                    No. of new customers
                  </th>
                  <th className="px-4 py-3 text-center">
                    No. of membership subscribers
                  </th>
                  <th className="px-4 py-3 text-center">
                    Total number of customers
                  </th>
                  <th className="px-4 py-3 text-center">
                    No. of customers who didn't shop
                  </th>
                  <th className="px-4 py-3 text-center">
                    No. of customers who shopped
                  </th>
                  <th className="px-4 py-3 text-center">
                    Total number of shopping done
                  </th>
                </tr>
              </thead>
              <tbody className="w-fit">
                {statusL === "failed" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        Error. {error}.{" "}
                        <span className="text-base font-semibold ">
                          {" "}
                          Try again later
                        </span>
                      </div>
                    </td>
                  </tr>
                ) : statusL === "loading" ? (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      <div className="flex justify-center items-center">
                        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                      </div>
                    </td>
                  </tr>
                ) : (
                  filteredShoppingMonthlyReports?.map((order, index) => (
                    <tr key={index} className="bg-white border-b">
                      <td className="px-4 py-2 text-center">
                        <p className="text-base font-medium text-center whitespace-nowrap text-gray-500">
                          {order.month}
                        </p>
                      </td>
                      <td className="px-4 py-2">
                        <p className="text-base font-medium text-center whitespace-nowrap text-gray-500">
                          {order.new_customers}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p className="text-base font-medium text-center whitespace-nowrap text-gray-500">
                          {order.membership_subs}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p className="text-base font-medium text-center whitespace-nowrap text-gray-500">
                          {order.total_customers}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p className="text-base font-medium text-center whitespace-nowrap text-gray-500">
                          {order.customers_who_didnt_shop}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center whitespace-nowrap">
                        <p className="text-base font-medium text-center whitespace-nowrap text-gray-500">
                          {order.customers_who_shopped}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center whitespace-nowrap">
                        <p className="text-base font-medium text-center whitespace-nowrap text-gray-500">
                          {order.orders_this_month}
                        </p>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingMonthlyReport;
