import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { fetchbalanceDetail } from "../redux/slices/balanceDetailsSlice";

const BalanceDetails = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchbalanceDetail());
  }, [dispatch]);

  const balanceDetails = useSelector(
    (state) => state.balanceDetail.balanceDetails
  );

  const [isBalanceHidden, setIsBalanceHidden] = useState(false);

  const handleToggleBalance = () => {
    setIsBalanceHidden(!isBalanceHidden);
  };

  const formatNumberWithCommas = (number) => {
    const num = parseFloat(number);
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="w-full">
      <div className=" mt-8 mb-[24px] rounded">
        <div className="flex flex-col md:flex-row justify-between gap-[160px] overflow-x-auto  rounded-lg bg-white px-6 py-6 shadow-md">
          <div className="flex flex-col p-4 gap-12">
            <h1 className="text-[24px] text-[#202020] font-semibold whitespace-nowrap">
              Balance Details
            </h1>
            <button
              id="toggleButton"
              type="button"
              className="flex gap-4 border-[1px] px-3 py-2 rounded-lg items-center"
              onClick={handleToggleBalance}
            >
              {isBalanceHidden ? (
                <FaEye className="text-gray-500 text-lg" />
              ) : (
                <FaEyeSlash className="text-gray-500 text-lg" />
              )}
              <p className=" text-base whitespace-nowrap">
                {isBalanceHidden ? "Show Balance" : "Hide Balance"}
              </p>
            </button>
          </div>

          <div className="flex justify-between">
            <div className="flex gap-6 px-8">
              <div className="border-[1px] h-[88px] mt-4 border-[#E8E8E8]"></div>

              {/* Sum Total Shopping Revenue */}
              <div className="flex flex-col gap-4 p-4">
                <h1 className="text-[14px] p-1 text-[#202020] font-semibold whitespace-nowrap md:whitespace-normal">
                  Sum Total Shopping Revenue
                </h1>
                <div className="flex items-center p-1 mt-2">
                  <p className="text-lg text-[#84818A] font- whitespace-nowrap">
                    ₦
                  </p>
                  <span
                    className="text-2xl text-[#202020] ml-1 font-bold"
                    id="totalShoppingRevenue"
                  >
                    {isBalanceHidden
                      ? "****"
                      : formatNumberWithCommas(balanceDetails.shopping_revenue)}
                  </span>
                </div>
              </div>

              <div className="border-[1px] border-solid h-[88px] mt-4 border-[#E8E8E8]"></div>

              {/* Sum Total Investment Revenue */}
              <div className="flex flex-col gap-4 p-4">
                <h1 className="text-[14px] p-1 text-[#202020] font-semibold whitespace-nowrap md:whitespace-normal">
                  Sum Total Investment Revenue
                </h1>
                <div className="flex items-center p-1 mt-2">
                  <p className="text-lg text-[#84818A] font-semibold">₦</p>
                  <span
                    className="text-2xl text-[#202020] ml-1 font-bold"
                    id="totalInvestmentRevenue"
                  >
                    {isBalanceHidden
                      ? "****"
                      : formatNumberWithCommas(
                          balanceDetails.total_investment_revenue
                        )}
                  </span>
                </div>
              </div>

              <div className="border-[1px] border-solid h-[88px] mt-4 border-[#E8E8E8]"></div>

              {/* Sum Total Laundry Revenue */}
              <div className="flex flex-col gap-4 p-4">
                <h1 className="text-[14px] p-1 text-[#202020] font-semibold whitespace-nowrap md:whitespace-normal">
                  Sum Total Laundry Revenue
                </h1>
                <div className="flex items-center p-1 mt-2">
                  <p className="text-lg text-[#84818A] font-semibold">₦</p>
                  <span
                    className="text-2xl text-[#202020] ml-1 font-bold"
                    id="totalLaundryRevenue"
                  >
                    {isBalanceHidden
                      ? "****"
                      : formatNumberWithCommas(
                          balanceDetails.total_laundry_revenue
                        )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceDetails;
