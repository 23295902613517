import React, { useEffect, useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { PiPlusBold } from "react-icons/pi";
import { toast } from "react-toastify";
import { addIncrementPrice } from "../../../redux/slices/productSlice";
import { useDispatch } from "react-redux";
import { fetchStoreDetail } from "../../../redux/slices/storeSlice";

export default function IncrementPrice({
  storeId: id,
  selectedProductIds,
  clearSelectedProductIds,
}) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    product_ids: [],
    percentage: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal) {
      setFormData({
        product_ids: selectedProductIds,
        percentage: "",
      });
    }
  }, [showModal, selectedProductIds]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let floatValue = parseFloat(value);

    // Ensure the value is not greater than 100
    floatValue = Math.min(Math.max(floatValue, 1), 100);

    setFormData({
      ...formData,
      [name]: floatValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await dispatch(addIncrementPrice(formData));

      setLoading(false);
      clearSelectedProductIds();
      
      // Clear the form data
      setFormData({
        product_ids: [],
        percentage: "",
      });

      await dispatch(fetchStoreDetail(id));
      toast.success(res.payload.message);
      // toast.success("Increment price has been updated");
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        disabled={
          selectedProductIds.length === 0
        }
        className={`py-2 px-4 text-[14px] rounded-md font-medium text-white ${
          selectedProductIds.length === 0
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-[#1F7BF4] hover:shadow-lg"
        }`}>
        {/* <PiPlusBold className=" text-lg font-extrabold" /> */}
        Increment Price
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Increment Price
                  </h1>
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="percentage"
                        className="text-[16px] text-center text-gray-500 font-medium tracking-[0.07px]"
                      >
                        Percentage
                      </label>
                      <div className="flex items-center">
                        <input
                          name="percentage"
                          id="percentage"
                          type="number"
                          value={formData.percentage}
                          onChange={handleInputChange}
                          placeholder="Type percentage here..."
                          className="pl-[12px] text-center text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>

                    <div className="flex mt-6 gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Updating...
                            </p>
                          </>
                        ) : (
                          <>
                            <PiPlusBold className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Increment Price
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
