import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";

import { fetchShoppers, updateShopper } from "../../redux/slices/shopperSlice";

export default function UpdateShopper({
  user_id,
  name,
  email,
  phone,
  state,
  city,
  country,
}) {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const status = useSelector((state) => state.driver?.status);

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData({ name, email, phone, state, city, country });
  }, [name, email, phone, state, city, country]);

  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;
    if (name === "state" || name === "country" || name === "city") {
      updatedValue = parseInt(value, 10) || 0;
    }

    setFormData({
      ...formData,
      [name]: updatedValue,
      user_id: user_id,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(updateShopper(formData));

      toast.success(res.payload.message);

      dispatch(fetchShoppers());

      setShowModal(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className=" flex justify-center">
        <button type="button" onClick={() => setShowModal(true)}>
          <FaEdit className=" text-gray-500 font-semibold text-lg" />
        </button>
      </div>
      {showModal ? (
        <>
          {/* Modal content */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-5 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Update Shopper
                  </h1>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col items-center px-8 py-4 gap-4">
                    <div className="flex flex-row gap-4 ">
                      <div className=" flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="name"
                            className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                          >
                            Name
                          </label>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            value={formData.name || ""}
                            onChange={handleInputChange}
                            className=" text-[#333843] px-3 py-3  rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="email"
                            className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            name="email"
                            value={formData.email || ""}
                            onChange={handleInputChange}
                            className=" text-[#333843] px-3 py-3  rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>

                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="phone"
                            className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                          >
                            Phone
                          </label>
                          <input
                            type="number"
                            name="phone"
                            value={formData.phone || ""}
                            onChange={handleInputChange}
                            className=" text-[#333843] px-3 py-3 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          />
                        </div>
                      </div>

                      <div className=" flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="state"
                            className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                          >
                            Country
                          </label>
                          <select
                            name="country"
                            id="country"
                            value={formData.country}
                            onChange={handleInputChange}
                            className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          >
                            {countries?.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="state"
                            className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                          >
                            State
                          </label>
                          <select
                            name="state"
                            id="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          >
                            {states
                              ?.filter(
                                (state) => state.country_id === formData.country
                              )
                              .map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="flex flex-col gap-1">
                          <label
                            htmlFor="state"
                            className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                          >
                            City
                          </label>

                          <select
                            name="city"
                            id="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                          >
                            {provinces
                              ?.filter(
                                (province) =>
                                  province.country_id === formData.country &&
                                  province.state_id === formData.state
                              )
                              .map((province) => (
                                <option key={province.id} value={province.id}>
                                  {province.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        type="submit"
                      >
                        {status === "loading" ? (
                          <>
                            <ImSpinner2 className="animate-spin text-base" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Updating ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaEdit className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Update Shopper
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
