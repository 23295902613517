// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBy2FQF6BJo1_4VHFrZk1Kb8r9xF3lu2vg",
  authDomain: "capfields-evolve-5e504.firebaseapp.com",
  projectId: "capfields-evolve-5e504",
  storageBucket: "capfields-evolve-5e504.appspot.com",
  messagingSenderId: "197741240085",
  appId: "1:197741240085:web:d26c172b545be39db6931c",
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
