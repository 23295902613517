import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { updateLaundryItem } from "../../../redux/slices/laundryItem";
import { ImSpinner2 } from "react-icons/im";
import { fetchLaundryStoreDetail } from "../../../redux/slices/laundryStoreSlice";
import { formatNumberWithCommas2 } from "../../../utils/commonUtils";

export default function ModalUpdateLaundryItem({
  item_id,
  name,
  wash,
  dryclean,
  starch,
  iron,
  laundry_id,
}) {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const status = useSelector((state) => state.laundryItem?.status);

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData({ name, dryclean, wash, starch, iron, laundry_id });
  }, [name, dryclean, wash, starch, iron, laundry_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // let updatedValue = value;
    const parsedValue = value.replace(/,/g, '');

    setFormData({
      ...formData,
      [name]: parsedValue ,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    try {
      const res = await dispatch(updateLaundryItem({ item_id, formData }));

      if (res.payload.status === "success") {
        toast.success(res.payload.message);
        const id = laundry_id;
        dispatch(fetchLaundryStoreDetail(id));

        setShowModal(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <button type="button" onClick={() => setShowModal(true)}>
        <FaEdit className=" text-gray-500 font-semibold text-base" />
      </button>
      {showModal ? (
        <>
          {/* Modal content */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-8 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-5 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Update Laundry Item
                  </h1>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center px-8 py-4 gap-4">
                    <div className="flex flex-col gap-4 ">
                      {/* Input fields */}
                      {/* Name */}
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="name"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Name
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          value={formData.name || ""}
                          onChange={handleInputChange}
                          placeholder="Type laundry item here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                      {/* Dyclean Price */}
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="dryclean"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Dryclean Price
                        </label>
                        <input
                          type="text"
                          name="dryclean"
                          value={formatNumberWithCommas2(formData.dryclean || "")}
                          onChange={handleInputChange}
                          placeholder="Enter dryclean price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                       {/* Wash Price */}
                       <div className="flex flex-col gap-1">
                        <label
                          htmlFor="wash"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Wash Price
                        </label>
                        <input
                          type="text"
                          name="wash"
                          value={formatNumberWithCommas2(formData.wash || "")}
                          onChange={handleInputChange}
                          placeholder="Enter washing price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                       {/* Iron Price */}
                       <div className="flex flex-col gap-1">
                        <label
                          htmlFor="iron"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Iron Price
                        </label>
                        <input
                          type="text"
                          name="iron"
                          value={formatNumberWithCommas2(formData.iron || "")}
                          onChange={handleInputChange}
                          placeholder="Enter ironing price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                      {/* Starch Price */}
                      <div className="flex flex-col gap-1">
                        <label
                          htmlFor="starch"
                          className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                        >
                          Starch Price
                        </label>
                        <input
                          type="text"
                          name="starch"
                          value={formatNumberWithCommas2(formData.starch || "")}
                          onChange={handleInputChange}
                          placeholder="Enter starching price here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                     
                      {/* Laundry ID */}
                      <div className="flex flex-col gap-1">
                        <input
                          type="hidden"
                          name="laundry_id"
                          value={formData.laundry_id || ""}
                          onChange={handleInputChange}
                          placeholder="Enter laundry ID here..."
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 justify-center">
                    <button
                      onClick={() => setShowModal(false)}
                      className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                      disabled={status === "loading"}
                      type="submit"
                    >
                      {status === "loading" ? (
                        <>
                          <ImSpinner2 className="animate-spin text-base" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Updating ...
                          </p>
                        </>
                      ) : (
                        <>
                          <FaEdit className="w-4 h-4" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Update Item
                          </p>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
