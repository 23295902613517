import React, { useState } from "react";
import { FaSpinner, FaTimes, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BsExclamationLg } from "react-icons/bs";
import {
  deleteLaundrySub,
  fetchLaundrySubs,
} from "../../../redux/slices/laundrySubSlice";

export default function ModalDeleteLaundrySub({
  id,
  name,
  cost,
  usage,
  pickup,
  delivery,
}) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const status = useSelector((state) => state.laundrySub?.status);

  const handleDelete = async () => {
    try {
      const res = await dispatch(deleteLaundrySub(id));
      setShowModal(false);
      toast.success(res.payload.message);
      dispatch(fetchLaundrySubs());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
      >
        <FaTrash className="text-gray-500 text-base " />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center mb-4">
                  <h1 className="text-2xl text-gray-800 m-4 text-center font-medium">
                    Delete Laundry Subscriptions
                  </h1>
                  <p>Do you want to Delete this Laundry Subscription ?</p>
                </div>
                <div className="flex justify-center items-center m-2">
                  <BsExclamationLg className="text-gray-500 text-3xl font-bold" />
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-row gap-6 ">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-row m-auto gap-2">
                        <h1 className="text-[14px] text-left text-gray-400 ">
                          Name :
                        </h1>
                        <div className="">
                          <p className=" text-base font-bold">{name}</p>
                        </div>
                      </div>

                      <div className="flex mt-2 gap-4 justify-center">
                        <button
                          onClick={() => setShowModal(false)}
                          className="text-sm text-gray-500 font-semibold bg-gray-100 py-2 px-4 rounded-md"
                        >
                          Cancel
                        </button>
                        <button
                          className="flex gap-1 rounded-md items-center bg-red-600 text-white py-2 px-4"
                          disabled={status === "loading"}
                          onClick={handleDelete}
                        >
                          {status === "loading" ? (
                            <>
                              <FaSpinner className="animate-spin" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Deleting ...
                              </p>
                            </>
                          ) : (
                            <>
                              <FaTimes className="w-4 h-4" />
                              <p className="text-sm font-semibold whitespace-nowrap">
                                Delete Laundry Sub
                              </p>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
