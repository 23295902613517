import React, { useState, useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvestorInvestment } from "../../redux/slices/investorInvestmentSlice";
import { formatCreatedAt, formatNumberWithCommas } from "../../utils/commonUtils";
import { fetchInvestments } from "../../redux/slices/investmentSlice";
import { fetchUsers } from "../../redux/slices/userSlice";
import { ImSpinner2 } from "react-icons/im";

const InvestorList = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInvestorInvestment());
    dispatch(fetchInvestments());
    dispatch(fetchUsers());
  }, [dispatch]);

  const investorInvestments = useSelector(
    (state) => state.investorInvestment?.investorInvestments
  );

  const reversedInvestorInvestments = investorInvestments.slice().reverse();

  const users = useSelector((state) => state.user?.users);

  // Filter categories based on search term
  const filteredInvestorInvestments = reversedInvestorInvestments.filter(
    (user) =>
      search.toLowerCase() === ""
        ? user
        : user.username.toLowerCase().includes(search)
  );

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentInvestorInvestments = filteredInvestorInvestments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(
    filteredInvestorInvestments.length / itemsPerPage
  );

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const investments = useSelector((state) => state.investment?.investments);
  const status = useSelector((state) => state.investorInvestment?.status);
  const error = useSelector((state) => state.investorInvestment?.error);


  const getUserDataFromUserId = (investmentId) => {
    const id = Number(investmentId);
    const user = users?.find((user) => user.id === id);

    return user
      ? {
          email: user.email || "Unknown",
        }
      : { email: "Unknown" };
  };
  const getInvestmentDataFromInvestmentId = (investmentId) => {
    // Convert investmentId to a number
    const id = Number(investmentId);

    // Find the investment with the matching id
    const investment = investments.find((investment) => investment.id === id);

    // If investment is found, return ROI; otherwise, return "Unknown"
    return investment
      ? { roi: investment.roi || "Unknown" }
      : { roi: "Unknown" };
  };

  const calculateProfit = (investmentId, amountInvested) => {
    const roi = getInvestmentDataFromInvestmentId(investmentId).roi;
    if (roi !== "Unknown") {
      return (amountInvested * roi) / 100;
    } else {
      return "Unknown";
    }
  };

  const getTotalProfit = (duration, profit) => {
    return duration * profit;
  };
  

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Investors
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Investor</p>
          </div>
        </div>
      </div>

      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

      
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full whitespace-nowrap text-sm text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-center">Investor Name</th>

                <th className="px-4 py-3 text-center">Investment</th>
                <th className="px-4 py-3 text-center">Investment Image</th>
                <th className="px-4 py-3 text-center">Amount Invested</th>
                <th className="px-4 py-3 text-center">Roi %</th>
                <th className="px-4 py-3 text-center">Duration</th>
                <th className="px-4 py-3 text-center">Profit[Monthly]</th>
                <th className="px-4 py-3 text-center">Total Profit</th>
                <th className="px-4 py-3 text-center">Total Payout</th>
                <th className="px-4 py-3 text-center">Start Date</th>
                <th className="px-4 py-3 text-center">Stop Date</th>
              </tr>
            </thead>
            <tbody className="w-fit">
            {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) :status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentInvestorInvestments.length === 0 ? (
                <tr>
                  <td
                    colSpan="12"
                    className="text-center text-base text-gray-500 py-4"
                  >
                    No Data
                  </td>
                </tr>
              ) : (
                currentInvestorInvestments?.map((order, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap text-center">
                      <div className="flex flex-col">
                        <p className="px-4 py-1 text-sm font-semibold text-gray-800">
                          {order.username}
                        </p>
                        <p className="px-4 py-1 text-[12px]">
                          {getUserDataFromUserId(order.user_id).email}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-center">
                      <p className="text-sm font-semibold text-gray-500 whitespace-nowrap">
                        {order.investment_name}
                      </p>
                    </td>
                    <td className="px-4 py-4 font-medium whitespace-nowrap flex justify-center items-center">
                      <div className="bg-[#E0E2E7] rounded-lg">
                        <img
                          src={order.investment_image}
                          alt={order.investment_name}
                          className="w-16 h-16 object-cover rounded-lg"
                        />
                      </div>
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(order.amountInvested)}
                      </p>
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        {
                          getInvestmentDataFromInvestmentId(order.investment_id)
                            .roi
                        }
                      </p>
                    </td>
                    <td className="px-4 py-2 text-center">{order.duration}</td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        <span className="mr-1">&#8358;</span>{" "}
                        {formatNumberWithCommas(calculateProfit(
                          order.investment_id,
                          order.amountInvested
                        ))}
                      </p>
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        <span className="mr-1">&#8358;</span>{" "}
                        {formatNumberWithCommas(
                          getTotalProfit(
                          order.duration,
                          calculateProfit(
                            order.investment_id,
                            order.amountInvested
                          )
                        ))}
                      </p>
                    </td>
                    <td className="px-4 py-2 text-center">
                      <p className="text-[14px] text-[#667085] font-medium tracking-[0.07px]">
                        <span className="mr-1">&#8358;</span>
                        {formatNumberWithCommas(order.amountToBeReceived)}
                      </p>
                    </td>

                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.created_at)}
                    </td>
                    <td className="px-4 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(order.stop_date)}
                    </td>

                    {/* <td className="px-4 py-4">
                      <div className=" flex justify-center items-center">
                        <span>
                          <FaEye />
                        </span>
                      </div>
                    </td> */}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {investorInvestments.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorList;
