import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { CgTrack } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { trackOngoingOrder } from "../../../redux/slices/trackSlice";
import { pushNotifyUser } from "../../../redux/slices/pushSlice";
import { fetchItemReceived } from "../../../redux/slices/itemReceivedSlice";

export default function ModalItemReceived({ orderId, name, userId }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const status = useSelector((state) => state.track?.status);
  const error = useSelector((state) => state.track?.error);

  if (status === "failed") {
    return <div>Error {error}</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await dispatch(trackOngoingOrder(orderId));
      if (res.payload) {
        const pushNote = {
          user_id: [+userId,],
          order_id: orderId,
          title: "Capfields Evolve",
          body: `Your order with id #${orderId} is currently ongoing.`,
          click: "LAUNDRY",
        };

        dispatch(pushNotifyUser(pushNote));
        await dispatch(fetchItemReceived());
        toast.success(res.payload.message);
        setShowModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center cursor-pointer">
        <button type="button" onClick={() => setShowModal(true)}>
          <CgTrack className=" text-green-600 text-3xl font-semibold " />
        </button>
      </div>
      {showModal && (
        <>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-6 ">
                    <h1 className="text-lg text-gray-800 mb-6 text-center font-semibold ">
                      Laundry Item Received Tracker
                    </h1>
                    <div className="flex justify-between items-center mb-2">
                      <h1 className="text-[14px] text-gray-500">Name</h1>
                      <p className=" text-base text-gray-700 font-semibold">
                        {name}
                      </p>
                    </div>
                    <p className="text-center">{orderId}</p>
                    <div className=" w-full flex flex-row gap-6">
                      <div className=" flex flex-col m-auto">
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-2">
                            <input type="checkbox" checked readOnly />
                            <label htmlFor="receivesOrder">
                              Move to Ongoing Order
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Updating ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Done
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
