import React, { useEffect } from "react";

import { HiChevronRight } from "react-icons/hi";

import { useDispatch, useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";

import { ImSpinner2 } from "react-icons/im";

import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import {
  fetchProductDetail,
  selectProductDetail,
} from "../../redux/slices/productSlice";

const ProductDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const status = useSelector((state) => state.product?.productDetailStatus);
  const error = useSelector((state) => state.product?.error);

  useEffect(() => {
    dispatch(fetchProductDetail(id));
  }, [dispatch, id]);

  const productDetail = useSelector(selectProductDetail);
  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);
    const state = states.find((state) => state.id === parsedId);
    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Product Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/shopping/products">
              <p className="text-[14px] text-[#1F7BF4]  font-medium">Product</p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Product Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {productDetail ? (
            <div className="flex mt-8 gap-6">
              <div className="flex flex-col gap-6 w-5/12">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] whitespace-nowrap font-semibold tracking-[0.09px]">
                      Product Information
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] whitespace-nowrap font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>

                        <p className=" text-base w-48 break-words text-right">
                          {productDetail.data.product.name}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Category
                          </p>
                        </div>

                        <p className=" text-base   whitespace-nowrap">
                          {productDetail.data.product.category}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex gap-2 text-left">
                          <p className="text-[14px]  mb-2 text-[#1A1C21] font-medium tracking-[0.07px]">
                            Description
                          </p>
                        </div>
                        <textarea
                          name=""
                          id=""
                          cols="10"
                          rows="5"
                          value={productDetail.data.product.description}
                          className=" w-full rounded-lg p-2 border-2"
                        ></textarea>
                      </div>
                      {productDetail.data.product.color ? (
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Color
                            </p>
                          </div>
                          <p className=" text-base   whitespace-nowrap">
                            {productDetail.data.product.color.join(",")}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {productDetail.data.product.size ? (
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Size
                            </p>
                          </div>
                          <p className=" text-base   whitespace-nowrap">
                            {productDetail.data.product.size.join(",")}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Owner Id
                          </p>
                        </div>

                        <p className=" text-base   whitespace-nowrap">
                          {productDetail.data.product.owner_id}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Owner Type
                          </p>
                        </div>

                        <p className=" text-base   whitespace-nowrap">
                          {productDetail.data.product.owner_type}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Price
                          </p>
                        </div>

                        <p className=" text-base whitespace-nowrap">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            productDetail.data.product.price
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Discount
                          </p>
                        </div>

                        <p className=" text-base whitespace-nowrap">
                          {productDetail.data.product.discount
                            ? productDetail.data.product.discount
                            : 0}
                          %
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Discount Price
                          </p>
                        </div>

                        <p className=" text-base whitespace-nowrap">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            productDetail.data.product.discount_price
                          )}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Type
                          </p>
                        </div>

                        <p className=" text-base   whitespace-nowrap">
                          {productDetail.data.product.type
                            ? productDetail.data.product.type
                            : "Not Available"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            City
                          </p>
                        </div>
                        {productDetail.data.product.city ? (
                          <p className=" text-base   whitespace-nowrap">
                            {getCityNameById(productDetail.data.product.city)}
                          </p>
                        ) : (
                          "No data"
                        )}
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            State
                          </p>
                        </div>
                        {productDetail.data.product.state ? (
                          <p className=" text-base   whitespace-nowrap">
                            {getStateNameById(productDetail.data.product.state)}
                          </p>
                        ) : (
                          "No data"
                        )}
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Country
                          </p>
                        </div>
                        {productDetail.data.product.country ? (
                          <p className=" text-base   whitespace-nowrap">
                            {getCountryNameById(
                              productDetail.data.product.country
                            )}
                          </p>
                        ) : (
                          "No data"
                        )}
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Created
                          </p>
                        </div>
                        <p className="text-base whitespace-nowrap">
                          {formatCreatedAt(
                            productDetail.data.product.created_at
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Updated
                          </p>
                        </div>
                        <p className="text-base  whitespace-nowrap">
                          {formatCreatedAt(
                            productDetail.data.product.updated_at
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 w-7/12">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className=" flex flex-wrap gap-2">
                      {productDetail.data.product.image &&
                        productDetail.data.product.image.map(
                          (imgUrl, index) =>
                            imgUrl && (
                              <img
                                key={index}
                                src={imgUrl}
                                alt={`${productDetail.data.product.name} ${
                                  index + 1
                                }`}
                                className="ring-2 ring-gray-50 rounded-lg mb-4 max-w-full h-auto object-contain"
                                style={{ maxWidth: "48%", height: "150px" }}
                              />
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "null"
          )}
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
