import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductSubs } from "../../redux/slices/productSub";
import { ImSpinner2 } from "react-icons/im";
import ModalAddProductSub from "../../components/modals/product/AddProductSub";
import ModalUpdateProductSub from "../../components/modals/product/UpdateProductSub";
import ModalDeleteProductSub from "../../components/modals/product/DeleteProductSub";
import { formatCreatedAt, formatNumberWithCommas } from "../../utils/commonUtils";

const ProductSub = () => {
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const productSubs = useSelector((state) => state.productSub?.productSubs);

  const reversedProductSubs = productSubs.slice().reverse();

  // console.log(productSubs);

  const status = useSelector((state) => state.productSub?.status);
  const error = useSelector((state) => state.productSub?.error);

  useEffect(() => {
    dispatch(fetchProductSubs());
  }, [dispatch]);



  const handlePageClick = (page) => {
    setActivePage(page);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
           Shopping Subscriptions
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Shopping Subscriptions
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-4 mt-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

        <div className="flex items-center justify-center rounded gap-4">
          <ModalAddProductSub />
        </div>
      </div>
      <div className="bg-white rounded-lg mt-4 w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-center text-gray-500">
            <thead className="w-full text-sm whitespace-nowrap text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-center">Name</th>
                <th className="px-4 py-3 text-center">Cost</th>
                <th className="px-4 py-3 text-center">Shopping Cost</th>
                <th className="px-4 py-3 text-center">No. of Shopping</th>

                <th className="px-4 py-3 text-center">No. of Deliveries</th>
                <th className="px-4 py-3 text-center">Created</th>

                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="w-fit">
            {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : reversedProductSubs.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                reversedProductSubs
                  ?.filter((order) => {
                    return search.toLowerCase() === ""
                      ? order
                      : order.name.toLowerCase().includes(search);
                  })
                  ?.map((order, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b whitespace-nowrap"
                    >
                      <td className="px-4 py-4">
                        <div className="flex justify-center items-center">
                          <p className=" text-base text-center font-semibold ">
                            {index + 1}
                          </p>
                        </div>
                      </td>

                      <td className="px-4 py-2 text-center">
                        <p className=" font-semibold">{order.name}</p>
                      </td>

                      <td className="px-4 py-2 text-center">
                        <p>
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(order.cost)}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p>
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(order.shopping_cost)}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p>
                         
                          {order.shopping_fee}
                        </p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p>{order.delivery}</p>
                      </td>
                      <td className="px-4 py-2 text-center">
                        <p>{formatCreatedAt(order.created_at)}</p>
                      </td>

                      <td className="px-2 py-4">
                        <div className="flex gap-4 justify-center items-center">
                          <div>
                            <ModalUpdateProductSub
                              id={order.id}
                              name={order.name}
                              cost={order.cost}
                              shopping_cost={order.shopping_cost}
                              shopping_fee={order.shopping_fee}
                              delivery={order.delivery}
                            />
                          </div>

                          <div>
                            <ModalDeleteProductSub
                              id={order.id}
                              name={order.name}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing 1 - 1 from 1
          </p>

          <div className="flex gap-[8px]">
            <button
              className="bg-[#DEDEFA] w-[32px] h-[32px] flex items-center justify-center rounded-[8px] hover:bg-[#5C59E8] text-[#5C59E8] hover:text-white"
              onClick={() =>
                handlePageClick(activePage > 1 ? activePage - 1 : 1)
              }
            >
              <IoChevronBack className="w-4 h-4" />
            </button>

            {[1].map((page) => (
              <button
                key={page}
                type="button"
                className={`${
                  page === activePage
                    ? "bg-[#1F7BF4] text-white"
                    : "bg-[#DEDEFA] text-[#1F7BF4]"
                } w-[32px] h-[32px] rounded-[8px] flex items-center justify-center hover:bg-[#1F7BF4] hover:text-white`}
                onClick={() => handlePageClick(page)}
              >
                {page}
              </button>
            ))}

            <button
              className="bg-[#DEDEFA] w-[32px] h-[32px] rounded-[8px] flex items-center justify-center text-[#1F7BF4] hover:bg-[#1F7BF4] hover:text-white"
              onClick={() => handlePageClick(activePage + 1)}
            >
              <IoChevronForward className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSub;
