// orderCancelledSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchOrderCancelleds = createAsyncThunk(
  "orderCancelled/fetchOrderCancelleds",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/admin/orders/canceled");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSingleOrder = createAsyncThunk(
  "singleOrderDetail/fetchSingleOrder",
  async (orderId, { getState }) => {
    try {
      const token = localStorage.getItem("accessToken");

      const response = await apiService.get(`/products/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data; // Assuming the data you need is nested under 'data'
    } catch (error) {
      throw new Error("Failed to fetch single order");
    }
  }
);

const orderCancelledSlice = createSlice({
  name: "orderCancelled",
  initialState: {
    orderCancelleds: [],
    status: "idle",
    error: null,
    singleOrderOngoing: null, // Add singleOrder property here
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderCancelleds.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchOrderCancelleds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orderCancelleds = action.payload.data;
      })
      .addCase(fetchOrderCancelleds.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch orders";
      })
      .addCase(fetchSingleOrder.pending, (state) => {
        state.status = "failed";
        state.error = null;
      })
      .addCase(fetchSingleOrder.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleOrderOngoing = action.payload; // Set singleOrder directly
      })
      .addCase(fetchSingleOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch single order";
      });
  },
});

export default orderCancelledSlice.reducer;
