import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";

import { Link } from "react-router-dom";

import { formatCreatedAt } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";

import { ImSpinner2 } from "react-icons/im";
import { fetchPromotions } from "../../redux/slices/promotionsSlice";
import { fetchUsers } from "../../redux/slices/userSlice";

const UserPromotionHistory = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPromotions());
    dispatch(fetchUsers());
  }, [dispatch]);

  const promotions = useSelector((state) => state.promotion?.promotions);

  const reversePromotions = promotions?.slice()?.reverse();

  const status = useSelector((state) => state.promotion?.status);
  const error = useSelector((state) => state.promotion?.error);

  const usersList = useSelector((state) => state.user?.users);

  const getUserNameFromId = (userId) => {
    const user = usersList?.find((user) => user.id === parseInt(userId, 10));
    if (!user) {
      return <span>Unknown</span>;
    }
  
    return (
      <div className="flex flex-col gap-2">
        <span className="font-bold">{user.firstname} {user.lastname}</span>
        <span className="text-gray-500"> ({user.email})</span>
      </div>
    );
  };
 

 
  const filteredPromotions = reversePromotions?.filter((pro) => {
    const user = usersList?.find((user) => user.id === parseInt(pro.user_id, 10));
    const userName = user ? `${user.firstname} ${user.lastname}`.toLowerCase() : "";
    return userName.includes(search.toLowerCase());
  });

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentPromotions = filteredPromotions?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredPromotions?.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Promotion History
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Users</p>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">
              Promotion History
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search ..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-2 py-3 text-center">Users</th>
                <th className="px-2 py-3 text-center ">Message</th>
                <th className="px-2 py-3 text-center">Created</th>
                <th className="px-2 py-3 text-center">Updated</th>
                <th className="px-2 py-3 text-center">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentPromotions?.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentPromotions?.map((pro, index) => (
                  <tr key={pro.id}>
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>

                    <td className="px-4 py-4 text-center">
                      <p>{getUserNameFromId(pro.user_id)}</p>
                    </td>

                    <td className="px-4 py-4 text-center">
                      <textarea
                        name=""
                        id=""
                        cols="20"
                        rows="3"
                        readOnly
                        value={pro.message}
                        className=" text-[#1A1C21] text-base border-2 rounded-lg p-2 tracking-[0.07px]"
                      ></textarea>
                    </td>

                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(pro.created_at)}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      {formatCreatedAt(pro.updated_at)}
                    </td>
                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center">
                        <div>
                          <IoEyeOutline className="text-base text-[#667085]" />
                          {/* <Link to={`/dashboard/user/user-detail/${pro.id}`}>
                        </Link> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {promotions?.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPromotionHistory;
