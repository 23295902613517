import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchPayment = createAsyncThunk(
  "payment/fetchPayment",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/payments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTotalBalance = createAsyncThunk(
  "payment/fetchTotalBalance",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/total-balance", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCompletedPayment = createAsyncThunk(
  "payment/fetchCompletedPayment",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/completed/payments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approvePayment = createAsyncThunk(
  "payment/approvePayment",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(`/approve/payment/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data; //
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const approvePaymentManually = createAsyncThunk(
  "payment/approvePaymentManually",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        `/approve/payment/manually/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; //
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTopupWallet = createAsyncThunk(
  "payment/addTopupWallet",
  async (updatedFormData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/wallet/top-up",
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deductWallet = createAsyncThunk(
  "payment/deductWallet",
  async (updatedFormData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "admin/wallet/deduct",
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addShoppingCoupon = createAsyncThunk(
  "payment/addShoppingCoupon",
  async (updatedFormData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/shopping-coupon",
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLaundryCoupon = createAsyncThunk(
  "payment/addLaundryCoupon",
  async (updatedFormData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/laundry-coupon",
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addIsusuCoupon = createAsyncThunk(
  "payment/addIsusuCoupon",
  async (updatedFormData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/admin/isusu-coupon",
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const paywithPaystack = createAsyncThunk(
  "payment/paywithPaystack",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/lock-paystack", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payments: [],
    totalBalance: null,
    status: "idle",
    error: null,
    completedPayments: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPayment.fulfilled, (state, action) => {
        state.status = "success";
        state.payments = action.payload.data;
      })
      .addCase(fetchPayment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTotalBalance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTotalBalance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalBalance = action.payload;
      })
      .addCase(fetchTotalBalance.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchCompletedPayment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCompletedPayment.fulfilled, (state, action) => {
        state.status = "success";
        state.completedPayments = action.payload.data;
      })
      .addCase(fetchCompletedPayment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(approvePayment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(approvePayment.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(approvePayment.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(approvePaymentManually.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(approvePaymentManually.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(approvePaymentManually.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(addTopupWallet.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addTopupWallet.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addTopupWallet.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(deductWallet.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deductWallet.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(deductWallet.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(addShoppingCoupon.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addShoppingCoupon.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addShoppingCoupon.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(addLaundryCoupon.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addLaundryCoupon.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addLaundryCoupon.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(addIsusuCoupon.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addIsusuCoupon.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addIsusuCoupon.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(paywithPaystack.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(paywithPaystack.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(paywithPaystack.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      });
  },
});

export default paymentSlice.reducer;
