import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchLaundryStore = createAsyncThunk(
  "laundryStore/fetchLaundryStore",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/laundries", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addLaundryStore = createAsyncThunk(
  "laundryStore/addLaundryStore",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create/laundry", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateLaundryStore = createAsyncThunk(
  "laundryStore/updateLaundryStore",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.put(
        `/update/laundry/${id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLaundryStore = createAsyncThunk(
  "laundryStore/deleteLaundryStore",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(`/delete/laundry/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const fetchLaundryStoreDetail = createAsyncThunk(
  "laundryStore/fetchLaundryStoreDetail",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(`/laundry/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const laundryStoreSlice = createSlice({
  name: "laundryStore",
  initialState: {
    laundryStores: [],
    status: "idle",
    error: null,
    laundryStoreDetail: null,
    laundryStoreDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLaundryStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchLaundryStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundryStores = action.payload.data;
      })
      .addCase(fetchLaundryStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addLaundryStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addLaundryStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laundryStores.unshift(action.payload);
      })
      .addCase(addLaundryStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error
          ? action.error
          : "Failed to add Laudry Store, Network Error";
      })
      .addCase(updateLaundryStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateLaundryStore.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedLaundry = action.payload;

        state.laundryStores = state.laundryStores.map((laundry) =>
          laundry.id === updatedLaundry.id ? updatedLaundry : laundry
        );
      })

      .addCase(updateLaundryStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to update laundry";
      })

      .addCase(deleteLaundryStore.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteLaundryStore.fulfilled, (state, action) => {
        const deletedLaundryId = action.payload;

        state.status = "succeeded";
        state.laundryStores =
          state.laundryStores?.filter(
            (laundry) => laundry.id !== deletedLaundryId
          ) ?? [];
      })
      .addCase(deleteLaundryStore.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete laundry";
      })
      .addCase(fetchLaundryStoreDetail.pending, (state) => {
        state.laundryStoreDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchLaundryStoreDetail.fulfilled, (state, action) => {
        state.laundryStoreDetailStatus = "succeeded";
        state.laundryStoreDetail = action.payload;
      })
      .addCase(fetchLaundryStoreDetail.rejected, (state, action) => {
        state.laundryStoreDetailStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectLaundryStoreDetail = (state) =>
  state.laundryStore?.laundryStoreDetail;

export default laundryStoreSlice.reducer;
