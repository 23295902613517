import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchbalanceDetail = createAsyncThunk(
  "balanceDetail/fetchbalanceDetail",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/balance-details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const balanceDetailsSlice = createSlice({
  name: "balanceDetail",
  initialState: {
    balanceDetails: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchbalanceDetail.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchbalanceDetail.fulfilled, (state, action) => {
        state.status = "success";
        state.balanceDetails = action.payload.data;
      })
      .addCase(fetchbalanceDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default balanceDetailsSlice.reducer;
