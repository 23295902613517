import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { updateDuplicateProduct } from "../../../redux/slices/productSlice";
import { fetchStore } from "../../../redux/slices/storeSlice";

export default function UpdateDuplicateProductStore({
  owner_id,
  clearSelectedProductIds,
  selectedProductIds,
  city,
  state,
  country,
}) {
  const [showModal, setShowModal] = useState(false);
  // const [ownerId, setOwnerId] = useState("");

  const dispatch = useDispatch();
  const ownerId = owner_id;
  const ownerType = "store";

  useEffect(() => {
    dispatch(fetchStore());
  }, [dispatch]);

  // const markets = useSelector((state) => state.market?.markets);

  const status = useSelector((state) => state.product?.status);

  const initialFormData = {
    product_ids: selectedProductIds,
    city,
    state,
    country,
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  // Update formData when selectedProductIds changes
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      product_ids: selectedProductIds,
    }));
  }, [selectedProductIds]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const res = await dispatch(
        updateDuplicateProduct({
          ownerType,
          ownerId,
          formData,
        })
      );
      clearSelectedProductIds();
      setFormData({ ...initialFormData });
      toast.success(res.payload.data.message);
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  // Filtering markets to exclude the current market
  // const filteredMarkets = markets?.filter((market) => {
  //   return String(market.id) !== String(fromOwnerId);
  // });

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-blue-200 text-blue-600 items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        Update Duplicated
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Update Duplicated
                  </h1>
                </div>
                {/* <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[1px]">
                    <label
                      htmlFor="location"
                      className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
                    >
                      Market
                    </label>
                    <select
                      name="market"
                      id="market"
                      className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      onChange={(e) => setOwnerId(e.target.value)}
                    >
                      <option className="text-[#333843]" value="">
                        Select Market
                      </option>
                      {filteredMarkets?.map((market) => (
                        <option key={market.id} value={market.id}>
                          {market.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex mt-8 gap-4 justify-center">
                    <button
                      onClick={() => setShowModal(false)}
                      className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                      disabled={status === "loading"}
                      onClick={handleSubmit}
                    >
                      {status === "loading" ? (
                        <>
                          <ImSpinner2 className="animate-spin" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Duplicating...
                          </p>
                        </>
                      ) : (
                        <>
                          <FaTimes className="w-4 h-4" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Duplicate
                          </p>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
