import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { fetchFeeSetting } from "../../redux/slices/feeSettingSlice";
import { useDispatch } from "react-redux";

const FeeSetting = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFeeSetting());
  }, [dispatch]);
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default FeeSetting;
