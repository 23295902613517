import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const addDropoff = createAsyncThunk(
  "dropoff/addDropoff",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create/dropoff", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDropoff = createAsyncThunk(
  "dropoff/updateDropoff",
  async ({ dropoffId, updateData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const id = dropoffId
      const response = await apiService.put(`/update/dropoff/${id}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDropoff = createAsyncThunk(
  "dropoff/deleteDropoff",
  async (dropoffId, { rejectWithValue }) => {
    try {
      const id = dropoffId

      const token = localStorage.getItem("token");
      const res = await apiService.delete(`/delete/dropoff/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error.res.data);
    }
  }
);

const dropoffSlice = createSlice({
  name: "dropoff",
  initialState: {
    dropoffs: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addDropoff.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addDropoff.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dropoffs.unshift(action.payload);
      })
      .addCase(addDropoff.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      .addCase(updateDropoff.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateDropoff.fulfilled, (state, action) => {
        state.status = "success";
        state.dropoffs = state.dropoffs?.map((dropoff) =>
          dropoff.id === action.payload.id ? action.payload : dropoff
        );
      })
      .addCase(updateDropoff.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload.message;
      })
      .addCase(deleteDropoff.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteDropoff.fulfilled, (state, action) => {
        state.status = "success";
        const dropoffId = action.payload;
        state.dropoffs = state.dropoffs.filter((df) => df.id !== dropoffId);
      })
      .addCase(deleteDropoff.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })
  },
});

export default dropoffSlice.reducer;
