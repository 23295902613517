import React, { useEffect } from "react";

import { HiChevronRight } from "react-icons/hi";
import {
  IoMdCall,
  IoMdCar,
  IoMdCube,
  IoMdMail,
  IoMdPerson,
} from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchAdminDetail,
  selectAdminDetail,
} from "../../redux/slices/adminSlice";
import { ImSpinner2 } from "react-icons/im";
import { formatCreatedAt } from "../../utils/commonUtils";
import { FaCity } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";

const AdminDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdminDetail(id));
  }, [dispatch, id]);

  const adminDetail = useSelector(selectAdminDetail);
  const countries = useSelector((state) => state.country?.countries);
  const states = useSelector((state) => state.state?.states);
  const provinces = useSelector((state) => state.province?.provinces);

  // console.log(adminDetail);

  const status = useSelector((state) => state.admin?.adminDetailStatus);
  const error = useSelector((state) => state.admin?.error);
  if (status === "failed") {
    return <div>Error. {error}</div>;
  }

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  return (
    <div className=" p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Admin Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/admin">
              <p className="text-[14px] text-[#1F7BF4] aad font-medium">
                Admin
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">
              Admin Detail
            </p>
          </div>
        </div>
      </div>

      {status === "loading" ? (
        <div className="m-auto">
          <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
        </div>
      ) : (
        <div>
          {adminDetail ? (
            <>
              {/* <div className="flex flex-col mt-6 md:flex-row justify-start items-center">
                <div className="flex justify-start">
                  <img
                    src="https://picsum.photos/40/40?"
                    alt="User"
                    className="w-80 h-80 rounded-lg"
                  />
                </div>
              </div> */}

              <div className="mt-8 flex flex-row gap-4">
                <div className="flex flex-col gap-[24px] w-1/2 h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[24px]  h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      Admin Detail
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Full Name
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium w-56 break-words text-right tracking-[0.07px]">
                          {adminDetail.data.name}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdMail size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Email
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {adminDetail.data.email}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCall size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Phone
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {adminDetail.data.phone}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <BiWorld size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Country
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {getCountryNameById(adminDetail.data.country)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCube size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            State
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {getStateNameById(adminDetail.data.state)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaCity size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            City
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {getCityNameById(adminDetail.data.city)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCube size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Created
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(adminDetail.data.created_at)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCube size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Updated
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(adminDetail.data.updated_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-[24px] w-1/2 h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      Admin ID
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Admin ID
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {adminDetail.data.id}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCar size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Role
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium  tracking-[0.07px] w-56 break-words text-right">
                          {adminDetail.data.role
                            ? adminDetail.data.role
                            : "No role"}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex justify-center items-center gap-2">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCube size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            Functions
                          </p>
                        </div>
                        {adminDetail.data.role === "Super Admin" ? (
                          "All Functions"
                        ) : (
                          <div className="flex flex-col">
                            {Array.isArray(adminDetail.data.functions) &&
                            adminDetail.data.functions.length > 0 ? (
                              <div className="text-left">
                                {adminDetail.data.functions.map(
                                  (func, index) => (
                                    <p
                                      key={index}
                                      className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]"
                                    >
                                      {func}
                                      {index !==
                                        adminDetail.data.functions.length - 1 &&
                                        ", "}
                                    </p>
                                  )
                                )}
                              </div>
                            ) : (
                              <p className="text-left text-gray-500">
                                No functions available
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            "null"
          )}
        </div>
      )}
    </div>
  );
};

export default AdminDetail;
