import React, { useState } from "react";
import { BsImage } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

const UploadImage = ({ onUpload }) => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [prevImageURL, setPrevImageURL] = useState(null);

  const handleImageUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      if (imageFile) {
        // Save current image URL as previous image URL before updating
        setPrevImageURL(URL.createObjectURL(imageFile));
      }
      setImageFile(file);
      onUpload(file);
    }
    setLoading(false);
  };

  return (
    <div className="flex items-center justify-center w-full">
      <label
        htmlFor="image"
        className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
      >
        {imageFile ? (
          <img
            src={URL.createObjectURL(imageFile)}
            alt="Uploaded Preview"
            className="h-40 w-40 object-cover rounded-lg"
          />
        ) : prevImageURL ? (
          <img
            src={prevImageURL}
            alt="Previous Upload"
            className="h-40 w-40 object-cover rounded-lg"
          />
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6 text-center">
            <BsImage size={28} color="#1F7BF4" />
            <p className="mb-2 p-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and
              drop a picture here
            </p>
            {loading && <FaSpinner className="animate-spin" />}
          </div>
        )}
        <input
          id="image"
          onChange={handleImageUpload}
          type="file"
          accept="image/*"
          className="hidden"
        />
      </label>
    </div>
  );
};

export default UploadImage;
