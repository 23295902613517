import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { fetchRole } from "../../redux/slices/rolesSlice";
import { useDispatch } from "react-redux";

const Admin = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRole());
  }, [dispatch]);
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Admin;
