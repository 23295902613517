import React, { useEffect } from "react";

import { HiChevronRight } from "react-icons/hi";
import { IoHomeOutline, IoWalletOutline } from "react-icons/io5";

import { Link, useParams } from "react-router-dom";
import {
  fetchUserDetail,
  selectUserDetail,
} from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { ImSpinner2 } from "react-icons/im";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { IoMdCall, IoMdMail, IoMdPerson } from "react-icons/io";

const OrderCartDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserDetail(id));
  }, [dispatch, id]);

  const userDetail = useSelector(selectUserDetail);

  const status = useSelector((state) => state.user?.userDetailStatus);
  const error = useSelector((state) => state.user?.error);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Cart Order Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/orders/carts-orders">
              <p className="text-[14px] text-[#1F7BF4] font-medium">
                Cart Order
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Cart Order Detail
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <div className="m-auto">
          <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
        </div>
      ) : (
        <div>
          {userDetail ? (
            <div class="flex flex-col mt-8 gap-6">
              <div class="flex flex-row gap-6">
                <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-row justify-start gap-4">
                    <div className="flex flex-col w-full gap-4 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-2xl text-[#1A1C21] font-medium tracking-[0.09px]">
                        Wallet
                      </h1>
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2 justify-start">
                          <div className="bg-green-100 w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-green-200 w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoWalletOutline className=" text-2xl font-extrabold text-green-600" />
                            </div>
                          </div>
                          <p className="text-[14px] text-gray-500 font-medium tracking-[0.07px]">
                            Cash in wallet
                          </p>
                        </div>
                        <p className="text-3xl mt-4 text-start text-[#257443] font-bold">
                          <span className="mr-1">&#8358;</span>
                          {formatNumberWithCommas(
                            userDetail.data.wallet_balance
                              ? userDetail.data.wallet_balance
                              : "0"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-8 h-auto px-2 py-2 bg-white rounded-lg shadow">
                      <div className=" flex justify-between p-2 items-center">
                        {userDetail.data.user.image ? (
                          <img
                            src={userDetail.data.user.image}
                            alt={userDetail.data.user.firstname}
                            className=" w-full max-h-[200px] p-2 object-contain rounded-lg ring-2 ring-slate-100"
                          />
                        ) : (
                          <div className="flex items-center justify-center h-full">
                            <p className="text-center">No Image</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col gap-6">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoMdPerson size={20} color="#667085" />
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          Name
                        </p>
                      </div>
                      <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                        {`${userDetail.data.user.firstname} ${userDetail.data.user.lastname}`}
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoMdMail size={20} color="#667085" />
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          Email
                        </p>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                        {userDetail.data.user.email}
                      </p>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoMdCall size={20} color="#667085" />
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          Phone
                        </p>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                        {userDetail.data.user.phone}
                      </p>
                    </div>

                    <div className="flex justify-between items-center gap-2">
                      <div className="flex gap-2 items-center">
                        <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                          <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <IoHomeOutline size={20} color="#667085" />
                          </div>
                        </div>

                        <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                          Address
                        </p>
                      </div>
                      <p className="text-[14px] text-[#1A1C21] break-words w-48 text-right font-medium tracking-[0.07px]">
                        {userDetail.data.user.address
                          ? userDetail.data.user.address
                          : "No Info"}
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div>
                <div className=" w-full mt-4">
                  <div className="flex flex-col w-full gap-[18px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex gap-2">
                      <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                        Cart Details
                      </h1>
                      <p className="bg-[#E7F4EE] text-[#0D894F] px-[12px] text-[13px] py-[4px] flex justify-center rounded-lg items-center font-semibold">
                        {userDetail?.data?.cart.length} Order
                      </p>
                    </div>
                    <div className="relative overflow-x-auto p-6 rounded-lg ">
                      <table className="w-full text-center text-gray-500">
                        <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
                          <tr>
                            <th className="px-6 py-3">No.</th>
                            <th className="px-6 py-3">Name</th>
                            <th className="px-6 py-3">Image</th>
                            <th className="px-6 py-3">Price</th>
                            <th className="px-6 py-3">Created </th>
                            <th className="px-6 py-3">Updated</th>
                          </tr>
                        </thead>

                        <tbody>
                          {userDetail?.data?.cart.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center text-gray-500 py-4"
                              >
                                No Cart Order
                              </td>
                            </tr>
                          ) : (
                            userDetail?.data?.cart?.map((order, index) => (
                              <tr
                                key={order.id}
                                className="bg-white "
                              >
                                <td className="px-6 py-4 text-[14px] ">
                                  <div className="flex justify-center items-center">
                                    <p className=" text-base text-center font-semibold ">
                                      {index + 1}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-6 py-4 text-[14px] w-48 break-words">
                                  {order.name}
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <div className="flex justify-center items-center">
                                    {order.image ? (
                                      <img
                                        src={
                                          order.image && order.image.length > 0
                                            ? order.image[0]
                                            : "default-image-url.jpg"
                                        }
                                        alt={order.name}
                                        className="w-20 h-20 object-contain rounded-2xl"
                                      />
                                    ) : (
                                      "No Image"
                                    )}
                                  </div>
                                </td>

                                <td className="px-6 py-4">
                                  <span className="mr-1">&#8358;</span>
                                  {order.price}
                                </td>
                                <td className="px-6 py-4 text-[14px]  ">
                                  {formatCreatedAt(order.created_at)}
                                </td>
                                <td className="px-6 py-3">
                                  {formatCreatedAt(order.updated_at)}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "null"
          )}
        </div>
      )}
    </div>
  );
};

export default OrderCartDetail;
