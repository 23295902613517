import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get("/admin/products", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addProduct = createAsyncThunk(
  "product/addProduct",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/create/product", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchProduct = createAsyncThunk(
  "product/searchProduct",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/search-products", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const allProduct = createAsyncThunk(
  "product/allProduct",
  async ({ id, owner_type }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(
        `/all-products/${id}/${owner_type}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPriceFeedback = createAsyncThunk(
  "product/fetchPriceFeedback",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/feedbacks", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (pId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const id = pId;
      const response = await apiService.delete(`/product/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const duplicateProduct = createAsyncThunk(
  "product/duplicateProduct",
  async (
    { fromOwnerType, fromOwnerId, toOwnerType, toOwnerId, formData },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(
        `/duplicate-products/${fromOwnerType}/${fromOwnerId}/${toOwnerType}/${toOwnerId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateDuplicateProduct = createAsyncThunk(
  "product/updateDuplicateProduct",
  async ({ ownerType, ownerId, formData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(
        `/update-duplicated-products/${ownerType}/${ownerId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ pId, formData }, { rejectWithValue }) => {
    try {
      const id = pId;
      const token = localStorage.getItem("token");
      const response = await apiService.put(`/product/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "applications/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductDetail = createAsyncThunk(
  "product/fetchProductDetail ",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.get(`/product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addDiscountPrice = createAsyncThunk(
  "product/addDiscountPrice",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(
        "/product/discount-price",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addIncrementPrice = createAsyncThunk(
  "product/addIncrementPrice",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(
        "/product/increment-price",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const outofStock = createAsyncThunk(
  "product/outofStock",
  async (pId, { rejectWithValue }) => {
    const id = pId;
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.post(`/out_of_stock/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    allProducts: [],
    searchResults: [],
    feedbacks: [],
    status: "idle",
    error: null,
    productDetail: null,
    productDetailStatus: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload.data;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchPriceFeedback.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPriceFeedback.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feedbacks = action.payload.data;
      })
      .addCase(fetchPriceFeedback.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(addProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.products.unshift(action.payload);
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(searchProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(searchProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.searchResults = action.payload.products; // Adjust based on API response
      })
      .addCase(searchProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        const product = action.payload;
        state.products = state.products?.map((prod) =>
          prod.id === product.id ? product : prod
        );
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(deleteProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.status = "success";
        state.products = state.products.filter(
          (product) => product.id !== action.payload.id
        );
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to delete product";
      })
      .addCase(fetchProductDetail.pending, (state) => {
        state.productDetailStatus = "loading";
        state.error = null;
      })
      .addCase(fetchProductDetail.fulfilled, (state, action) => {
        state.productDetailStatus = "succeeded";
        state.productDetail = action.payload;
      })
      .addCase(fetchProductDetail.rejected, (state, action) => {
        state.productDetailStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(duplicateProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(duplicateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.payload = action.payload;
      })
      .addCase(duplicateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateDuplicateProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateDuplicateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.payload = action.payload;
      })
      .addCase(updateDuplicateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addDiscountPrice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addDiscountPrice.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addDiscountPrice.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(addIncrementPrice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addIncrementPrice.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(addIncrementPrice.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(allProduct.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(allProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allProducts = action.payload.data;
      })
      .addCase(allProduct.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(outofStock.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(outofStock.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(outofStock.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });
  },
});

export const selectProductDetail = (state) => state.product?.productDetail;

export default productSlice.reducer;
