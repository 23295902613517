import React from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import InvestmentProjectSetting from "../../components/feesetting/InvestmentProjectSetting";
import DailywalletRoiSetting from "../../components/feesetting/DailywalletRoiSetting";

const InvestmentFeeSetting = () => {
  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Investment | Project | Isusu | Transaction Fee Setting
          </h1>

          <div className="flex mb-4 justify-start items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Fee Setting
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Investment Transactions Fee Setting
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4"></div>
      </div>
      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="w-full text-sm whitespace-nowrap border-2 text-gray-700 bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-center">
                    <p className="text-lg font-bold">
                      Investment & Project Transaction Fee Setting
                    </p>
                  </th>
                  <th className="px-4 py-3 text-center">
                    <p className="text-lg font-bold">
                      Daily Wallet Roi Setting
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-3 mt-8 whitespace-nowrap border-2">
                    <InvestmentProjectSetting />
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap border-2">
                    <DailywalletRoiSetting />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentFeeSetting;
