import React, { useEffect } from "react";

import { HiChevronRight } from "react-icons/hi";
import { IoMdCall, IoMdMail, IoMdPerson } from "react-icons/io";

import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchDriverDetail,
  selectDriverDetail,
} from "../../redux/slices/driverSlice";
import { ImSpinner2 } from "react-icons/im";
import { convertValue, formatCreatedAt } from "../../utils/commonUtils";
import {
  FaAddressCard,
  FaCity,
  FaCreditCard,
  FaFileCode,
} from "react-icons/fa";
import ImageFullScreen from "../../components/ImageFullScreen";
import { VscReferences } from "react-icons/vsc";
import { IoLocationOutline } from "react-icons/io5";
import { MdDateRange, MdOutlineDomainVerification } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import ModalSuspendDriver from "../../components/staffs/ModalSuspendDriver";
import { TbNumber } from "react-icons/tb";

const DriverDeletionRequestDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDriverDetail(id));
  }, [dispatch, id]);

  const driverDetail = useSelector(selectDriverDetail);

  const status = useSelector((state) => state.driver?.driverDetailStatus);
  const error = useSelector((state) => state.driver?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameFromId = (countryId) => {
    const country = countries?.find(
      (country) => country.id === parseInt(countryId, 10)
    );
    return country ? country.name : "Unknown";
  };

  const getStateNameFromId = (stateId) => {
    const state = states?.find((state) => state.id === parseInt(stateId, 10));
    return state ? state.name : "Unknown";
  };

  const getCityNameFromId = (cityId) => {
    const city = provinces?.find((city) => city.id === parseInt(cityId, 10));
    return city ? city.name : "Unknown";
  };

 

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Driver Deletion Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to={"/dashboard/drivers/deletion-request"}>
              <p className="text-[14px] text-[#1F7BF4]  font-medium">Driver Deletion</p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-[#667085] font-medium">
              Driver Deletion Detail
            </p>
          </div>
        </div>
        <div className="mt-12">
          <div className="flex gap-2 border-2 p-2 rounded-3xl cursor-pointer">
            <ModalSuspendDriver
              id={driverDetail?.data?.driver?.id}
              name={driverDetail?.data?.driver?.name}
            />
            <p>Suspend</p>
          </div>
        </div>
      </div>

      
       {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold ">
                {" "}
                Try again later
              </span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {driverDetail ? (
            <div class="flex mt-8 gap-6">
              <div class="flex flex-col gap-6 w-1/2">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                      Driver Information
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Name
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.driver?.name}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdMail size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Email
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.driver?.email}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdCall size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Phone
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.driver?.phone}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaAddressCard size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Address
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium text-right">
                          {driverDetail?.data?.driver?.address
                            ? driverDetail?.data?.driver?.address
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <VscReferences size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Referred By
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.driver?.referred_by
                            ? driverDetail?.data?.driver?.referred_by
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaFileCode size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Ref Code
                          </p>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.driver?.ref_code}
                        </p>
                      </div>
                      <div className="flex justify-between items-center gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                            <TbNumber size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            No. of Referrals
                          </p>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.referrals.length}
                        </p>
                      </div>
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaCity size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              City
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {getCityNameFromId(driverDetail?.data?.driver?.city)}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              State
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {getStateNameFromId(driverDetail?.data?.driver?.state)}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Country
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {getCountryNameFromId(driverDetail?.data?.driver?.country)}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdOutlineDomainVerification
                                size={20}
                                color="#667085"
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Verified
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {convertValue(driverDetail?.data?.driver?.verified)}
                        </p>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdOutlineDomainVerification
                                size={20}
                                color="#667085"
                              />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Suspend
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {convertValue(driverDetail?.data?.driver?.suspended)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdDateRange size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Created
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(driverDetail?.data?.driver?.created_at)}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <MdDateRange size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Last Updated
                            </p>
                          </div>
                        </div>
                        <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {formatCreatedAt(driverDetail?.data?.driver?.updated_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-6 w-1/2">
                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between gap-2">
                        <p className="text-base text-gray-700  font-medium">
                          Referee
                        </p>
                        <div>
                          {driverDetail?.data?.driver?.referee ? (
                            JSON.parse(driverDetail?.data?.driver?.referee).map(
                              (refereeInfo, index) => (
                                <p
                                  key={index}
                                  className="text-[16px] text-right mt-2 text-[#1A1C21] font-medium"
                                >
                                  {refereeInfo}
                                </p>
                              )
                            )
                          ) : (
                            <p className="text-[14px] mt-2 text-[#1A1C21] font-medium tracking-[0.07px]">
                              No Info
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <h1 className="text-base text-[#1A1C21] font-medium tracking-[0.09px]">
                      Documents
                    </h1>

                    <div className="flex flex-col gap-[14px]">
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <BsBank2 size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Bank
                            </p>
                          </div>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.driver?.bank
                            ? driverDetail?.data?.driver?.bank
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaCreditCard size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Account Number
                            </p>
                          </div>
                        </div>
                        <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                          {driverDetail?.data?.driver?.account_number
                            ? driverDetail?.data?.driver?.account_number
                            : "No Info"}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <FaAddressCard size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Address Verification
                          </p>
                        </div>
                        {driverDetail?.data?.driver?.address_verification ? (
                          <ImageFullScreen
                            src={driverDetail?.data?.driver?.address_verification}
                            alt={driverDetail?.data?.driver?.name}
                          />
                        ) : (
                          "No Image"
                        )}
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoMdPerson size={20} color="#667085" />
                            </div>
                          </div>
                          <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                            Passport
                          </p>
                        </div>
                        {driverDetail?.data?.driver?.passport ? (
                          <ImageFullScreen
                            src={driverDetail?.data?.driver?.passport}
                            alt={driverDetail?.data?.driver?.name}
                          />
                        ) : (
                          "No image"
                        )}
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-2 items-center">
                          <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                            <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                              <IoLocationOutline size={20} color="#667085" />
                            </div>
                          </div>
                          <div className="flex gap-2 items-center">
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              NIN
                            </p>
                          </div>
                        </div>
                        {driverDetail?.data?.driver?.nin ? (
                          <ImageFullScreen
                            src={driverDetail?.data?.driver?.nin}
                            alt={driverDetail?.data?.driver?.name}
                          />
                        ) : (
                          "No Image"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "null"
          )}
        </div>
      )}
    </div>
  );
};

export default DriverDeletionRequestDetail;
