import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchManualTransfer = createAsyncThunk(
  "manualTransfer/fetchManualTransfer",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/manual-transfers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchManualWithdrawls = createAsyncThunk(
  "manualTransfer/fetchManualWithdrawls",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/manual-withdrawals", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveManualTransfer = createAsyncThunk(
  "manualTransfer/approveManualTransfer",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(`/approve-transfer/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveManualWithdrawal = createAsyncThunk(
  "manualTransfer/approveManualWithdrawal",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/approve-withdrawal", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const manualTransferSlice = createSlice({
  name: "manualTransfer",
  initialState: {
    manualTransfers: [],
    manualWithdrawls: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchManualTransfer.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchManualTransfer.fulfilled, (state, action) => {
        state.status = "success";
        state.manualTransfers = action.payload.data;
      })
      .addCase(fetchManualTransfer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchManualWithdrawls.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchManualWithdrawls.fulfilled, (state, action) => {
        state.status = "success";
        state.manualWithdrawls = action.payload.data;
      })
      .addCase(fetchManualWithdrawls.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(approveManualTransfer.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(approveManualTransfer.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(approveManualTransfer.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      })
      .addCase(approveManualWithdrawal.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(approveManualWithdrawal.fulfilled, (state) => {
        state.status = "idle";
        state.error = null;
      })
      .addCase(approveManualWithdrawal.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.payload;
      });
  },
});

export default manualTransferSlice.reducer;
