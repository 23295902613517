import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchCountry = createAsyncThunk(
  "country/fetchCountry",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/admin/country", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCountry = createAsyncThunk(
  "country/addCountry",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/add/country", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCountry = createAsyncThunk(
  "country/updateCountry",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.put(
        `/update/country/${id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCountry = createAsyncThunk(
  "country/deleteCountry",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(
        `/delete/country/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const countrySlice = createSlice({
  name: "country",
  initialState: {
    countries: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.status = "success";
        state.countries = action.payload.data;
      })
      .addCase(fetchCountry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addCountry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addCountry.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.countries.unshift(action.payload);
      })
      .addCase(addCountry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ? action.payload : "Failed to add country";
      })
      .addCase(updateCountry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCountry.fulfilled, (state, action) => {
        state.status = "success";
        const updatedCountry = action.payload;
        state.countries = state.countries?.map((country) =>
          country.id === updatedCountry.id ? updatedCountry : country
        );
      })
      .addCase(updateCountry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to update market";
      })
      .addCase(deleteCountry.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteCountry.fulfilled, (state, action) => {
        const deletedCountryId = action.payload;
        state.status = "success";
        state.countries = state.countries.filter(
          (country) => country.id !== deletedCountryId
        );
      })
      .addCase(deleteCountry.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete country";
      });
  },
});

export default countrySlice.reducer;
