import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addInCityFee } from "../../redux/slices/feeSettingSlice";
import { FaSpinner } from "react-icons/fa";
import { formatNumberWithCommas2 } from "../../utils/commonUtils";

const CitySetting = () => {
  const initialFormData = {
    delivery_fee: "",
    min_fee: "",
    max_fee: "",
    capfields: "",
    driver: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const dispatch = useDispatch();
  const status = useSelector((state) => state.feeSetting?.inCityFeeStatus);

  const delivery_city = useSelector(
    (state) => state.feeSetting?.feeSettings?.delivery_city
  );

  useEffect(() => {
    if (delivery_city) {
      setFormData({
        delivery_fee: delivery_city.delivery_fee || "",
        min_fee: delivery_city.min_fee || "",
        max_fee: delivery_city.max_fee || "",
        capfields: delivery_city.capfields || "",
        driver: delivery_city.driver || "",
      });
    }
  }, [delivery_city]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let adjustedValue = value.replace(/,/g, "");
  
    if (name === "delivery_fee" || name === "capfields" || name === "driver") {
      // Parse the numeric part of the input value as a float
      let floatValue = parseFloat(value);
  
      // Ensure the value is not greater than 100
      floatValue = Math.min(Math.max(floatValue, 1), 100);
  
      // Calculate the adjusted values to ensure the total is 100%
      if (name === "capfields") {
        adjustedValue = floatValue;
        const adjustedDriver = 100 - floatValue;
        setFormData((prevData) => ({
          ...prevData,
          driver: adjustedDriver.toString(),
        }));
      } else if (name === "driver") {
        adjustedValue = floatValue;
        const adjustedCapfields = 100 - floatValue;
        setFormData((prevData) => ({
          ...prevData,
          capfields: adjustedCapfields.toString(),
        }));
      } else {
        adjustedValue = floatValue;
      }
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: adjustedValue,
    }));
  };
  
  const handleSubmit = async () => {
    if (
      !formData.delivery_fee ||
      !formData.min_fee ||
      !formData.max_fee ||
      !formData.capfields ||
      !formData.driver
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }
    try {
     await dispatch(addInCityFee(formData));
      toast.success("Shopping fee successfully set");
    } catch (error) {}

    // setFormData({ ...initialFormData });
  };
  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <label
          htmlFor="delivery_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Delivery Fee (%)
        </label>
        <div className="flex justify pr-24">
          <input
            name="delivery_fee"
            id="name"
            type="number"
            value={formData.delivery_fee}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex flex-row mt-2 justify-between items-center gap-4">
        <label
          htmlFor="min_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Minimum Fee
        </label>
        <div className="flex justify- pr-24">
          <input
            name="min_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.min_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>

      <div className="flex flex-row mt-2 justify-between items-center ">
        <label
          htmlFor="max_fee"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Maximum Fee
        </label>
        <div className="flex justify- pr-24">
          <input
            name="max_fee"
            id="name"
            type="text"
            value={formatNumberWithCommas2(formData.max_fee)}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex flex-row mt-2 justify-between items-center ">
        <label
          htmlFor="capfileds"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Capfields (%)
        </label>
        <div className="flex justify- pr-24">
          <input
            name="capfields"
            id="name"
            type="number"
            value={formData.capfields}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>
      <div className="flex mt-2 flex-row justify-between items-center">
        <label
          htmlFor="driver"
          className="text-[14px] whitespace-nowrap text-[#4D5464] font-bold tracking-[0.07px] pl-24"
        >
          Driver (%)
        </label>
        <div className="flex justify- pr-24">
          <input
            name="driver"
            id="name"
            type="number"
            value={formData.driver}
            onChange={handleInputChange}
            placeholder="Type here..."
            className="pl-[12px] text-right text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
          />
        </div>
      </div>

      <div className="flex justify-center">
        <button
          className="flex gap-1 mt-4  rounded-lg items-center justify-center bg-blue-600 text-white py-2 px-4"
          disabled={status === "loading"}
          onClick={handleSubmit}
        >
          {status === "loading" ? (
            <>
              <FaSpinner className="animate-spin" />
              <p className="text-sm font-semibold whitespace-nowrap">
                Submitting ...
              </p>
            </>
          ) : (
            <>
              <FiPlus className=" font-bold text-base" />
              <p className="text-sm font-semibold whitespace-nowrap">Submit</p>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default CitySetting;
