import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BsExclamationLg } from "react-icons/bs";
import { FaCircleCheck } from "react-icons/fa6";
import {
  approveWithdrawal,
  fetchWithdrawal,
} from "../../../redux/slices/withdrawalSlice";
import { pushNotifyDriver } from "../../../redux/slices/pushSlice";

export default function ApproveDriverWithdrawal({ id, name, driver_id, amount }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const status = useSelector((state) => state.withdrawal?.status);
  const dispatch = useDispatch();

  const handleApprove = async () => {
    try {
      const res = await dispatch(approveWithdrawal(id));
      if (res.payload) {
        const pushNote = {
          driver_id: [driver_id],
          title: "Capfields Evolve",
          body: "Hurray🎉 Capfields has approved your Withdrawal.",
          click: "DRIVER",
        };
        await dispatch(pushNotifyDriver(pushNote));
        toast.success(res.payload.message);
        dispatch(fetchWithdrawal());
      }
    } catch (error) {
      console.error(error);
    }

    setShowApproveModal(false);
  };

  return (
    <>
      <div className="flex items-center">
        <button type="button" onClick={() => setShowConfirmModal(true)}>
          <FaCircleCheck className="text-red-600 text-2xl" />
        </button>
      </div>

      {showConfirmModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mx-auto max-w-3xl">
              <div className="border-0 rounded-lg p-12 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowConfirmModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="text-center mb-4">
                  <h1 className="text-2xl mb-4 text-gray-800 font-medium">
                    Are you sure?
                  </h1>
                  <p className="mb-4">Do you really want to proceed with this action?</p>
                  <p>Do you really want to <span className="font-bold">Approve Withdrawal with Paystack?</span> </p>

                  
                </div>
                <div className="flex justify-center items-center m-4">
                  <BsExclamationLg className="text-gray-500 text-3xl font-bold" />
                </div>
                <div className="flex gap-4 justify-center mt-4">
                  <button
                    onClick={() => setShowConfirmModal(false)}
                    className="text-sm text-gray-500 font-semibold bg-gray-100 py-2 px-4 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      setShowConfirmModal(false);
                      setShowApproveModal(true);
                    }}
                    className="text-sm text-white font-semibold bg-red-600 py-2 px-4 rounded-md"
                  >
                    Yes, Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showApproveModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-12 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowApproveModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="text-center mb-4">
                  <h1 className="text-2xl mb-4 text-gray-800 font-medium">
                    Approve Withdrawal with Paystack
                  </h1>
                  <p>Do you want to approve this withdrawal?</p>
                </div>
                <div className="flex justify-center items-center m-4">
                  <BsExclamationLg className="text-gray-500 text-3xl font-bold" />
                </div>
                <div className="flex justify-center items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-2 justify-center items-center">
                      <h1 className="text-[14px] text-left text-gray-400">
                        Name:
                      </h1>
                      <div>
                        <p className="text-base font-bold">{name}</p>
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 justify-between">
                      <h1 className="text-[14px] text-left text-gray-400 ">
                        Amount :
                      </h1>
                      <div className=" flex justify-center items-center">
                        <span className="mr-1 text-base font-bold">
                          &#8358;
                        </span>
                        <p className=" text-base font-bold">{amount}</p>
                      </div>
                    </div>
                    <div className="flex mt-2 gap-4 justify-center">
                      <button
                        onClick={() => setShowApproveModal(false)}
                        className="text-sm text-gray-500 font-semibold bg-gray-100 py-2 px-4 rounded-md"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-md items-center bg-green-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleApprove}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Approving ...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Approve
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
