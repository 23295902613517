import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchProductSubs = createAsyncThunk(
  "productSub/fetchProductSubs",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/shop_sub", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application.json",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProductSub = createAsyncThunk(
  "productSub/addProductSub",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/add_shop_sub", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductSub = createAsyncThunk(
  "productSub/deleteProductSub",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.delete(`/delete_shop_sub/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application.json",
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductSub = createAsyncThunk(
  "productSub/updateProductSub",
  async ({ id, updateData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(
        `/update_shop_sub/${id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productSubSlice = createSlice({
  name: "productSub",
  initialState: {
    productSubs: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductSubs.pending, (state) => {
        state.status = "loading";

        state.error = null;
      })
      .addCase(fetchProductSubs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.productSubs = action.payload.data;
      })
      .addCase(fetchProductSubs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addProductSub.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProductSub.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.productSubs?.unshift(action.payload);
      })
      .addCase(addProductSub.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteProductSub.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteProductSub.fulfilled, (state, action) => {
        state.status = "succeeded";
        const psId = action.payload;

        state.productSubs = state.productSubs?.filter((ps) => ps.id !== psId);
      })
      .addCase(deleteProductSub.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateProductSub.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateProductSub.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedProduct = action.payload;
        state.productSubs = state.productSubs?.map((ps) =>
          ps.id === updatedProduct.id ? updatedProduct : ps
        );
      })

      .addCase(updateProductSub.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default productSubSlice.reducer;
