import React from "react";
import LogoutModal from "../pages/auth/Logout";

const Header = () => {
  const randomImageNumber = Math.floor(Math.random() * 1000) + 1;

  return (
    <header className="bg-white text-gray-800 p-2 flex items-center justify-between">
      <div className="flex items-center"></div>
      <div className="flex items-center justify-between mr-12">
        <div className="p-4 ">
          <img
            src={`https://picsum.photos/40/40?random=${randomImageNumber}`}
            alt="User"
            className="w-10 h-10 rounded-full ring-1 ring-gray-200"
          />
        </div>
        <div className="">
          <LogoutModal />
        </div>
      </div>
    </header>
  );
};

export default Header;
