import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchTransports = createAsyncThunk(
  "transport/fetchTransports",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/transportation", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTransport = createAsyncThunk(
  "transport/addTransport",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/create/transportation",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTransport = createAsyncThunk(
  "transport/updateTransport",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.put(
        `/update/transportation/${id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTransport = createAsyncThunk(
  "transport/deleteTransport",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(
        `/delete/transportation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const transportSlice = createSlice({
  name: "transport",
  initialState: {
    transports: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransports.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchTransports.fulfilled, (state, action) => {
        state.status = "success";
        state.transports = action.payload.data;
      })
      .addCase(fetchTransports.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addTransport.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addTransport.fulfilled, (state, action) => {
        state.status = "success";
        state.transports.unshift(action.payload);
      })
      .addCase(addTransport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateTransport.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateTransport.fulfilled, (state, action) => {
        state.status = "succeeded";
        const ut = action.payload;
        state.transports = state.transports?.map((lt) =>
          lt.id === ut.id ? ut : lt
        );
      })
      .addCase(updateTransport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteTransport.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteTransport.fulfilled, (state, action) => {
        state.status = "success";
        const dt = action.payload;
        state.transports = state.transports?.filter((tp) => tp.id !== dt);
      })
      .addCase(deleteTransport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default transportSlice.reducer;
