import React, { useState, useEffect } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchIsusu, fetchIsusuDropoff } from "../../redux/slices/isusuSlice";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";
import { ImSpinner2 } from "react-icons/im";
// import ModalIsusuAprovalRequest from "../../components/modals/investment/ModalIsusuAprovalRequest";
import { fetchUsers } from "../../redux/slices/userSlice";

const IsusuReceivers = () => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIsusuDropoff());
    dispatch(fetchUsers());
    dispatch(fetchIsusu());
  }, [dispatch]);

  const isusuDropOffs = useSelector((state) => state.isusu?.isusuDropoffs);
  const usersList = useSelector((state) => state.user?.users);
  const isusus = useSelector((state) => state.isusu?.isusu);

  const status = useSelector((state) => state.isusu?.status);
  const error = useSelector((state) => state.isusu?.error);

  const getUserDataFromUserId = (userId) => {
    const user = usersList?.find((user) => +user.id === +userId);

    return user
      ? {
          address: user.address || "No Address",
          phone: user.phone || "No Phone",
        }
      : { address: "No Address", phone: "No Phone" };
  };

  const getGroupDataFromGroupId = (groupId) => {
    const isusu = isusus?.find((isusu) => +isusu.id === +groupId);

    return isusu
      ? {
          name: isusu.name || "No Group",
        }
      : { name: "No Group" };
  };

  const filteredDropOffs = isusuDropOffs?.filter(
    (dropoff) => dropoff.month === selectedMonth
  );

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Isusu Receivers
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">
              Investment
            </p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Isusu Receivers
            </p>
          </div>
        </div>
      </div>

      <div className="mb-4 mt-4">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block p-2.5"
        >
          <option value="">Select a month</option>
          {isusuDropOffs?.map((dropoff) => (
            <option key={dropoff.month} value={dropoff.month}>
              {dropoff.month}
            </option>
          ))}
        </select>
      </div>

      {selectedMonth ? (
        <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
          <div className="relative overflow-x-auto">
            {status === "failed" ? (
              <div className="text-center py-4">
                <div className="flex justify-center items-center">
                  Error. {error}.{" "}
                  <span className="text-base font-semibold">
                    {" "}
                    Try again later
                  </span>
                </div>
              </div>
            ) : status === "loading" ? (
              <div className="text-center py-4">
                <div className="flex justify-center items-center">
                  <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                </div>
              </div>
            ) : filteredDropOffs.length === 0 ? (
              <div className="text-center text-base text-gray-500 py-4">
                No Data
              </div>
            ) : (
              filteredDropOffs.map((order, index) => (
                <React.Fragment key={index}>
                  {order.Receivers.length === 0 ? (
                    <div className="text-center text-base text-gray-500 py-4">
                      No Receivers Data
                    </div>
                  ) : (
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="w-full whitespace-nowrap text-sm text-gray-700 bg-gray-50">
                        <tr>
                          <th className="px-4 py-3 text-center">No.</th>
                          <th className="px-4 py-3 text-center">Month</th>
                          <th className="px-4 py-3 text-left">Customer Name</th>
                          <th className="px-4 py-2 text-left w-48">Address</th>
                          <th className="px-4 py-3 text-center">Isusu Group</th>
                          <th className="px-4 py-3 text-center">Amount</th>
                          <th className="px-4 py-3 text-center">Month Ended</th>
                          <th className="px-4 py-3 text-center">
                            Created Date
                          </th>
                          <th className="px-4 py-3 text-center">
                            Updated Date
                          </th>
                          {/* <th className="px-4 py-3 text-center">Action</th> */}
                        </tr>
                      </thead>
                      <tbody className="w-fit">
                        {order.Receivers.map((receiver, receiverIndex) => (
                          <tr
                            key={`${index}-${receiverIndex}`}
                            className="bg-white border-b"
                          >
                            <td className="px-4 py-4">
                              <p className="text-base text-center font-semibold">
                                {receiverIndex + 1}
                              </p>
                            </td>
                            <td className="px-4 py-4 text-center whitespace-nowrap">
                              <p>{order.month}</p>
                            </td>
                            <td className="px-4 py-4 font-medium whitespace-nowrap text-left">
                              <div className="flex flex-col gap-1 text-gray-600">
                                <p className="text-base font-semibold">
                                  {receiver.username}
                                </p>
                                <p>
                                  {
                                    getUserDataFromUserId(receiver.user_id)
                                      .phone
                                  }
                                </p>
                              </div>
                            </td>

                            <td className="px-4 py-2 text-left">
                              <div className="">
                                <p className="text-[14px] font-medium whitespace-normal w-48 break-words">
                                  {
                                    getUserDataFromUserId(receiver.user_id)
                                      .address
                                  }
                                </p>
                              </div>
                            </td>

                            <td className="px-4 py-3 text-center">
                              <div className="flex flex-col gap-1">
                                <p className="px-4 py-1 text-[14px] font-medium whitespace-normal w-48 break-words">
                                  {
                                    getGroupDataFromGroupId(receiver.group_id)
                                      .name
                                  }
                                </p>
                              </div>
                            </td>

                            <td className="px-4 py-4">
                              <div className="flex justify-center items-center">
                                <span className="mr-1">&#8358;</span>
                                {receiver.amount
                                  ? formatNumberWithCommas(receiver.amount)
                                  : 0}
                              </div>
                            </td>
                            <td className="px-4 py-2 text-center whitespace-nowrap">
                              {formatCreatedAt(receiver.end_month)}
                            </td>
                            <td className="px-4 py-2 text-center whitespace-nowrap">
                              {formatCreatedAt(receiver.created_at)}
                            </td>
                            <td className="px-4 py-2 text-center whitespace-nowrap">
                              {formatCreatedAt(receiver.updated_at)}
                            </td>
                            {/* <td className="px-4 py-4">
                              <div className="flex gap-[8px] items-center justify-center">
                                <ModalIsusuAprovalRequest /> -
                              </div>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
          <div className="text-center text-base text-gray-500 py-4">
            Select A month
          </div>
        </div>
      )}
    </div>
  );
};

export default IsusuReceivers;
