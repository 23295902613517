// stateSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchState = createAsyncThunk(
  "state/fetchState",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get('/admin/state', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addState = createAsyncThunk(
  "state/addState",
  async (formData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post("/admin/add/state", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteState = createAsyncThunk(
  "state/deleteState",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.delete(`/delete/state/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateState = createAsyncThunk(
  "state/updateState",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await apiService.put(
        `/update/state/${id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const stateSlice = createSlice({
  name: "state",
  initialState: {
    states: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchState.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchState.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.states = action.payload.data;
      })
      .addCase(fetchState.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addState.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addState.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.states.unshift(action.payload);
      })
      .addCase(addState.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ? action.payload : "Failed to add state";
      })
      .addCase(deleteState.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteState.fulfilled, (state, action) => {
        state.status = "succeeded";
        const deletedStateId = action.payload;
        state.states = state.states?.filter(
          (state) => state._id !== deletedStateId
        );
      })
      .addCase(deleteState.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to delete state";
      })
      .addCase(updateState.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateState.fulfilled, (state, action) => {
        state.status = "success";
        const updatedState = action.payload;

        state.states = state.states?.map((state) =>
          state.id === updatedState.id ? updatedState : state
        );
      })
      .addCase(updateState.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
        console.log(action.payload.message);
      });
  },
});

export default stateSlice.reducer;
