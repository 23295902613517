import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchDriverPushedOrder = createAsyncThunk(
  "driverPushedOrder/fetchDriverPushedOrder",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.get("/admin/pushed-orders");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const pushDriverPushedOrder = createAsyncThunk(
  "driverPushedOrder/pushDriverPushedOrder",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiService.post("/admin/push-delivery");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const driverPushedOrderSlice = createSlice({
  name: "driverPushedOrder",
  initialState: {
    driverPushedOrders: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDriverPushedOrder.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDriverPushedOrder.fulfilled, (state, action) => {
        state.status = "success";
        state.driverPushedOrders = action.payload.data;
      })
      .addCase(fetchDriverPushedOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default driverPushedOrderSlice.reducer;
