import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const addMonitorOrder = createAsyncThunk(
  "monitorOrder/addMonitorOrder",
  async ({ order_details, order_id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        "/monitor/order",
        { order_details, order_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const monitorOrderSlice = createSlice({
  name: "monitorOrder",
  initialState: {
    monitorOrders: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addMonitorOrder.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addMonitorOrder.fulfilled, (state, action) => {
        state.status = "success";
        state.monitorOrders = action.payload;
      })
      .addCase(addMonitorOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default monitorOrderSlice.reducer;
